import { IConfig } from 'ngx-mask';


export class ThirdPartyConfig {

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Configuration of toastr
   */
  public static toastrConfig = {
    // maxOpened: 1,
    timeout: 5000,
    closeButton: false,
    autoDismiss: true,
    progressBar: false,
    preventDuplicates: true,
    positionClass: 'toast-top-right',
  };

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Configuration of ngx-spinner
   */
  public static spinnerConfig = {
    bdColor: "rgba(0, 0, 0, 0.8)",
    size: "medium",
    color: '#419255', // Secondary color
    fullScreen: "true",
  };

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Configuration of @ngx-loading-bar
   */
  public static loadingBarConfig = {
    color: '#FFFAEB', // Primary color
  };

  /**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Configuration of ngx-mask
 */
  public static inputMaskConfig: Partial<IConfig> = {
    validation: false,
  };

  /**
  * Author: TO512 Krupa Tresa Joseph
  * Description: Configuration of the prime ng calender date only picker
  */
  public static calenderDateOnlyConfig = {
    dateFormat: 'M dd, yy',
    showTime: false,
    hourFormat: '24',
    showIcon: true,
    showButtonBar: false,
    monthNavigator: true,
    yearNavigator: true,
    yearRange: '1950: 2100',
    hideOnDateTimeSelect: true,
    showTransitionOptions: '3ms',
    hideTransitionOptions: '3ms'
  };

  /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: Configuration of the prime ng calender date time picker
  */
  public static calenderDateTimeConfig = {
    dateFormat: 'M dd, yy',
    showTime: true,
    hourFormat: '24',
    showIcon: true,
    showButtonBar: false,
    monthNavigator: true,
    yearNavigator: true,
    yearRange: '1950: 2100',
    hideOnDateTimeSelect: true,
    showTransitionOptions: '3ms',
    hideTransitionOptions: '3ms'
  };


  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Configuration of the prime ng calender date range
   */
  public static dateRangeCalenderConfig = {
    dateFormat: 'dd MM yy',
    showTime: false,
    hideOnDateTimeSelect: true,
    showIcon: true,
    selectionMode: 'range',
    hourFormat: '12',
    showButtonBar: true,
    showTransitionOptions: '3ms',
    hideTransitionOptions: '3ms'
  };

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Configuration of the prime ng calender table menu
   */
  public static tableMenuConfig = {
    appendTo: 'body',
    popup: true,
  };

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Configuration of TinyMCE editor
   */
  public static htmlEditorConfig =  {
    height: 350,
    theme: 'modern',
    plugins: 'preview fullpage searchreplace directionality visualchars fullscreen  hr pagebreak nonbreaking anchor advlist lists textcolor wordcount contextmenu colorpicker textpattern link image media paste contextmenu',
    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link autolink openlink unlink | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | image media',
    contextmenu: 'paste link image',
    image_advtab: false,
    image_uploadtab: true,
    a11y_advanced_options: true,
    content_css: [
      '//fonts.googleapis.com/css?family=Raleway&display=swap',
      '//www.tinymce.com/css/codepen.min.css'
    ],

    file_picker_types: 'image',
    file_picker_callback: function (cb) {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = function (e?: HTMLInputEvent) {
      var file = e.target.files[0];

      var reader = new FileReader();
      reader.onload = function (event) {
        // console.log("tinymce",event)
        var blobCache =  event.target.result;
        var blobInfo = blobCache;
        cb(blobInfo, { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
    interface HTMLInputEvent extends Event {
      target: HTMLInputElement & EventTarget;
    }
  },
  };

}
