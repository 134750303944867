import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpUserEvent,
  HttpSentEvent,
  HttpInterceptor,
  HttpProgressEvent,
  HttpErrorResponse,
  HttpHeaderResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { AuthService } from '@services/auth/auth.service';
import { LoaderService } from '@services/loader/loader.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';

import { ServiceList } from '@utilities/services-list';
import { AuthConstants } from '@utilities/auth-constants';
import { ToastrMessages } from '@utilities/toastr-messages';

import { AlertMsg } from '@models/alert-msgs';

import { environment } from '@env/environment';
import { AppConstants } from '@utilities/app-constants';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  // Flags
  serviceIdentified: boolean;
  isRefreshingToken = false;

  // Subjects
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // HTTP related variables
  requestHeaders: any;

  apiUrl: string;
  requestCount: number;
  totalRequestsCount: number;

  // Error related Variables
  errorMsg: AlertMsg;
  genericMessagesList;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private alertMsgService: AlertMsgService
  ) {
    this.apiUrl = '';
    this.requestCount = 0;
    this.requestHeaders = {
      contentType: 'application/json',
      accept: '',
      authorization: '',
    };
    this.genericMessagesList = ToastrMessages.HTTP_ERROR_MESSAGES;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    this.setBaseUrl(req);
    req = req.clone({ url: `${this.apiUrl}${req.url}` });
    const isTokenPresent = this.checkTokenInStorage();

    if (isTokenPresent) {
      req = req.clone(
        {
          headers: req.headers.set('Authorization', `${this.requestHeaders.authorization}`)
        });
    } else {
      req = req.clone({ headers: req.headers.set('Authorization', '') });
    }
    this.requestCount++;
    req = this.addHeaders(req, this.requestHeaders.authorization);
    this.loaderService.showSpinner();
    return next.handle(req).pipe(
      map(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            let retVal: any = event;
            this.decrementRequestCount();
            return retVal;
          }
        }),
      catchError((error: HttpErrorResponse) => {
        this.showErrorMessages(error, req, next);
        this.decrementRequestCount();
        return throwError(error);
      }),
    );
  }

  //   ---- REQUEST VALIDATORS ----

  /**
   * Author: Krupa Tresa Joseph
   * Description: Check the value of token in storage
   */
  checkTokenInStorage() {
    let retVal = false;
    if (localStorage.getItem(AuthConstants.localStorageVariables.access_token)) {
      this.requestHeaders.authorization = localStorage.getItem(AuthConstants.localStorageVariables.access_token);
      retVal = true;
    }
    return retVal;
  }

  // Configures the request by setting proper values for header properties
  addHeaders(req: HttpRequest<any>, token): HttpRequest<any> {
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', token) });
    }
    req.clone({
      url: `${this.apiUrl}${req.url}`,
    });
    return req.clone({
      setHeaders: {
        // 'Content-Type': 'application/json',
        // 'Accept': 'q=0.8;application/json;q=0.9',
      }
    });
  }

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: TODO: Remove this code once all APIs are avaiable in  a single IP
   */
  setBaseUrl(request) {
    let matchUrl = '';
    this.serviceIdentified = false;
    // Check api matching in auth service list
    matchUrl = ServiceList.authServiceApiLists.find(
      (item: string) => {
        if (request['url'].includes(item)) {
          this.serviceIdentified = true;
          this.apiUrl = environment.authApiUrl;
          return item;
        }
      });
    // Check api matching in clientFeatures service list
    if (!this.serviceIdentified) {
      matchUrl = ServiceList.clientServiceApiLists.find((item) => {
        if (request['url'].includes(item)) {
          this.serviceIdentified = true;
          this.apiUrl = environment.otherApiUrl;
          return item;
        }
      });
    }

    // Check api matching in careproviderFeatures service list
    if (!this.serviceIdentified) {
      matchUrl = ServiceList.careporviderServiceApiLists.find((item) => {
        if (request['url'].includes(item)) {
          this.serviceIdentified = true;
          this.apiUrl = environment.featureApiUrl;
          return item;
        }
      });
    }

            // Check api matching in admin service list
            if (!this.serviceIdentified) {
              matchUrl = ServiceList.adminServiceApiLists.find((item) => {
                if (request['url'].includes(item)) {
                  this.serviceIdentified = true;
                  this.apiUrl = environment.adminApiUrl;
                  return item;
                }
              });
            }
  }

  // -------------- ERROR HANDLING METHODS ------------

  showErrorMessages(error: HttpErrorResponse, req?: HttpRequest<any>, next?: HttpHandler) {
    let showErrorMsg = false;
    this.errorMsg = { msg: ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_GENERIC, title: '' };
    if (error instanceof HttpErrorResponse) {
      switch ((<HttpErrorResponse>error).status) {
        case 400:
          showErrorMsg = false;
          this.errorMsg.msg = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_GENERIC;  
          break;
        case 0:
          showErrorMsg =true;
          this.errorMsg.msg = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_NETWORK;
          break;
        case 401:
          // Token Expiry Scenario
          showErrorMsg = true;
          this.errorMsg.msg = ToastrMessages.HTTP_ERROR_MESSAGES.ERROR_UNAUTHORIZED_ACCESS_401;
          this.handle401Error();
          break;
      }
      if (this.errorMsg && showErrorMsg) {
        this.alertMsgService.showErrorToster(this.errorMsg);
      }
    }
  }

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: 401 error is basically related to token expiry, so the
   * token refresh API is called
   */
  handle401Error() {
    this.authService.purgeAuth();
    this.router.navigate([AppConstants.redirectUrls.login]);
  }

  // ------------- REQUEST FORMATTERS --------------

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Show or hide loader based on request count
   */
  decrementRequestCount() {
    this.requestCount--;
    if (this.requestCount <= 0) {
      this.loaderService.hideSpinner();
    }
  }

}
