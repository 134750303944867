import { ActivityActions, ActivityMatrixInfo, FinalMatrixData } from './activity-matrix';
import { PackedActivityInfo } from './master-activities';

export interface TreatmentActivities {
    id?: string;
    _id?: string;
    activity_id?: string;
    domain: string;
    sensor: string;
    domainName?: string;
    sensorName?: string;
    is_deleted: boolean; 
    interval: string;
    materials: string;
    procedure: string;
    weekday: any[];
    recurrence: string;
    expectation: string;
    activityId? :string;   
    activity_ref: string;
    activity_name: string;
    duration_min : string;
    duration_hours: string;
    start_date?: string;
    end_date?: string;
    createdAt?: string;
    updatedAt?: string;
    action?: ActivityActions;
    activity_matrix?: FinalMatrixData;
}
export interface DurationInfo {
    duration_minutes: number;
    duration_hours: number;
}
export interface RecurrenceInfo {
    count: number;
    type: string;
}
export interface TreatmentActivityDetail {
    materials: string;
    procedure: string;
    expectation: string;
    activity_matrix: ActivityMatrixInfo;
}

// ----------------- HTTP DATA FORMATTING METHODS ------------------------

export interface ActivityDetailRequestInfo {
    domain: string;
    sensor: string;
    // activity_id: string;
    activity_name: string;
    materials: {
        contents: string;
    };
    procedure: {
        contents: string;
    };
    expectation: string;
    is_deleted: boolean;
    duration_min: any;
    duration_hours: any;
    id?: any;
    activityId?: any;
    activity_id?: any;
    activity_matrix: FinalMatrixData;

}

// ----------------- CLASS ---------------------

export class TreatmentPlanManager { 

    static createPackedActivity(activityDetail: any): PackedActivityInfo {
        const packedActivity: PackedActivityInfo = {
            activity_details: {
                is_deleted: false, 
                activity_name: activityDetail.activity_name,
                sensor: activityDetail.sensor,
                domain: activityDetail.domain,
                end_date: activityDetail.end_date,
                start_date: activityDetail.start_date,
                duration_min: activityDetail.duration_min,
                duration_hours: activityDetail.duration_hours,
                materials: activityDetail.materials,
                procedure: activityDetail.procedure,
                expectation: activityDetail.expectation,
                weekday: activityDetail.weekday,
                interval: activityDetail.interval,
                recurrence: activityDetail.recurrence,
                _id:  activityDetail.activity_id,
                activity_ref: activityDetail.activity_ref ? activityDetail.activity_ref : null,
                activityId: activityDetail.activityId ? activityDetail.activityId : null,
                activity_id: activityDetail.activity_id ? activityDetail.activity_id : null,
            },
            activity_matrix: activityDetail.activity_matrix
        };
        return packedActivity; 
    }
}