<nav class="navbar">
  <!-- App Details -->
  <div class="logo-con">
    <i class="fa fa-bars light-icon menu-click-icon" aria-hidden="true" (click)="toggleSideBar()"></i>
    <img class="logo logo-small" src="assets/images/main-logo.png">
    <!-- <span class="header-title">Guardian RPM</span> -->
  </div>
  <!-- Side Bar -->
  <p-sidebar
    position="left"
    [(visible)]="showSideBar"
    [baseZIndex]="10000"
    [showCloseIcon]="true"
    styleClass="p-sidebar-sm">
    <div class="side-menu menu-wrap" id="navbarSupportedContent">
      <div class="menu-title">
        <img class="logo logo-small" src="assets/images/main-logo.png">
        <!-- <span class="header-title">Guardian RPM</span> -->
      </div>
      <ul class="navbar-nav mr-auto">
        <!-- Nav Items -->
        <li class="nav-item"
          [title]="item?.title"

            *ngFor="let item of menuList;let menuIndex=index">
            <!-- [ngClass]="{'hide-item':item?.showOnList}" -->
          <a class="nav-link"
            *ngIf="item?.showOnList"
            [routerLink]="item?.link"
            [routerLinkActive]="'active'">
            <span class="menu-title">{{item?.title}}</span>
          </a>
        </li>
      </ul>
    </div>
  </p-sidebar>
  <!-- Menu Wrap -->
  <div class="header-menu menu-wrap" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <!-- Nav Items -->
      <li class="nav-item"
      [title]="item?.title"
      [ngClass]="{'hide-item': !item?.showOnList}"
      *ngFor="let item of menuList;let menuIndex=index">
        <a class="nav-link"
          *ngIf="item?.showOnList"
          [routerLink]="item?.link"
          [routerLinkActive]="'active'">
          <span class="menu-title">{{item?.title}}</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- Nav Item - User Information -->
  <!-- <ul class="user-info">
      <li class="nav-item dropdown no-arrow">
              <div class="user-info" aria-controls="dropdown-basic">
                  <span class="mr-2 d-lg-inline user-name">
                     {{userInfo?.name}}
                     <br> ({{displayRole}})
                  </span>
                  <img class="img-profile rounded-circle"
                      [src]="userInfo?.avatar"
                      (error)="handleBrokenImage(this)">
                  <i
                  class="fa fa-gear light-icon ml-2"
                  title="Logout"
                  (click)="onSettingsClick()"></i>
                  <i
                  class="fa fa-sign-out light-icon ml-2"
                  title="Logout"
                  (click)="onLogoutClick()"></i>
              </div>
      </li>
  </ul> -->

  <!-- Nav Item - User Information -->
  <ul class="user-info">
    <li class="nav-item dropdown no-arrow">
      <div class="user-info" dropdown>
        <span class="mr-2 d-lg-inline user-name">
            {{userInfo?.name}}
            <br> ({{displayRole}})
        </span>
        <img
            dropdownToggle
            id="button-basic"
            [src]="userInfo?.avatar"
            class="img-profile rounded-circle pointer-mode"
            aria-controls="dropdown-basic"
            (error)="handleBrokenImage(this)">
        <!-- Dropdown Menu -->
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu transform"
          role="menu" aria-labelledby="button-basic">
          <!-- <li role="menuitem">
            <a class="dropdown-item" (click)="onTrainingClick()">
              <i class="fa fa-book colored ml-2" title="Training"></i>
              <span class="pl-1">Training</span>
            </a>
          </li>
          <li class="divider dropdown-divider"></li> -->
          <li role="menuitem"><a class="dropdown-item" (click)="onSettingsClick()">
            <i
              class="fa fa-gear colored ml-2"
              title="Settings"></i><span class="pl-1">Settings</span></a>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" (click)="onChangePasswordClick()">
            <i
              class="fa fa-key colored ml-2"
              title="Change Password"></i><span class="pl-1">Change Password</span></a>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" (click)="onLogoutClick()">
            <i
              class="fa fa-sign-out colored ml-2"
              title="Logout"></i><span class="pl-1">Logout</span></a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</nav>
