export class TableConfigConstants {

    public static FILTER_TYPES = {
        dropdown: 'dropdown',
        dateOnly: 'dateOnly',
        dateRange: 'dateRange',
        multiSelect: 'multiSelect',
    };

    public static GENERIC_TABLE_CONTENTS = {
        defaultSelectDropdown: {value: '', label: 'Select'},
        defaultGenderDropdownValue: {value: null, label: 'Select'},
        defaultMaritalDropdownValue: {value: null, label: 'Select'},
        defaultNationalityDropdownValue: {value: null, label: 'Select'},
        defaultRatingValue: {value: 5, label: '5'},
        defaultNoneValueDropdown: {id: null, name: 'None'},
        noDataFound: 'No data found',
        noResultsFound: 'No results found',
        naIdentifierText: '-NA-',
        defaultSelectAll: { label: 'All', value: ''}
    };

    /**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Configurations of table actions
 */
    public static tableActionsList = {
        delete: 'delete',
        link: 'link',
        unlink: 'unlink',
        edit: 'edit',
        view: 'view',
        viewPayoutLog: 'viewPayoutLog',
        activate: 'activate',
        checkbox: 'checkbox',
        deactivate: 'deactivate',
        tableMenu: 'tableMenu',
        triggerAccount: 'triggerAccount',
        triggerContact: 'triggerContact',
        triggerRefund: 'triggerRefund',
        triggerPayout: 'triggerPayout',
    };
        
    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table Data types : data/action
     */
    public static tableDataType = {
        id: 'id',
        age: 'age',
        date: 'date',
        data: 'data',
        slNo: 'slNo',
        name: 'name',
        menu: 'menu',
        price: 'price',
        input: 'input',
        group: 'group',
        gender: 'gender',
        action: 'action',
        cbtGroup: 'cbtGroup',
        dateTime: 'dateTime',
        imageName: 'imageName',
        arrayItems: 'arrayItems',
        markedTitle: 'markedTitle',
        activeStatus: 'activeStatus',
        multiStageStatus: 'multiStageStatus',
        assessmentCategory: 'assessmentCategory',
    };
    
    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Configurations of table actions types
     */
    public static tableActionsTypes = {
        icon: 'icon',
        input: 'input',
    };

    public static genericFormats = {
        currency: 'INR',
        dateFormat: 'MMM dd, yyyy',
        dateTimeFormat: 'MMM dd, yyyy h:mm:ss a',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Generic table messages
     */
    public static tableGenericContents = {
        usersTitle: 'Users',
        actionColText: 'Action',
        noUsersMsg: 'No Users Found',
        selfNeuroTest: 'Question Bank',
        noDataGenericMsg: 'No Data Found',
        noInstructionsMsg: 'No Instructions Found',
        noOTPMsg: 'No Instructions Found',
    };

    /**
    * Description: table action keys
    */
    public static TABLE_ACTION_KEYS = {
        menuVisibility: "view_all",
        createItem: 'create',
        editItem: 'edit',
        viewItem: 'view',
        deleteItem: 'delete',
        checkItem: 'checkbox',
    }

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Sets modes for a dat aupdate
    */
    public static crudOperationsMode = {
        view: { key: 'view', title: 'View'},
        edit: { key: 'edit', title: 'Edit'},
        add: { key: 'create', title: 'Add'},
        create: { key: 'create', title: 'Create'},
    };
}