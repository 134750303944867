import { 
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectionStrategy 
} from '@angular/core';

import { Table } from 'primeng/table';
import { PrimeNgTableColumns, PrimeNgTableActions, PrimeNgTableSettings } from '@models/prime-ng-table';
import { TableConfigConstants } from '@utilities/table-config-constants';

@Component({
  selector: 'app-normal-data-table',
  templateUrl: './normal-data-table.component.html',
  styleUrls: ['./normal-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NormalDataTableComponent implements OnInit, OnChanges {

  @ViewChild('dataTable') dataTable: Table;

  // INCOMING VARIABLES: Table related variables
  @Input() tableItems;
  @Input() tableTitle: string;
  @Input() tableSettings: PrimeNgTableSettings;
  @Input() tableActions: PrimeNgTableActions[];
  @Input() tableColumns: PrimeNgTableColumns[];

  // EVENT EMITTERS
  @Output() editItem = new EventEmitter();
  @Output() viewItem = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  @Output() unLinkItem = new EventEmitter();
  @Output() triggerRefund = new EventEmitter();
  @Output() managePagination = new EventEmitter();

  // General variables
  actionTypes;
  actionsList;
  searchInputVal: string; 
  tableActiontext: string;

  // CONSTANTS
  dataTypes: any;
  genericFormats: any;

  constructor() {
    this.dataTypes = TableConfigConstants.tableDataType;
    this.actionsList = TableConfigConstants.tableActionsList;
    this.genericFormats = TableConfigConstants.genericFormats;
    this.actionTypes = TableConfigConstants.tableActionsTypes;
    this.tableActiontext = TableConfigConstants.tableGenericContents.actionColText;
   }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName) {
        const change = changes[propName];
        const curVal = change.currentValue;
        const preVal = change.previousValue;
        const strCurVal = JSON.stringify(curVal);
        const strPrevVal = JSON.stringify(preVal);
        if (strCurVal !== strPrevVal) {
          this.onResetTable();
        }
      }
    }
  }

  onActionClick(action: PrimeNgTableActions, selectedItem: any) {
    switch (action.action) {
      case this.actionsList.delete:
        this.deleteItem.emit(selectedItem);
        break;
      case this.actionsList.edit:
        this.editItem.emit(selectedItem);
        break;
      case this.actionsList.view:
        this.viewItem.emit(selectedItem);
        break;
      case this.actionsList.view:
        this.viewItem.emit(selectedItem);
        break;
      case this.actionsList.deactivate:
        this.deactivate.emit(selectedItem);
        break;
      case this.actionsList.activate:
        this.activate.emit(selectedItem);
        break;
      case this.actionsList.triggerRefund:
        this.triggerRefund.emit(selectedItem);
        break;
      case this.actionsList.unlink:
        this.unLinkItem.emit(selectedItem);
        break;
    }
  }

  onResetTable() {
    if (this.dataTable) {
      this.dataTable.reset();
    }
  }

  onSearchInput(enteredValue) {
    this.searchInputVal = enteredValue;
    this.dataTable.filterGlobal(enteredValue, 'contains');
  }

}
