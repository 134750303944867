import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '@services/timezone/timezone.service';
import * as moment from 'moment';

@Pipe({
  name: 'timeZoneFormat'
})
export class TimezoneFormatPipe implements PipeTransform {

  constructor(private timezoneService: TimezoneService) {}

  timeZone: any;
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    // to milliseconds
    const timeInMilliSeconds = value * 0.001;
    this.timeZone = this.timezoneService.getUserTimeZone();

    return moment(value).tz(this.timeZone).format('hh:mm A');
  }

}
