<div class="main-con">
    <div class="table-filters">
        <div class="filter-block" 
        *ngFor="let filter of filters"
        [ngStyle]="{'order':filter?.order}">
          <div class="filter-wrap">
            <label class="label filter-label">
              {{filter?.filterTitle}}
            </label>
            <!-- NORMAL DROPDOWN FILTER -->
            <div class="filter filter-dropdown-container"
              *ngIf="filterTypes.dropdown === filter.type">
                <select class="form-control"  
                [(ngModel)]="filter.selectedOption" (change)="onFilterSelectionChange(filter)">
                <option *ngIf="filter?.showAllOption" [value]="defaultOption?.value">{{defaultOption?.label}}</option>
                <option *ngFor="let option of filter?.options" [value]="option?.value">
                  {{option?.label}}
                </option>
              </select>
            </div>
            <!-- DATE ONLY FILTER -->
            <div class="filter filter-calender"
              *ngIf="filterTypes.dateOnly === filter.type" >
              <p-calendar 
                #dateOnlyFilter
                [(ngModel)]="filter.selectedOption"
                [showTime]=dateOnlyConfig?.showTime
                [showIcon]="dateOnlyConfig?.showIcon"
                [yearRange]="dateOnlyConfig?.yearRange"
                [dateFormat]=dateOnlyConfig?.dateFormat
                [yearNavigator]="dateOnlyConfig?.yearNavigator"
                [showButtonBar]="dateOnlyConfig?.showButtonBar"
                [monthNavigator]="dateOnlyConfig?.monthNavigator"
                [hourFormat]="dateOnlyConfig?.hourFormat"
                [dateFormat]=dateOnlyConfig?.dateFormat
                [hideOnDateTimeSelect]="dateOnlyConfig?.hideOnDateTimeSelect"
                (onClearClick)="onClearDate(filter)"
                (onSelect)="onDateOnlySelectionChange(filter)">
              </p-calendar>
            </div>
            <!-- DATE RANGE PICKER FILTER-->
            <div class="filter filter-calender"
            *ngIf="filterTypes.dateRange === filter.type" >
              <p-calendar 
                #dateRangeCalender
                [(ngModel)]="filter.selectedOption"
                [showTime]="dateRangeConfig?.showTime"
                [dateFormat]=dateRangeConfig?.dateFormat
                [hourFormat]="dateRangeConfig?.hourFormat"
                [yearNavigator]="dateOnlyConfig?.yearNavigator"
                [monthNavigator]="dateOnlyConfig?.monthNavigator"
                [selectionMode]="dateRangeConfig?.selectionMode"
                [showButtonBar]="dateRangeConfig?.showButtonBar"
                [showIcon]="dateRangeConfig?.showIcon"
                (onClearClick)="onClearDate(filter)"
                (onSelect)="onDateChange(filter)">
              </p-calendar>
            </div>
            <!-- DATE TIME RANGE PICKER -->
            <div class="filter filter-calender date-time"
              *ngIf="filterTypes.dateTimeRange === filter.type" >
              <p-calendar 
              #dateRangeCalender
              [(ngModel)]="filter.selectedOption"
              [showTime]="dateTimeRangeConfig?.showTime"
              [dateFormat]=dateTimeRangeConfig?.dateFormat
              [hourFormat]="dateTimeRangeConfig?.hourFormat"
              [selectionMode]="dateTimeRangeConfig?.selectionMode"
              [showButtonBar]="dateTimeRangeConfig?.showButtonBar"
              [hideOnDateTimeSelect]="dateTimeRangeConfig?.hideOnDateTimeSelect"
              [showIcon]="dateRangeConfig?.showIcon"
              (onClearClick)="onClearDate(filter)"
              (onSelect)="onDateChange(filter)">
              </p-calendar>
            </div>
            <!-- MULTI SELECT -->
            <div class="filter filter-multiselect"
                *ngIf="filterTypes.multiSelect === filter.type">     
                  <p-multiSelect 
                    [options]="filter?.options" 
                    [optionLabel]="filter?.optionLabel"
                    [emptyFilterMessage] = "filter?.emptyFilterMessage ? filter?.emptyFilterMessage : genericContents?.noResultsFound" 
                    [(ngModel)]="filter.selectedOption" 
                    (onChange)="onMultiSelectChange(filter)">
                  </p-multiSelect>
            </div>
          </div>
        </div>
    </div>
    <!-- Filter Manager -->
    <div class="filter-manager" *ngIf="showFilterClearBtn">
      <button class="btn sec-btn" (click)="onClearFilter()">Clear Filters</button>
    </div>
</div>