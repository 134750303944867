// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  // authApiUrl: 'http://18.219.52.202:8080/',
  // otherApiUrl: 'http://18.219.52.202:8081/',
  // featureApiUrl: 'http://18.219.52.202:8082/',
  // adminApiUrl: 'http://18.219.52.202:8086/'


  // authApiUrl: 'https://stage-shareddd.guardianrpm.com/',
  // featureApiUrl: 'https://stage-caredd.guardianrpm.com/',
  // otherApiUrl: 'https://stage-clientdd.guardianrpm.com/',
  // adminApiUrl: 'https://stage-admindd.guardianrpm.com/'

  authApiUrl: 'https://shared-api-stage.guardianrpm.com/',
  featureApiUrl: 'https://care-api-stage.guardianrpm.com/',
  otherApiUrl: 'https://client-api-stage.guardianrpm.com/',
  adminApiUrl: 'https://admin-api-stage.guardianrpm.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
