<div class="modal-header" *ngIf="title">
  <h5 class="modal-title pull-left">
      {{title}}
  </h5>
</div>
<div class="modal-body">
  <span class="main-con">
      {{message}}
  </span>
</div>
<div class="modal-footer">
  <div class="btn-con">
      <button class="btn submit-btn"
      (click)="onConfirm()" [title]="confirmBtnText">
          {{confirmBtnText}}
      </button>
  </div>
</div>
