export class ToastrMessages {

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Contains an oject with custom error messages for HTTP errors
     */
    public static HTTP_ERROR_MESSAGES = {
        ERROR_GENERIC: 'Some error occured',
        ERROR_INTERNAL: 'Something went wrong on the server.',
        ERROR_NETWORK: 'Something Went Wrong, Contact Administrator or Please check your internet connectivity',
        ERROR_FALIURE_0: 'API call failed',
        ERROR_BAD_REQUEST_400: 'Bad request.',
        ERROR_UNAUTHORIZED_ACCESS_401: 'Unauthorized access! Please login',
        ERROR_FORBIDDEN_403: 'Forbidden.',
        ERROR_NOT_FOUND_404: 'Not found.',
        ERROR_AUTHENTICATION_ERROR_429: 'Something went wrong, please refresh the page.',
        ERROR_SERVER_ERROR_500: 'Internal server error.',
        ERROR_SERVICE_ERROR_503: 'Service unavailable.',
        ERROR_GATEWAY_ERROR_504: 'Gateway timeout error, please try again later.',
    };

    public static AUTH_MESSAGES = {
        logoutSuccess: 'You have been logged out  of the application',
        logoutFail: 'Logout failed',
        unauthorizedAccess: 'You are not authorized to view this page',
        userInfoFailFetch: 'Failed to find user info'
    }

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Account verification related messages
     */
    public static ACCOUNT_ACTIVATION = {
        activateAccountSuccess: 'Account activated successfully',
        activateAccountFail: 'Oops! Account activatation failed',
    };

    /**
     * Author: Jeswin Chacko P A
     * Description: Alert messages in forgot-password page
     */
    public static FORGOTPASSWORD_FORM_MSGS = {
        forgotPasswordSuccess: 'Reset Link Sent Successfully',
        forgotPasswordFail: 'Reset Link Senting Failed',
    };

    /**
     * Author: Jeswin Chacko P A
     * Description: Account verification related messages
     */
    public static RESETPASSWORD_ACTIVATION = {
        activateResetPasswordSuccess: 'Reset Password activated successfully',
        activateResetPasswordFail: 'Oops! Reset Password activatation failed',
    };

    /**
     * Author: Jeswin Chacko P A
     * Description: Alert messages in reset-password page
     */
    public static RESETPASSWORD_FORM_MSGS = {
        resetPasswordSuccess: 'Password Changed Successfully',
        resetPasswordFail: 'Password Changing Failed',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores Generic titles of alert messages
     */
    public static TOASTR_TITLES = {
        success: 'Success',
        error: 'Error',
        warning: 'Warning',
        info: 'Info',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Contains the common form error messages
     */
    public static FORM_ERROR_MSGS = {
        invalidFormat: 'Invalid Format',
        invalidEmail: 'Invalid email address',
        OTPPattern: 'Please enter a valid OTP',
        fileUpload: 'File uploaded successfully',
        invalidCost: 'Please enter a valid amount',
        mandarotyFields: 'This field is mandatory',
        zeroDuration: 'Duration cannot be zero',
        fileUploadFail: 'Oops! File upload failed',
        invalidMobileNumber: 'Invalid mobile number',
        mandatoryToast: 'Please fill all the mandatory fields',
        passwordPattern: 'Password does not meet the criteria',
    };

    public static FILE_VALIDATIONS = {
        size: 'File size cannot exceed ',
        type: 'Invalid file type'
    }

    public static DATE_VALIDATIONS ={
        dateRangeValidation: 'End date should be greater than start date',
    }

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in login page
     */
    public static LOGIN_FORM_MSGS = {
        loginSuccess: 'Login Successful',
        loginFail: 'Login Failed',
        logoutFail: 'Logout Failed',
        logoutSuccess: 'Logout Success',
        noPermissionAssigned: 'You are not granted any permissions. Please contact the administrator',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in client listing
     */
    public static CLIENTS_LIST = {
        listFail: 'Failed to find clients list',
        listSuccess: 'Clients List Found',
        noInfoAvailable: `We don't have enough information to show in the profile`,
        profileUpdateFail: 'Failed to update Client details',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Alert messages in CSA form page
     */
    public static CSA_FORM_MSGS = {
        csaFormStatusSuccess: 'Form status found',
        csaFormStatusFail: 'Failed to find form status',
        tempSaverFail: 'Failed to save form information',
        tempSaverSuccess: 'CSA form saved successfully',
        formSubmitFail: 'Failed to submit CSA form information',
        formSubmitSuccess: 'CSA form submitted successfully',
    };

    public static TREATMENT_PLAN = {
        saveTreatmentPlan: 'Developmental Activity plan saved succssfully',
        activityFetchError: 'Oops! Failed to find the activity list',
        activityCreateSuccess: 'Activity created successfully',
        activityCreateError: 'Oops! Failed to create activity',
        activityUpdateSuccess: 'Activity updated successfully',
        activityUpdateError: 'Oops! Failed to update the activity',
        domainFetchError: 'Oops! Failed to find the domains list',
        activitySecSelected: 'Secondary matrix has been selected',
        activitySecRemoved: 'Secondary matrix has been removed',
        planSentSuccess: 'Plan has been sent to client',
        planSentFail: 'Failed to send plan to client',
    };

    public static TP_LOG = {
        logFetchFail: 'Failed to find log list'
    }

    public static MASTER_ACTIVITY = {
        deleteActivity: 'Activity deleted successfully',
        updateActivity: 'Activity updated successfully',
        createActivity: 'Activity created successfully',
        fetchListError: 'Failed to find activity list',
        deleteActivityFail: 'Failed to delete activity',
        updateActivityFail: 'Failed to update activity',
        createActivityFailed: 'Failed to create activity',
        fetchMatrixCOordinatesFail: 'Failed to find domains and sensors',
        activityTempSave: 'Treatment activity info updated temporarily'
    };

    public static DIAGNOSIS_LIST = {
      listFail: 'Failed to find diagnosis list',
      listSuccess: 'Diagnosis List Loaded',
    };

    public static CREATE_APPOINTMENT = {
        createFail: 'Failed to create appointment',
        createSuccess: 'Appointmnet created successfully',
      };

    public static INITIAL_EVALUATION = {
        listFail: 'Failed to find Initial Evaluation',
        listSuccess: ' Initial Evaluation found',
    };

    public static ASSIGN_CARE_PROVIDER = {
        assignSuccess: 'Care provider has been assigned to the client',
        reomveSuccess: 'Care provider has been removed successfully',
        assignSuccessFail: 'Failed to assign care provider to the client',
        fetchCareProvidersFail: 'Failed to find care providers list',
        removeCareProvidersFail: 'Failed to remove care provider',
    };

    public static CARE_PROVIDER = {
        deleteCP: 'Care Provider deleted successfully',
        updateCP: 'Care Provider updated successfully',
        createCP: 'Care Provider created successfully',
        fetchListError: 'Failed to find Care Provider list',
        deleteCPFail: 'Failed to delete Care Provider',
        updateCPFail: 'Failed to update Care Provider',
        createCPFailed: 'Failed to create Care Provider',

    };

    public static SETTINGS = {
        timeZoneSetSuccess: 'Tikmezone has been updated successfully',
        timeZoneSetSFail: 'Failed to update timezone',
    }

    public static INVOICE = {
        deleteInvoice: 'Invoice deleted successfully',
        updateInvoice: 'Invoice updated successfully',
        createInvoice: 'Invoice created successfully',
        fetchListError: 'Failed to find Invoice list',
        deleteInvoiceFail: 'Failed to delete Invoice',
        updateInvoiceFail: 'Failed to update Invoice',
        createInvoiceFailed: 'Failed to create Invoice',

    };

    public static REPORTS = {
        fetchReportsError: 'Failed to find Reports',

    };

    public static DOWNLOAD = {
        downloadError: 'Download Failed',
    };

    public static NOTES = {
      notesSaveFail: 'Failed to Save Notes',
      fetchNotesFail: 'Failed to find notes list',
    };

    public static DOCUMENTS = {
      documentsSaveSuccess: 'Document Saved',
      documentsSaveFail: 'Failed to Save Document',
      fetchDocumentsFail: 'Failed to find documents list',
      documentsRemoveFail: 'Failed to Remove Document Already Uploaded',
    };

    public static CALLIER = {
      fetchAreaFail: 'Failed to find areas and subscale',
      submitFail: 'Failed to submit callier test data',
      submitSuccess: 'Callier test data submitted successfully',
      fetchCallierFail: 'Failed to find callier test results',
    };

    public static FOLLOWUP = {
      saveFail: 'Failed to save follow-up data',
      saveSuccess: 'Follow-up data saved successfully',
      fetchFollowupFail: 'Failed to find follow-up results',
      fetchFollowupSuccess: 'Follow-up List Found',
    };


}
