import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let seconds = 0;
    let minutes = 0;
    let hours = 0;

    seconds = Math.floor( parseInt(value) / 1000);
    minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    hours = hours % 24;
    if (seconds >= 30) {
      minutes++;
    }

    return `${hours < 10 ? `0${hours}` : hours }h : ${minutes < 10 ? `0${minutes}` : minutes}m`;
  }

}
