import { Pipe, PipeTransform } from '@angular/core';
import { DropdownOption } from '@models/generic-types';
import { AppConstants } from '@utilities/app-constants';

@Pipe({
  name: 'applnStatus'
})

export class ApplnStatusPipe implements PipeTransform {

  applnStatusList: DropdownOption[];

  constructor() {
    this.applnStatusList = AppConstants.applnStatusDisplaytexts;
  }

  transform(value: unknown): string {
    let retVal = '';
    const resObj: DropdownOption = this.applnStatusList.find((item: DropdownOption) => item.value == value);
    if (resObj) {
      retVal = resObj.label;
    }
    return retVal;
  }

}
