import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimeNgPagination } from '@models/prime-ng-table';

@Component({
  selector: 'app-list-paginator',
  templateUrl: './list-paginator.component.html',
  styleUrls: ['./list-paginator.component.scss']
})
export class ListPaginatorComponent implements OnInit {

  @Input() paginationConfig: PrimeNgPagination;

  @Output() managePagination = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  onPageChange(pageInfo) {
    this.managePagination.emit(pageInfo);
}

}
