import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mobileFormat'
})
export class MobileFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    let opString = '';
    let valueString = String(value);
    valueString = valueString.replace(/\D+/g, '');
    opString += `(${valueString.slice(0, 3 )})-${valueString.slice(3, 6)}-${valueString.slice(6, 10)}`;

    return opString;
  }

}
