import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageTitleComponent } from './components/page-title/page-title.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { TableFiltersComponent } from './components/table-filters/table-filters.component';
import { CardListViewComponent } from './components/card-list-view/card-list-view.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { ListPaginatorComponent } from './components/list-paginator/list-paginator.component';
import { TableControllerComponent } from './components/table-controller/table-controller.component';


import { NgxMaskModule } from 'ngx-mask';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import { ThirdPartyConfig } from '@utilities/third-party-config';

import { PipesModule } from '@pipes/pipes.module';
import { DataTableComponent } from '@shared-components/data-table/data-table.component';
import { NormalDataTableComponent } from '@shared-components/normal-data-table/normal-data-table.component';

@NgModule({
  declarations: [
    DataTableComponent,
    AddClientComponent,
    PageTitleComponent,
    FileUploadComponent,
    ClientCardComponent,
    TableFiltersComponent,
    CardListViewComponent,
    SuccessDialogComponent,
    ConfirmDialogComponent,
    ListPaginatorComponent,
    TableControllerComponent,
    NormalDataTableComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    PaginatorModule,
    CalendarModule,
    InputSwitchModule,
    MultiSelectModule,
    VirtualScrollerModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(ThirdPartyConfig.inputMaskConfig)
  ],
  exports: [
    AddClientComponent,
    DataTableComponent,
    PageTitleComponent,
    FileUploadComponent,
    ClientCardComponent,
    ClientCardComponent,
    TableFiltersComponent,
    CardListViewComponent,
    ListPaginatorComponent,
    SuccessDialogComponent,
    ConfirmDialogComponent,
    TableControllerComponent,
    NormalDataTableComponent,
  ]
})
export class SharedModule { }
