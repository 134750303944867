import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AlertMsgService } from '@services/alert-msg/alert-msg.service';

import { AppConstants } from '@utilities/app-constants';
import { UtilityService } from '@utilities/utility.service';
import { ToastrMessages } from '@utilities/toastr-messages';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ThirdPartyConfig } from '@utilities/third-party-config';
import { TableConfigConstants } from '@utilities/table-config-constants';

import { TreatmentPlanService } from '../treatment-plan-detail/treatment-plan.service';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { TreatmentActivitiesService } from '@modules/treatment-activities/treatment-activities.service';

import { AlertMsg } from '@models/alert-msgs';
import { DropdownOption } from '@models/generic-types';
import { TreatmentActivityDetail, ActivityDetailRequestInfo, TreatmentActivities, TreatmentPlanManager } from '@models/treatment-activities';
import { ActivityDetailSubject, ActivityListHttpResponse, CreateActivityHttpResponse, MasterActivitiesParams, PackedActivityInfo } from '@models/master-activities';
import {
  ActivityDomain,
  ActivitySensor,
  FinalMatrixData,
  MatrixDomainObj,
  ActivityMatrixInfo,
  ActivityMatrixManager,
  MatrixCoordinatesHttpResponse,
} from '@models/activity-matrix';

@Component({
  selector: 'app-treatment-activity-detail',
  templateUrl: './treatment-activity-detail.component.html',
  styleUrls: ['./treatment-activity-detail.component.scss']
})
export class TreatmentActivityDetailComponent implements OnInit {

  @Output() updateActivity = new EventEmitter();

  // Incoming variables
  public viewMode: any;
  public title: string;
  public isMasterMode: boolean;
  public selectedActivityInfo: TreatmentActivities;

  // Generic Modal Contents
  cancelBtnText: string;
  confirmBtnText: string;

  // Form related variables
  formControls;
  activityDetailForm: FormGroup;

  // Flags
  isModalView: boolean;
  showConfirmBtn: boolean;
  isFormSubmitted: boolean;
  disableFormFields: boolean;
  disableNonEditableFields: boolean;

  // Subjects
  onCancelProcess = new Subject();
  onConfirmProcess = new Subject();

  // Constants
  errMsgs;
  defaultValues: any;
  calenderConfig: any;
  fieldValidations: any;
  textEditorConfig: any;

  // API call related variables
  activitiesListRequestParams: MasterActivitiesParams;

  // Arrays
  selectedWeekdays: number[];
  recurrenceTypes: DropdownOption[];

  // Arrays
  domainsList: ActivityDomain[];
  sensorsList: ActivitySensor[];
  weekdaysList: DropdownOption[];
  activityMatrix: ActivityMatrixInfo[];
  masterActivityList: PackedActivityInfo[];
  filteredMasterActivityList: PackedActivityInfo[];

  currentDate: any;
  zeroDuration: boolean;

  constructor (
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public utilityService: UtilityService,
    public alertMessageService: AlertMsgService,
    public rolesPermissionsService: TreatmentPlanService,
    public treatmentActivitiesService: TreatmentActivitiesService,
  ) {

    this.domainsList = [];
    this.sensorsList = [];
    this.selectedWeekdays = [];
    this.weekdaysList = AppConstants.weekDaysList;

    this.isModalView = false;
    this.zeroDuration = false;
    this.showConfirmBtn = true;
    this.isFormSubmitted = false;
    this.disableFormFields = false;
    this.disableNonEditableFields = false;

    this.currentDate = new Date();

    this.defaultValues = AppConstants.defaultValues;
    this.recurrenceTypes = AppConstants.recurrenceTypes;
    this.cancelBtnText = AppConstants.buttonTexts.cancel;

    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;

    this.fieldValidations = InputFieldLimits.activityDetail;

    this.textEditorConfig = ThirdPartyConfig.htmlEditorConfig;
    this.calenderConfig = ThirdPartyConfig.calenderDateOnlyConfig;
  }

  ngOnInit() {
    this.fetchMasterActivities();
    this.fetchDomainsSensors();
    this.initialiseActivityInfo();
    this.configureModalView();
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Initialises User Role form with necessary form fields
    */
  initialiseActivityInfo() {
    this.activityDetailForm = this.formBuilder.group({
      _id: [null, []],
      activityId: [null, []],
      activity_id: [null, []],
      activity_ref: [null, []],
      sensor: ['', [Validators.required]],
      domain: ['', [Validators.required]],
      activity_name: ['', [Validators.required]],
      start_date: [null, []],
      end_date: [null, []],
      duration_min: [0, []],
      duration_hours: [0, []],
      recurrence: ['', []],
      interval: [1, []],
      weekday: [this.selectedWeekdays, []],
      procedure: this.formBuilder.group({
        contents: ['', [Validators.required]],}),
      materials: this.formBuilder.group({
        contents: ['', [Validators.required]],}),
      expectation: ['', [Validators.required]],
      activity_matrix: ['', []],
      is_deleted: [false, []],
    },
    );
    this.formControls = this.activityDetailForm.controls;
    this.manageModeBasedValidations();
  }

  intialiseActivitiesListRequestParams() {
    this.activitiesListRequestParams = { sensorId: '', domainId: ''};
  }

  manageModeBasedValidations() {
    if (!this.isMasterMode) {
      this.activityDetailForm.controls['start_date'].setValidators([Validators.required]);
      this.activityDetailForm.controls['start_date'].updateValueAndValidity();
      this.activityDetailForm.controls['end_date'].setValidators([Validators.required]);
      this.activityDetailForm.controls['end_date'].updateValueAndValidity();
      this.activityDetailForm.controls['recurrence'].setValidators([Validators.required]);
      this.activityDetailForm.controls['recurrence'].updateValueAndValidity();
    }
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Based on the view mode set the button contents
    */
  configureModalView() {
    switch (this.viewMode.key) {
      case TableConfigConstants.crudOperationsMode.create.key:
        this.confirmBtnText = AppConstants.buttonTexts.create;
        break;
      case TableConfigConstants.crudOperationsMode.edit.key:
        this.patchSelectedActivityInfo();
        this.disableNonEditableFields =  true;
        this.confirmBtnText = AppConstants.buttonTexts.update;
        break;
      case TableConfigConstants.crudOperationsMode.view.key:
        this.isModalView = true;
        this.patchSelectedActivityInfo();
        this.showConfirmBtn = false;
        this.disableFormFields = true;
        this.disableNonEditableFields =  true;
        this.cancelBtnText = AppConstants.buttonTexts.close;
        break;
    }
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Populates values in the form field with respect to the selected activity data
    */
  patchSelectedActivityInfo(masterActivitySelection = false) {
    if (this.selectedActivityInfo) {
      this.selectedWeekdays = this.selectedActivityInfo.weekday;
      this.activityDetailForm.patchValue(this.selectedActivityInfo);
      if (!masterActivitySelection) {
        this.activityDetailForm.patchValue(
          {
            _id: this.selectedActivityInfo._id,
            activity_id: this.selectedActivityInfo._id
          });
      }
      if (this.isMasterMode) {
        this.activityDetailForm.patchValue({activity_id: this.selectedActivityInfo._id});
      }
      this.selectedWeekdays = this.selectedActivityInfo.weekday;
      this.activityDetailForm.patchValue(
        {
          start_date: this.utilityService.convertToLocalTime(this.selectedActivityInfo.start_date),
          end_date: this.utilityService.convertToLocalTime(this.selectedActivityInfo.end_date)
      });
    }
  }

  patchMatrixInfo() {
    const matrixVal = this.selectedActivityInfo.activity_matrix;
    this.activityMatrix = ActivityMatrixManager.constructActivityMatrixInfo(this.domainsList, this.sensorsList, matrixVal);
  }

  validateDateFields(): boolean {
    let isValid = true;
    const startDate = this.activityDetailForm.value.start_date;
    const endDate = this.activityDetailForm.value.end_date;
    const hrs = this.activityDetailForm.value.duration_hours;
    const min = this.activityDetailForm.value.duration_min;
    if (null !== endDate && null !== startDate) {
      if (startDate > endDate) {
        isValid = false;
        let alertMsg: AlertMsg = {
          msg: ToastrMessages.DATE_VALIDATIONS.dateRangeValidation,
          title: ToastrMessages.TOASTR_TITLES.success };
        this.alertMessageService.showWarningToster(alertMsg);
      }
    }

    return isValid;
  }

  validateDuration(): boolean{
    let isValid = true;
    const hrs = this.activityDetailForm.value.duration_hours;
    const min = this.activityDetailForm.value.duration_min;
    if(hrs == 0 && min == 0){
      isValid = false;
    }
    return isValid;
  }

  // ------------------- ERROR DISPLAY METHODS -----------------

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Displays error alert messages
    */
  showErrorToastr() {
    let failureMsg = '';
    if (this.viewMode.key ===  TableConfigConstants.crudOperationsMode.edit.key) {
      failureMsg = ToastrMessages.TREATMENT_PLAN.activityUpdateError;
    } else {
      failureMsg = ToastrMessages.TREATMENT_PLAN.activityCreateError;
    }
    const alertMsg: AlertMsg = {
      msg: failureMsg,
      title: ToastrMessages.TOASTR_TITLES.error
    }
    this.alertMessageService.showErrorToster(alertMsg);
  }

  // ------------------- DATA FORMATTING METHODS ----------------

  constructMatrixDomain() {
    this.activityMatrix = ActivityMatrixManager.constructActivityMatrixInfo(this.domainsList, this.sensorsList);
  }

  constructFinalRequestData(): ActivityDetailRequestInfo {
    const formVal = this.activityDetailForm.value;
    const activity_matrix = this.constructFinalActivityMatrix();
    let requestData: ActivityDetailRequestInfo;
    if (this.isMasterMode) {
      // Construct basic info object
      requestData =  {
        domain: formVal.domain,
        sensor: formVal.sensor,
        activity_name: formVal.activity_name,
        materials: formVal.materials,
        procedure: formVal.procedure,
        expectation: formVal.expectation,
        duration_min: formVal.duration_min,
        duration_hours: formVal.duration_hours,
        is_deleted: formVal.is_deleted,
        id: formVal.id ? formVal.id: null,
        activity_id: formVal._id ? formVal._id: null,
        activityId: formVal.activityId ? formVal.activityId: null,
        activity_matrix
      };
    }
    return requestData;
  }

  constructFinalActivityMatrix(): FinalMatrixData{
    let finalActivityMatrixInfo: FinalMatrixData = {
      primary_impact: { domain: null, sensor: null},
      secondary_impact: []
    };
    const domainId = this.activityDetailForm.value.domain;
    const sensorId = this.activityDetailForm.value.sensor;
    const domain = this.domainsList.find((domain: ActivityDomain) => domain._id == domainId);
    const sensor = this.sensorsList.find((sensor: ActivitySensor) => sensor._id == sensorId);
    finalActivityMatrixInfo.primary_impact = {
      domain: { _id: domain._id, id: domain.id, domainName: domain.domainName},
      sensor: { _id: sensor._id, id: sensor.id, sensorName: sensor.sensorName},
    };
    finalActivityMatrixInfo.secondary_impact = ActivityMatrixManager.constructSecondaryImpactInfo(this.activityMatrix);
    return finalActivityMatrixInfo;
  }

  filterMasterActivities(domainId: string, sensorId: string) {
    this.activitiesListRequestParams = {
      sensorId: sensorId,
      domainId: domainId
    };
    this.fetchMasterActivities();
  }

  constructPackedActivityInfo(): PackedActivityInfo {
    this.activityDetailForm.patchValue({activity_matrix: this.constructFinalActivityMatrix()});
    const packedActivity = TreatmentPlanManager.createPackedActivity(this.activityDetailForm.value);
    return packedActivity;
  }

  // ------------------- HTTP REQUESTS METHODS ------------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Fetches activities list from server
   */
  fetchMasterActivities() {
    if (!this.isMasterMode) {
      this.treatmentActivitiesService.fetchActivitiesList(this.activitiesListRequestParams).subscribe(
      (response: ActivityListHttpResponse) => {
        if (response.status) {
          this.masterActivityList = response.data.records;
        } else {
          const alert: AlertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.fetchListError, title: ToastrMessages.TOASTR_TITLES.error };
        }
      },
      (error) => {
        const alert: AlertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.fetchListError, title: ToastrMessages.TOASTR_TITLES.error };
        this.alertMessageService.showErrorToster(alert);
      }
    );
    }
  }

  fetchDomainsSensors() {
    const alert: AlertMsg = {
      msg: ToastrMessages.MASTER_ACTIVITY.fetchMatrixCOordinatesFail,
      title: ToastrMessages.TOASTR_TITLES.error
    };
    this.treatmentActivitiesService.fetchMatrixCoordinates().subscribe(
      (response: MatrixCoordinatesHttpResponse) => {
        if (response.status) {
          this.domainsList =  this.utilityService.constructDropdownFormat(response.data.domains, 'id', 'domainName');
          this.sensorsList =  this.utilityService.constructDropdownFormat(response.data.sensors, 'id', 'sensorName');
          this.constructMatrixDomain();
          if (this.viewMode.key != TableConfigConstants.crudOperationsMode.create.key) {
            this.patchMatrixInfo();
          }
        } else {
          this.alertMessageService.showErrorToster(alert);
        }
      },
      (error: HttpErrorResponse) => {
        this.alertMessageService.showErrorToster(alert);
      }
    );
  }

  postActivityInfo(requestData: ActivityDetailRequestInfo) {
    let msg = ToastrMessages.MASTER_ACTIVITY.updateActivity;
    if (this.viewMode.key == TableConfigConstants.crudOperationsMode.create.key) {
      msg = ToastrMessages.MASTER_ACTIVITY.createActivity;
    }
    let alertMsg = { msg, title: ToastrMessages.TOASTR_TITLES.success };
    this.treatmentActivitiesService.postActivity(requestData).subscribe(
      (response: CreateActivityHttpResponse) => {
        if (response.status) {
          this.onConfirm(response.data);
        }
      },
      (error: HttpErrorResponse) => {
        this.showErrorToastr();
      },
    );
  }

  // ------------ METHODS RELATED TO EVENTS -----------------

  onMasterActivitySelection() {
    if (this.activityDetailForm.value.activity_ref) {
      const refId = this.activityDetailForm.value.activity_ref;
      const refActivityDetail: PackedActivityInfo = this.masterActivityList.find(
        (activity: PackedActivityInfo) => activity.activity_details._id == refId
      );
      let newEntry: TreatmentActivities = { ...refActivityDetail.activity_details, activity_matrix: refActivityDetail.activity_matrix};
      this.selectedActivityInfo = newEntry;
      this.patchSelectedActivityInfo(true);
      this.patchMatrixInfo();
    }

  }

  manageMatrixValues() {
    const selectedDomain = this.activityDetailForm.value.domain;
    const selectedSensor = this.activityDetailForm.value.sensor;
    if (selectedSensor && selectedDomain) {
      this.setPrimaryMatrixVal(selectedDomain, selectedSensor);
    }
    if (!this.isMasterMode) {
      this.filterMasterActivities(selectedDomain, selectedSensor);
    }
  }

  setPrimaryMatrixVal(selectedDomainId: string, selectedSensorId: string) {
    const selectedDomainObj: ActivityDomain = this.domainsList.find( (domainItem: ActivityDomain) => domainItem.id == selectedDomainId);
    // Reset primary selection of all matrices
    this.activityMatrix.forEach(
          (matrixItem: ActivityMatrixInfo, index) => {
            for (let key in matrixItem.domainCoordinateX) {
                matrixItem.domainCoordinateX[key].is_primary = false;
            }
          }
        );
    // Set primary selected val to true
    const modifiedMatrix = this.activityMatrix.map(
      (matrixItem: ActivityMatrixInfo) => {
        let retVal = matrixItem;
        if (matrixItem.sensorCoordinateY.sensorId == selectedSensorId) {
          matrixItem.domainCoordinateX[selectedDomainObj.key].is_primary = true;
          matrixItem.domainCoordinateX[selectedDomainObj.key].is_secondary = false;
          retVal = matrixItem;
        }
        return retVal;
      }
    );
    this.activityMatrix = JSON.parse(JSON.stringify(modifiedMatrix));
  }

  onPrimaryMatrixSelectionChange(selctedMatrixVal: ActivityMatrixInfo, domainInfo: ActivityDomain) {
    this.activityDetailForm.patchValue(
      {
       sensor: selctedMatrixVal.sensorCoordinateY.sensorId,
       domain: domainInfo.id
      }
    );
    this.manageMatrixValues();
  }

  onRecurrenceTypeChange() {
    // const newRecurrenceVal = this.activityDetailForm.value.recurrence;
    this.selectedWeekdays = [];
  }

  onMatrixClick(domainObj: MatrixDomainObj) {
    domainObj.is_secondary = !domainObj.is_secondary;
    let alertMsg: AlertMsg = {
      msg: ToastrMessages.TREATMENT_PLAN.activitySecSelected,
      title: ToastrMessages.TOASTR_TITLES.info
    };
    if (!domainObj.is_secondary) {
      alertMsg.msg = ToastrMessages.TREATMENT_PLAN.activitySecRemoved
    }
    this.alertMessageService.showInfoToastr(alertMsg);
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Triggers the confirm process subject
    */
  onConfirm(activityInfo: PackedActivityInfo) {
    const activitySubject: ActivityDetailSubject = {
      activityData: activityInfo,
      status: true,
      mode: this.viewMode
    };
    this.onConfirmProcess.next(activitySubject);
    this.bsModalRef.hide();
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Handles cancel button click
    */
  onCancel() {
    this.onCancelProcess.next(true);
    this.bsModalRef.hide();
  }

  onActivitySubmit() {
    this.isFormSubmitted = true;
    this.activityDetailForm.patchValue({weekday: this.selectedWeekdays});
    if(!this.validateDuration()){
      this.zeroDuration = true;
      return
    }
    if (this.validateDateFields()) {
      this.zeroDuration = false;
      if (this.activityDetailForm.valid) {
        if (this.isMasterMode) {
          const requestData: ActivityDetailRequestInfo = this.constructFinalRequestData();
          this.postActivityInfo(requestData);
        } else {
          let alertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.activityTempSave, title: ToastrMessages.TOASTR_TITLES.success };
          this.alertMessageService.showSuccessToster(alertMsg);
          this.onConfirm(this.constructPackedActivityInfo());
        }
      }
    }
  }

}
