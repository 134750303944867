import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CareProviderDetails, CreateScheduleResponse, GetCareProviderResponse, GetPatientsResponse, PatientDetails } from '@models/appointments';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreateScheduleService {

  constructor(
    private http: HttpClient
  ) { }

  // ---------------- API METHODS ----------------

  /**
   * Author: Gokul Krishna
   * Description: Makes the get request for patients with the search credentials
   */
  getPatients(requestParams): Observable<PatientDetails[]> {
    const apiUrl = ApiUrls.CREATE_SCHEDULE.getPatients;
    const paramsObj = {
      params: requestParams
    };
    return this.http.get(apiUrl, paramsObj).pipe(
      map(
        (response: GetPatientsResponse) => {
          return response.data
        }
      ),
    );
  }


  getCareProviders(requestParams): Observable<CareProviderDetails[]> {
    const apiUrl = ApiUrls.CREATE_SCHEDULE.getCareProviders;
    const paramsObj = {
      params: requestParams
    };
    return this.http.get(apiUrl, paramsObj).pipe(
      map(
        (response: GetCareProviderResponse) => {
          return response.data
        }
      ),
    );
  }

  createSchedule(data) : Observable<CreateScheduleResponse>{
    const apiUrl = ApiUrls.CREATE_SCHEDULE.createSchedule;
    return this.http.post(apiUrl, data).pipe(
      map(
        (response: CreateScheduleResponse) => {
          return response;
        }
      )
    )
  }
}
