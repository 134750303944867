import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '@services/timezone/timezone.service';
import * as moment from 'moment';


@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  timeZone: any;

  constructor(private timezoneService: TimezoneService) {}

  transform(value: any, args?: Boolean): any {
    let retVal = '';
    if (!value) {
      retVal = '';
    }
    if (args) {
      retVal = moment(value).format('MMM d, y, h:mm:ss a');
    }
    else {
      this.timeZone = this.timezoneService.getUserTimeZone();

      if (this.timeZone) {
        retVal = moment(value).tz(this.timeZone).format('MMM d, y, h:mm:ss a');
      } else {
        retVal = moment(value).format('MMM d, y, h:mm:ss a');
      }
    }
    return retVal;
  }

  // transform(value: number, args?: any): any {
  //   if (!value) {
  //     return '';
  //   }

  //   this.timeZone = this.timezoneService.getUserTimeZone();

  //   return moment(value).utc().tz(this.timeZone).format('hh:mm A MMM DD, YYYY');
  // }

}
