import { LocalStorageKeywords } from '@models/local-storage-keywords';

export class AuthConstants {

    public static mainRoleKeywords = {
        careProvider: 'care-provider'
    };

    public static userRoleIdentifiers = {
        staffAdmin: 'staff-admin',
        expert: 'expert',
        doctor: 'doctor',
        admin: 'admin',
    };

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Stores the keywords used to store values in memory
     */
    public static localStorageVariables: LocalStorageKeywords = {
        access_token: 'access_token',
        refresh_token: 'refresh_token',
        tokenType: 'Bearer ',
        reference: 'reference',
        userId: 'userId',
        referenceId: 'referenceId',
    };

    public static permissionModes  = {
        view: 'view',
        modifiy: 'modifiy'
    };

}
