export class ApiUrls {

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Stores the api-urls related to auth service
   */
  public static AUTH_SERVICE = {
    logout: 'logout/',
    login: 'login/',
    userDetails: 'getLoggedinUser/',
    refreshToken: 'token',
    confirm_account: 'v1/signup/confirm',
    resetPassword: 'reset/password',
    forgotPassword: 'forgot/password',
    changePassword: 'change/password',
    updateSettings: 'update/settings',
  };

  /**
   * Author: Gokul Krishna
   * Description: Stores the api-urls related to create-schedule service
   */
  public static CREATE_SCHEDULE = {
    getPatients: 'getpatients',
    getCareProviders: 'getcareproviders',
    getAllPatients: 'getpatients?keyword=',
    getAllCareProviders: 'getcareproviders?keyword=',
    createSchedule: 'createappointment'
  };

  /**
 * Author: Gokul Krishna
 * Description: Stores the api-urls related to appointments service
 */
  public static APPOINTMENTS_SERVICE = {
    getCalendarAppointments: 'getcalenderdates',
    getSchedules: 'getSchedules',
    getSession: 'getappointment/',
    cancelSession: 'telemedicine/'
  };

  public static ADD_CLIENTS = {
    addClient: 'addPatient'
  };

      /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores the api-urls common to all services
     */
    public static COMMON_SERVICE = {
      // fileUpload: '/file-upload/'
      fileUpload: 'upload'

    };

    /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Stores the api-urls related to csa form service
   */
  public static CSA_FORM = {
    formTempSave: 'savecsatmp',
    getTempFormInfo: 'getcsatmp/',
    getFinalFormInfo: 'getcsafinal/',
    getCountryList: 'country',
    getStatesList: 'states',
    formFinalSubmit: 'savecsafinal',
    getLiabilityFormStatus: 'getcsafinal',
    downloadCSAFinal: 'downloadcsafinal',
};

  public static SESSION_SERVICE = {
    start: 'startsession/',
    startRecord: 'archive/',
    stopRecord: 'archive/',
    addParticipant: 'session/'
  };

  public static CLIENTS = {
    fetchList: 'listpatients',
    patientProfile: 'patientprofile',
    patientProfileUpdate: 'updatePatient'
  }

  public static INITIAL_EVALUATION = {
    formIESave: 'saveevaluation',
    formIEGet: 'getevaluation',
    downloadInitialEvaluation: 'downloadevaluation',
  };

  public static ACTIVITIES = {
    saveActivity: 'saveactivity',
    masterActivities: 'getactivitylist',
    matrixCoordinates: 'getdomainssensors',
    test: 'test'
  };

  public static TREATMENT_PLAN = {
    saveTp: 'savetp',
    submitTp: 'submittp',
    gettpdetails: 'gettpdetails',
    tpChangeLog: 'gettpchangelog',
    developmentReport:'getreport',
  };

  public static TREATMENTPLAN__SERVICE = {
    submittreatmentplan: 'setpaymentprofile'
  };

  public static DIAGNOSIS = {
    listDomains: 'getdomains',
    formDiagnosisGet: 'getdiagnoses',
    formDiagnosisSave: 'savediagnosis',
  };

  public static TP_SCHEDULED_ACTIVITY_SERVICE = {
    getActivitySchedules: 'gettpcalenderdates',
    completeActivity:'completeactivity'

  };
  public static EVALUATION_FEEDBACK_SERVICE = {
    getFeedbackQuestions: 'getfeedbackqns',
    postFeedback: 'submitfeedback',
  };

  public static ASSIGN_CARE_PROVIDER = {
    careProvidersList: 'listcareproviders',
    assignCareProvider: 'assigncareprovider'
  };


  public static CAREPROVIDERS = {
    careProvidersList: 'listcareproviders',
    addCareProvider: 'add/careprovider',
    editCareProvider: 'edit/careprovider',

  };

  public static ACTIVITY_DETAIL = {
    getTpActivityList: 'gettpactivities'
  }

  public static INVOICES = {
    getAllInvoices: 'getallinvoices',
    editInvoice: 'edit/invoice',
    invoiceStatusChange: 'invoice/status_change',
    downloadInvoice: 'download/invoice',
    downloadCSV: 'download/csv',
  }

  public static REPORTS = {
    getManagementSummary: 'getmgmntsummary',
    getTotalPatients: 'getreport/totalpatients',
    getTotalInProgram: 'getreport/totalinprogram',
    getTotalReleased: 'getreport/totalreleased',
    getTotalDiscontinued: 'getreport/totaldiscontinued',
    getTotalMale: 'getreport/totalmale',
    getTotalFemale: 'getreport/totalfemale',
    getTotalCantsay: 'getreport/totalcantsay',
    getTotalImproved: 'getreport/totalimproved',
    getTotalStaysame: 'getreport/totalstaysame',
    getTotalRegressed: 'getreport/totalregressed',
    getTotalDiagnosed: 'getreport/totaldiagnosed',
    getTotalNotDiagnosed: 'getreport/totalnotdiagnosed',
    getStatusNotUpdated: 'getreport/statusnotupdated',
    getAgeList:'getreport/agelist',
    getStayList:'getreport/staylist',
  }

  public static ACTIVITY_LOGS = {
    activityLog: 'activitylog'
  }

  public static NOTES = {
    addNotes: 'add/note',
    getNotes: 'get/notes'
  }

  public static DOCUMENTS = {
    addDocument: 'patient/document',
    cancelDocument: 'cancel/document',
    patient: 'patient/documents',
  }

  public static CALLIER = {
    getMasterCallier: 'get/master/callier',
    addCallier: 'add/callier',
    getCallier: 'get/callier',
    getLatestCallier: '/get/latest/callier'
  }

  public static FOLLOWUP = {
    saveFollowup: 'savefollowup',
    getFollowups: 'getfollowups',
  }
}
