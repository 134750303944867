import { MenuItem } from '@models/menu-item';

export class MenuList {

  public static staffAdminMenu: MenuItem[] = [
    {
      id: 2,
      key: 'clients',
      link: '/clients',
      title: 'Clients',
      showOnList: true
    },
    {
      id: 3,
      key: 'care-providers',
      link: '/care-providers',
      title: 'Care Providers',
      showOnList: true
    },
    {
      id: 4,
      key: 'appointments',
      link: '/appointments',
      title: 'Appointments',
      showOnList: true
    },
    {
      id: 5,
      key: 'treatment-activities',
      link: '/treatment-activities',
      title: 'Master Activities',
      showOnList: true
    },
    {
      id: 8,
      key: 'TRAINING',
      link: 'http://stage-knowledge.guardianrpm.com/',
      title: 'Training',
      showOnList: false
    },
  ];

  public static doctorMenu: MenuItem[] = [
    {
      id: 7,
      key: 'clients',
      link: '/clients',
      title: 'Clients',
      showOnList: true
    },
    {
      id: 8,
      key: 'appointments',
      link: '/appointments',
      title: 'Appointments',
      showOnList: true
    },
    {
      id: 5,
      key: 'treatment-activities',
      link: '/treatment-activities',
      title: 'Master Activities',
      showOnList: true
    },
    {
      id: 8,
      key: 'TRAINING',
      link: 'http://stage-knowledge.guardianrpm.com/',
      title: 'Training',
      showOnList: false
    },
  ];

  public static expertMenu: MenuItem[] = [
    {
      id: 12,
      key: 'clients',
      link: '/clients',
      title: 'Clients',
      showOnList: true
    },
    {
      id: 13,
      key: 'appointments',
      link: '/appointments',
      title: 'Appointments',
      showOnList: true
    },
    {
      id: 5,
      key: 'treatment-activities',
      link: '/treatment-activities',
      title: 'Master Activities',
      showOnList: true
    },
    {
      id: 8,
      key: 'TRAINING',
      link: 'http://stage-knowledge.guardianrpm.com/',
      title: 'Training',
      showOnList: false
    },
  ];

  public static allMenuItems: MenuItem[] = [
    {
      id: 1,
      key: 'CLIENT_LISTING_VIEW',
      link: '/clients',
      title: 'Clients',
      showOnList: false
    },
    {
      id: 3,
      key: 'CARE_PROVIDER_LIST',
      link: '/care-providers',
      title: 'Care Providers',
      showOnList: false
    },
    {
      id: 4,
      key: 'APPOINTMENT_PARTICIPATION',
      link: '/appointments',
      title: 'Appointments',
      showOnList: false
    },
    {
      id: 5,
      key: 'ACTIVITIES_VIEW',
      link: '/treatment-activities',
      title: 'Master Activities',
      showOnList: false
    },
    {
      id: 6,
      key: 'INVOICES_LIST',
      link: '/invoices',
      title: 'Invoices',
      showOnList: false
    },
    {
      id: 7,
      key: 'INVOICES_LIST',
      link: '/reports',
      title: 'Reports',
      showOnList: false
    },
    {
      id: 8,
      key: 'TRAINING',
      link: 'http://stage-knowledge.guardianrpm.com/',
      title: 'Training',
      showOnList: false
    },
  ];

}
