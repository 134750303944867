<!-- TABLE TITLE -->
<div class="table-info" *ngIf="tableTitle">
    <h3 class="title table-title">{{tableTitle}}</h3>
</div>
<!-- TABLE VIEW -->
<p-table
#dataTable
*ngIf="tableItems"
immutable="false"
responsive="true"
autoLayout = true
resetPageOnSort=true
resizableColumns="true"
columnResizeMode="expand"
[value]="tableItems"
[columns]="tableColumns"
[totalRecords]="tableItems?.length"
[dataKey]="tableSettings?.rowIdentifierKey"
[scrollable]="tableSettings?.scrollable"
[scrollHeight]="tableSettings?.scrollHeight"
[paginator]="tableSettings?.pagination?.enable"
[rows]="tableSettings?.pagination.itemsPerPage">
    <!--  Search Field -->
    <ng-template pTemplate="caption" *ngIf="!tableSettings?.noSearch">
        <div style="text-align: right">
            <input
            class="form-control"
            type="text"
            pInputText size="55"
            [placeholder]="tableSettings?.searchPlaceholder"
            (input)="onSearchInput($event.target.value)" style="width:auto">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
        </div>
    </ng-template>
    <!-- Table Header -->
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th
            class="expand-con-col"
            *ngIf="tableSettings?.expandableRows"></th>
            <th
            *ngFor="let col of tableColumns"
            [ngClass]="col?.type"
            [ngStyle]="{'width':col?.width}"
            [class.name]="col?.sub_type == dataTypes?.name"
            [pSortableColumn]="col.field">
                {{col?.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <!-- Main Table Body -->
    <ng-template pTemplate="body"
        let-item
        let-rowData
        let-i="rowIndex"
        let-columns="columns"
        let-expanded="expanded">
        <tr>
            <td
            class="expand-con-col"
            *ngIf="tableSettings?.expandableRows">
                <i
                [pRowToggler]="rowData"
                class="fa action-icon"
                [title]="expanded ? 'Click to minimise view' : 'Click to expand view'"
                [ngClass]="expanded ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
            </td>
            <td
            [ngClass]="col?.type"
            class="table-data"
            [ngStyle]="{'width':col?.width}"
            [class.name]="col?.sub_type == dataTypes?.name"
            *ngFor="let col of tableColumns">
                <!-- TABLE DATA COLS -->
                    <!-- Normal datas are displayed without any formatting -->
                    <span class="table-val"
                        *ngIf="col.type === dataTypes.slNo">
                        {{i + 1}}
                    </span>
                    <!-- Normal datas are displayed without any formatting -->
                    <span class="table-val"
                        [title]="item[col?.field]"
                        *ngIf="col.type === dataTypes.data">
                        {{item[col?.field]}}
                    </span>
                    <!-- Array Items Field -->
                    <span class="table-val"
                        *ngIf="col.type === dataTypes.arrayItems">
                            <div *ngFor="let loopedData of item[col?.childDataKey]">
                                <span class="child-data"
                                [ngClass]="{
                                    defaultActive: col?.active,
                                    active: loopedData?.is_active,
                                    inactive: !loopedData?.is_active}">
                                    {{loopedData[col?.childDataValRef]}}
                                </span>
                            </div>
                    </span>
                    <!-- Date columns -->
                    <span class="table-val"
                    [title]="item[col?.field]"
                    *ngIf="col.type === dataTypes.date">
                    {{item[col?.field] | date: genericFormats?.dateFormat}}
                    </span>
                    <!-- Date Time Columns -->
                    <span class="table-val"
                        [title]="item[col?.field] | date: genericFormats?.dateTimeFormat"
                        *ngIf="col.type === dataTypes.dateTime">
                        {{item[col?.field] | date: genericFormats?.dateTimeFormat}}
                    </span>
                <!-- TABLE ACTION COL -->
                    <div class="action-con"
                        *ngIf="col.type === dataTypes.action">
                        <div class="action-wrap"
                            [title]="actionInfo?.label"
                            (click)="onActionClick(actionInfo, item)"
                            *ngFor="let actionInfo of tableActions"
                            [ngClass]="{'empty-action': !item?.action[actionInfo.action]}">
                            <div class="action-btn"
                                *ngIf="item?.action[actionInfo.action] == true && actionInfo?.enable">
                                    <i
                                    class="fa action-icon" [ngClass]="actionInfo?.icon" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
            </td>
        </tr>
    </ng-template>
    <!-- Expanded Row View -->
    <ng-template
    pTemplate="rowexpansion"
    let-rowData
    let-item
    let-columns="columns"
    let-i="rowIndex">
        <tr class="row-data-wrap">
            <td [attr.colspan]="columns.length + 1" class="row-data-wrap">
                <div class="row-data-wrap">
                   <div class="row">
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <label class="label">Feedback Category</label>
                            <span class="description d-block">{{item?.category_title}}</span>
                        </div>
                       <div class="col-sm-12 col-md-3 col-lg-3">
                            <label class="label">User Name</label>
                            <span class="description d-block">{{item?.user_name}}</span>
                       </div>
                       <div class="col-sm-12 col-md-3 col-lg-3">
                            <label class="label">User Key</label>
                            <span class="description d-block">{{item?.user_key}}</span>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <label class="label">Mobile Number</label>
                            <span class="description d-block">{{item?.user_mobile_number}}</span>
                       </div>
                       <hr class="full-width">
                       <div class="col-sm-12 col-md-12 col-lg-12">
                           <label class="label">Comment</label>
                           <span class="description d-block">{{item?.comment}}</span>
                    </div>
                   </div>
                </div>
            </td>
        </tr>
    </ng-template>
    <!-- No Data Container -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="no-data-text">{{tableSettings?.noDataText}}</td>
        </tr>
    </ng-template>
</p-table>
