import { Injectable } from '@angular/core';
import { AuthConstants } from '@utilities/auth-constants';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Clears values stored in local storage
   */
  clearLocalStorageValues() {
    localStorage.clear();
  }

  setUserIdInStorage(userId: string) {
    localStorage.setItem(AuthConstants.localStorageVariables.userId, userId);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Fetches access token value from storage 
   */
  getTokenValueFromStorage(): string {
    return localStorage.getItem(AuthConstants.localStorageVariables.access_token);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Fetches userId value from storage 
   */
  getUserIdFromStorage(): string {
    return localStorage.getItem(AuthConstants.localStorageVariables.userId);
  }

}
