import { Observable, Subscription } from 'rxjs';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';

import { AuthService } from '@services/auth/auth.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';

import { AppConstants } from '@utilities/app-constants';
import { UtilityService } from '@utilities/utility.service';
import { ToastrMessages } from '@utilities/toastr-messages';

import { AlertMsg } from '@models/alert-msgs';
import { LoggedUserInfo } from '@models/logged-user';
import { RoleFeatureInfo, UserPermission } from '@models/role-info';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild, OnInit, OnDestroy {

  // User Info
  userInfo: LoggedUserInfo;
  userPermission: UserPermission;

  // Flags
  allowRouting: boolean;
  isTokenPresent: boolean;

  // Subscriptions
  userInfoSubscription$ = new Subscription();
  userPermissionSubscription$ = new Subscription();

  constructor (
    private router: Router,
    private authService: AuthService,
    private utilityService: UtilityService,
    private alertMsgService: AlertMsgService,
  ) {
    this.userPermission = {
      role_type: null,
      permissions: [],
    }
    this.allowRouting = true;
    this.subscribeToUserPermission();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.userPermissionSubscription$.unsubscribe();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Checks if token is present
    this.allowRouting = this.authService.isAuthenticated();

    if (this.allowRouting) {
      this.allowRouting = this.checkRoleCompatability(state, route);
    }
    if (!this.allowRouting) {
      const alert: AlertMsg = { title: ToastrMessages.TOASTR_TITLES.error, msg: ToastrMessages.AUTH_MESSAGES.unauthorizedAccess};
      this.alertMsgService.showErrorToster(alert);
      this.router.navigateByUrl(AppConstants.redirectUrls.login)
    }
    return this.allowRouting;

  }

  checkRoleCompatability(state, route): boolean {
    // console.log('ROUTE CONFIG INFO: ', state.url);
    let isRoleMatching = false;
    // console.log('MENU KEY: ', route.data.menuKey.key);
    const menuKey = route.data.menuKey.key;
    // console.log('PERMISSIONS: ', this.userPermission.permissions);
    if (this.userPermission.permissions.length > 0) {
      const matchIndex = this.userPermission.permissions.findIndex((permission: RoleFeatureInfo) => permission.feature_code == menuKey);
      // console.log('MATCH INDEX:', matchIndex);
      if (matchIndex != -1) {
        isRoleMatching = true;
      }
    } else {
        isRoleMatching = true;
    }
    // console.log('IS ROLE MATCHING: ', isRoleMatching);
    return isRoleMatching;
  }

  subscribeToUserPermission() {
    this.userPermissionSubscription$ = this.authService.userPermissions.subscribe(
      (permission: UserPermission) => {
        // console.log('OLD USER PERMISSION VALUE: ', this.userPermission);
        // console.log('Incoming Permission info: ', permission);
        if (permission.role_type) {
          this.userPermission = permission;
          // console.log('SUBSCRIPTION OF ROLE GUARD TRIGGERED! ', this.userPermission);
          // console.log('SUBSCRIBED PERMISSION VALUE: ', this.userPermission);
        }
      }
    );
  }

}
