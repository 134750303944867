import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-list-view',
  templateUrl: './card-list-view.component.html',
  styleUrls: ['./card-list-view.component.scss']
})

export class CardListViewComponent implements OnInit {
   
  constructor() {}

  ngOnInit() {}

}
