import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { TableController } from '@models/table';
import { Calendar } from 'primeng/calendar';
import { TableFilter, TableFilterChangeResult, TableFilterOption } from '@models/table-filter';
import { AppConstants } from '@utilities/app-constants';
import { TableConfigConstants } from '@utilities/table-config-constants';
import { ThirdPartyConfig } from '@utilities/third-party-config';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {

  // Incoming variables
  @Input() filters: TableFilter[];
  @Input() showFilterClearBtn: boolean;

  // Event emitters
  @Output() filterChange = new EventEmitter();
  @Output() onResetFilter = new EventEmitter();

  currentDate: any;

  // General Variables
  filterTypes: any;
  genericContents: any;
  dateOnlyConfig: any;
  dateRangeConfig: any;
  dateTimeRangeConfig: any;
  defaultOption: TableFilterOption;
  selectedFilterInfo: TableFilterChangeResult;

  constructor() {
    this.showFilterClearBtn = false;
    this.currentDate = new Date();
    this.filterTypes = TableConfigConstants.FILTER_TYPES;
    this.genericContents = TableConfigConstants.GENERIC_TABLE_CONTENTS;
    this.dateOnlyConfig = ThirdPartyConfig.calenderDateOnlyConfig;
    this.dateRangeConfig = ThirdPartyConfig.dateRangeCalenderConfig;
    this.defaultOption = TableConfigConstants.GENERIC_TABLE_CONTENTS.defaultSelectAll;
  }

  ngOnInit() {}

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: INvoked when a change 
   */
  onFilterSelectionChange(selectedFilter: TableFilter) {
    const selectedOption = selectedFilter.options.find(
      (option: TableFilterOption) => {
        return option.value == selectedFilter.selectedOption;
      });
    this.selectedFilterInfo = {
      type: selectedFilter.type,
      filterId: selectedFilter.filterId,
      filterTitle: selectedFilter.filterTitle,
      selectedOption: selectedFilter.selectedOption ? selectedFilter.selectedOption : '',
      selectedOptionLabel: selectedOption ? selectedOption.label: ''
    };
    this.filterChange.emit(this.selectedFilterInfo);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Validates the date range information
   */
  onDateChange(filter: TableFilter) {
    if (filter.selectedOption) {
      if (filter.selectedOption[0] != null && filter.selectedOption[1] && filter.selectedOption[1] != null) {
        this.selectedFilterInfo = {
          type: filter.type,
          filterId: filter.filterId,
          filterTitle: filter.filterTitle,
          selectedOption: filter.selectedOption,
        };
        this.filterChange.emit(this.selectedFilterInfo);
      }
    }
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Invoked when clear button is clicked on the date picker
   */
  onClearDate(filter: TableFilter) {
    this.selectedFilterInfo = {
      type: filter.type,
      filterId: filter.filterId,
      filterTitle: filter.filterTitle,
      clearFilter: true,
      selectedOption: filter.selectedOption,
    };
    this.filterChange.emit(this.selectedFilterInfo);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Validates the date picker information
   */
  onDateOnlySelectionChange(filter: TableFilter) {
    if (filter.selectedOption) {
        this.selectedFilterInfo = {
          type: filter.type,
          filterId: filter.filterId,
          filterTitle: filter.filterTitle,
          selectedOption: filter.selectedOption,
        };
        this.filterChange.emit(this.selectedFilterInfo);
    }
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Triggered no multiselect change
   */
  onMultiSelectChange(filter: TableFilter) {
      if (filter.selectedOption) {
        if (filter.selectedOption.length > 0) {
          this.selectedFilterInfo = {
            type: filter.type,
            filterId: filter.filterId,
            filterTitle: filter.filterTitle,
            selectedOption: filter.selectedOption,
          };
        } else {
          this.selectedFilterInfo.selectedOption = [];
        }
        this.filterChange.emit(this.selectedFilterInfo);
      }
  }

  onClearFilter() {
    this.onResetFilter.emit();
  }

}
