import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { AlertMsg } from '@models/alert-msgs';
import { SignInResponseData } from '@models/sign-in';
import { LoggedUserInfo } from '@models/logged-user';
import { TokenInfo } from '@models/basic-configuration';
import { RefreshTokenRequest, RefreshTokenResponse } from '@models/token-auth';

import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';

import { ApiUrls } from '@utilities/api-urls';
import { AppConstants } from '@utilities/app-constants';
import { AuthConstants } from '@utilities/auth-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { RoleMockData } from 'src/app/shared/mock/role-feature-mock';
import { RoleFeatureInfo, UserPermission } from '@models/role-info';
import { ActivateAccountRequest, ActivateAccountResponse } from '@models/activate-account';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  permissions: UserPermission = {
    role_type: null,
    permissions: []
  };

  isPermissionsLoading: boolean;

  userInfoSubscription$ = new Subscription();
  currentUserSubject = new BehaviorSubject<LoggedUserInfo>({} as LoggedUserInfo);

  userPermissionsSubject = new BehaviorSubject<UserPermission>(this.permissions);
  userPermissions = this.userPermissionsSubject.asObservable();

  currentUser = this.currentUserSubject.asObservable();
  userData: LoggedUserInfo;

  constructor(
    private router: Router,
    private http: HttpClient,
    private utilityService: UtilityService,
    private alertMessageService: AlertMsgService,
    private storageService: SessionStorageService
  ) {
    this.isPermissionsLoading = false;
   }

  // ---------- AUTHORIZATION METHODS ------------

  setUserVariables(loginInfo: LoggedUserInfo, reAssignToken: boolean = true) {
    if (reAssignToken) {
      this.setUserTokenInStorage(loginInfo.token);
      this.setUserIdInStorage(loginInfo._id);
    }
    this.triggerUserBasedSubjects(loginInfo);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { loggedUserInfo } userData
   * Description: Emits the user permissions observable
   */
  triggerUserPermissionsInfo(roleType: string, permissions: any) {
    let updatedPermissionInfo: UserPermission = {
      role_type: roleType,
      permissions: permissions,
    };
    this.isPermissionsLoading = false;
    this.permissions = updatedPermissionInfo;
    this.userPermissionsSubject.next(updatedPermissionInfo);
    // console.log('USER PERMISSIONS: ', updatedPermissionInfo.permissions);
  }

  refreshToken(): Observable<string> {
    /*
        The call that goes in here will use the existing refresh token to call
        a method on the oAuth server (usually called refreshToken) to get a new
        authorization token for the API calls.
    */
    const apiUrl = ApiUrls.AUTH_SERVICE.refreshToken;
    const requestParams: RefreshTokenRequest = { 'refresh_token': localStorage.getItem('refresh_token') };
    return this.http.post(apiUrl, requestParams).pipe(
      map(
        (res: RefreshTokenResponse) => {
          if (res.status) {
            this.setUserTokenInStorage(res.data);
            return res.data.access_token;
          }
        }),
    )

  }

  isAuthenticated(): boolean {
    let retVal: boolean = false;
    if (this.utilityService.checkTokenInStorage()) {
      retVal = true;
    }
    return retVal;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Clears values stored in local storage
   */
  logoutUser(): Observable<any> {
    const apiUrl = ApiUrls.AUTH_SERVICE.logout;
    return this.http.post(apiUrl, {}).pipe(
      map(
        (data: any) => {
          if (data) {
            this.purgeAuth();
            const alert: AlertMsg = { msg: ToastrMessages.AUTH_MESSAGES.logoutSuccess, title: '' };
            this.alertMessageService.showSuccessToster(alert)
            this.storageService.clearLocalStorageValues();
            this.router.navigateByUrl(AppConstants.redirectUrls.login);
          } else {
            this.purgeAuth();
            this.storageService.clearLocalStorageValues();
            this.utilityService.showResponseError(ToastrMessages.AUTH_MESSAGES.logoutFail)
          }
          return data;
        },
        (error) => {
          this.purgeAuth();
          this.storageService.clearLocalStorageValues();
          this.utilityService.showResponseError(ToastrMessages.AUTH_MESSAGES.logoutFail)
          this.router.navigateByUrl(AppConstants.redirectUrls.login);
        }
      ));
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Resets the token if unauthenticated
   */
  purgeAuth() {
    this.storageService.clearLocalStorageValues();
    this.currentUserSubject.next({} as LoggedUserInfo);
  }

  // ---------------------------- PERMISSIONS & DETAILS --------------------

  fetchPersonalInfo(): Observable<any> {
    const apiUrl = ApiUrls.AUTH_SERVICE.userDetails;
    return this.http.get(apiUrl).pipe(
      map(
        (response: SignInResponseData) => {
          this.setUserVariables(response.data, false);
          this.triggerUserPermissionsInfo(response.data.role, response.data.features[0]);
          return response;
        },
      ),
    );
  }

  // ------------------ SUBJECT RELATED VARIABLES --------------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Sets the user unique token in local storage
   */
  setUserTokenInStorage(tokenInfo: TokenInfo) {
    // Access Token
    localStorage.setItem(
      AuthConstants.localStorageVariables.access_token,
      `${AuthConstants.localStorageVariables.tokenType}${tokenInfo.access_token}`);
  }

  setUserIdInStorage(userId: string) {
    localStorage.setItem(AuthConstants.localStorageVariables.userId, `${userId}`);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { loggedUserInfo } userData
   * Description: Sets the user unique token in local storage
   */
  triggerUserBasedSubjects(userData: LoggedUserInfo) {
    this.currentUserSubject.next(userData);
  }

  checkPermission(type: string, value: number): boolean {
    this.userInfoSubscription$ = this.currentUser.subscribe(
      (userData: LoggedUserInfo) => {
        this.userData = userData;
      }
    );
    return (this.userData && this.userData.group[type]) >= value ? true : false;
  }

  // ------------------ HTTP REQUESTS ---------------------

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { ActivateAccountRequest }
   * Description: Makes the post request with the user key
   */
  activateUserAccount(requestData: ActivateAccountRequest): Observable<ActivateAccountResponse> {
    const apiUrl = `${ApiUrls.AUTH_SERVICE.confirm_account}`;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: ActivateAccountResponse) => {
          return response;
        }
      ),
    );
  }

}
