import { TableFilter } from '@models/table-filter';
import { ConfirmDialog } from '@models/confirm-dialog';
import { DropdownOption } from '@models/generic-types';
import { TableConfigConstants } from './table-config-constants';
import { PrimeNgTableColumns, PrimeNgTableActions, PrimeNgTableSettings } from '@models/prime-ng-table';
import { Domains } from '@models/follow-up';

export class AppConstants {

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Stores redirect urls
     */
    public static redirectUrls = {
        emptyRoute: '/',
        login: '/auth/login',
        forgotPassword: '/auth/forgot-password',
        staffAmdinLandingPage: '/clients',
        csaForm: '/csa-form/intro',
        csaFormViewMode: '/csa-form',
        csaLandingForm: '/csa-form/personal-info',
        invoiceView: 'invoices/view'
        //initial Evaluation
        //ieForm:'/initial-evaluation/',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Page titles
     */
    public static pageTitles = {
        signIn: 'Sign In',
        forgotPassword: 'Forgot Password',
        resetPassword: 'Reset Password',
        changePassword: 'Change Password',
        client: 'Clients',
        familyInfo: 'Family Info',
        medicalInfo: 'Medical Info',
        EarlyHistory: 'Early History',
        personalInfo: 'Personal Info',
        birthHistoryInfo: 'Birth History Info',
        medicalHistoryInfo: 'Medical History Info',
        behaviouralInfo: 'Behavioral History',
        Diagnosis: 'Diagnosis',
        sendToClient: 'Send to Client',
        tpPaymentInfo: 'Developmental Activity Plan Payment Info',
        initialEvaluation: 'Initial Evaluation',
        tpChangeLog: 'Developmental Activity Plan Change Log',
        createTreatmentPlan: 'Developmental Activity Plan Detail',
        generateInvoice: 'Generate Invoice',
        activities: 'Developmental Activity',
        masterActivities: 'Master Activities',
        assignCareProvider: 'Assign Care Provider',
        developmentSummary: 'Client Development Summary',
        careproviderDetails: 'Care Provider Information',
        careproviderList: 'Care Providers',
        invoices: 'Invoices',
        invoicesDetails: 'Invoice Information',
        waiveInvoice: 'Waive Invoice',
        settings: 'Settings',
        videosessions: "Video Session",
        clientprofileUpdate: 'Update Client Profile',
        caseNotes: 'Case Notes',
        uploadDocument: 'Upload Documents',
        callierTest: 'Callier Azusa Test',
        followup: 'Follow-up'

    }

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Parent relationship options
     */
    public static parentRelationshipList: DropdownOption[] = [
        {
            value: 1,
            label: 'Step Father'
        },
        {
            value: 2,
            label: 'Step Mother'
        },
    ];

    public static durationList: DropdownOption[] = [
        {
            value: 1,
            label: 'Days'
        },
        {
            value: 2,
            label: 'Months'
        },
        {
            value: 3,
            label: 'Years'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Yes No options
     */
    public static yesNoList: DropdownOption[] = [
        {
            value: 1,
            label: 'Yes'
        },
        {
            value: 2,
            label: 'No'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Default option values
     */
    public static defaultValues = {
        dropdownDefault: { value: '', label: 'Select' },
        dropdownNullDefault: { value: null, label: 'Select' },
        noDataFound: 'No Data Found'
    }

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Pipe append values
     */
    public static valuePipes = {
        agePipe: 'Years',
        agePipeMonths: 'Months'
    };

    public static generalFormats: {
        date: 'M dd, yy',
        dateTime: 'M dd, yy h:mm:ss a'
    }

    public static fetchListConfig = {
        itemsPerPage: 5,
    }

    public static genericMessages = {
        noEvaluation: 'No initial assessment has been added',
        noTeatmentPlan: 'No developmental activity plan has been added',
        noActivities: 'No activities has been added',
        noCareProviderAssigned: 'Care provider is not yet assigned'
    };

    // --------- GENRIC CONSTANTS --------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Gender options
     */
    public static genderList: DropdownOption[] = [
        {
            value: 1,
            label: 'Male'
        },
        {
            value: 2,
            label: 'Female'
        },
        {
            value: 3,
            label: 'Prefer not to say'
        },
    ];

    public static APPLN_STATUS = {
        default: 'signed_up',
        pending: 'pending',
        inProgress: 'csa_in_progress',
        csaFormsubmitted: 'csa_submitted',
        evaluationInProgress: 'evaluation_in_progress',
        evaluationSubmitted: 'evaluation_submitted',
        diagnosisInProgress: 'diagnosis_in_progress',
        diagnosisSubmitted: 'diagnosis_submitted',
        treatmentplanInProgress: 'treatmentplan_in_progress',
        treatmentplanSubmitted: 'treatmentplan_submitted',
        discontinued: 'discontinued',
        released: 'released',
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Appln Status based styles
     */
    public static applnStatusDisplaytexts: DropdownOption[] = [
        {
            value: 'signed_up',
            label: 'Signed Up'
        },
        {
            value: 'pending',
            label: 'Pending'
        },
        {
            value: 'csa_in_progress',
            label: 'CSA in progress'
        },
        {
            value: 'csa_submitted',
            label: 'Form submitted'
        },
        {
            value: 'diagnosis_submitted',
            label: 'Diagnosis submitted'
        },
        {
            value: 'evaluation_in_progress',
            label: 'Evaluation in progress'
        },
        {
            value: 'evaluation_submitted',
            label: 'Evaluation Submitted'
        },
        {
            value: 'diagnosis_in_progress',
            label: 'Diagnosis in progress'
        },
        {
            value: 'diagnosis_submitted',
            label: 'Diagnosis submitted'
        },
        {
            value: 'tp_in_progress',
            label: 'Developmental Activity Plan in progress'
        },
        {
            value: 'tp_submitted',
            label: 'Developmental Activity plan submitted'
        },
        {
            value: 'discontinued',
            label: 'Treatment Discontinued'
        },
        {
            value: 'released',
            label: 'Released from Treatment'
        },
    ];

    public static VIEW_MODE = {
        minMode: 'minMode',
        maxMode: 'minMode',
    };

    // ---------- TABLE CONSTANTS ------------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Filter configuration for startDate
     */
    public static startDateFilter: TableFilter = {
        type: 'dateOnly',
        filterId: 'start_date',
        optionLabel: 'label',
        optionValue: 'key',
        emptyFilterMessage: 'No data found',
        showAllOption: true,
        selectedOption: '',
        defaultValue: '',
        filterTitle: 'Added Start Date',
        options: []
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Filter configuration for endDate
     */
    public static endDateFilter: TableFilter = {
        type: 'dateOnly',
        filterId: 'end_date',
        optionLabel: 'label',
        optionValue: 'key',
        emptyFilterMessage: 'No data found',
        showAllOption: true,
        selectedOption: '',
        defaultValue: '',
        filterTitle: 'Added End Date',
        options: []
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Filter configuration for CSA Status
     */
    public static applnStatusFilter: TableFilter = {
        type: 'dropdown',
        filterId: 'applnStatus',
        showAllOption: true,
        optionLabel: 'label',
        optionValue: 'value',
        selectedOption: '',
        defaultValue: '',
        filterTitle: 'Application Status',
        options: AppConstants.applnStatusDisplaytexts,
    };

    public static cancelAppointmentDialog: ConfirmDialog = {
        actionMode: '',
        title: 'Cancel Appointment',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
        message: 'Are you sure, you want to cancel the appointment?',
    };

    // ---------- LOGOUT ------------

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Confirm dialog of logout
     */
    public static logoutConfirmDialog: ConfirmDialog = {
        actionMode: '',
        title: 'Logout',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
        message: 'Unsaved changes(if any) will be lost! Are you sure, you want to logout?',
    };

    /**
    * Author:  Jeswin Chacko P A
    * Description: Diagnosis options
    */
    public static diagnosisOptions: DropdownOption[] = [
        {
            value: 1,
            label: 'Provisional'
        },
        {
            value: 2,
            label: 'Differential'
        },
        {
            value: 3,
            label: 'Final'
        },
    ];

    // ---------- FOOTER ------------

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table headers for registered users list
     */
    public static footerContent = {
        textContent: 'Home Integration Portal',
    };

    // --------- MASTER ACTIVITIES -------------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table headers for master activities
     */
    public static masterActivitiesListTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'activity_name',
            header: 'Activity Name',
        },
        {
            type: 'data',
            field: 'domainName',
            header: 'Domain',
        },
        {
            type: 'data',
            field: 'sensorName',
            header: 'Sensor',
        },
        {
            type: 'date',
            field: 'createdAt',
            header: 'Created Date',
        },
        {
            type: 'date',
            field: 'updatedAt',
            header: 'Modified Date',
        },
        {
            type: 'action',
            field: 'action',
            header: 'Actions',
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Question Table options
     */
    public static activitiesListTableActions: PrimeNgTableActions[] = [
        {
            key: 'view',
            enable: true,
            action: 'view',
            label: 'View',
            icon: 'fa-eye',
        },
        {
            key: 'edit',
            modifiyAction: true,
            enable: true,
            action: 'edit',
            label: 'Edit',
            icon: 'fa-edit',
        },
        {
            key: 'delete',
            enable: true,
            action: 'delete',
            label: 'Delete',
            modifiyAction: true,
            icon: 'fa-trash',
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table options of question lists
     */
    public static masterActivitiesTableSetings: PrimeNgTableSettings = {
        noDataText: 'No activities found',
        scrollHeight: '700px',
        searchPlaceholder: 'Search activities',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 15,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [10, 20, 30]
        }
    };

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Deactivate question modal ref
     */
    public static deactivateQuestionConfirmDialog: ConfirmDialog = {
        title: 'Deactivate Question',
        message: 'Are you sure, you want to deactivate the question?',
        actionMode: '',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
    };

    // ---------- TREATMENT PLAN CONSTANTS -----------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Activity table options
     */
    public static treatmentActivityTableActions: PrimeNgTableActions[] = [
        {
            key: TableConfigConstants.TABLE_ACTION_KEYS.viewItem,
            action: 'view',
            label: 'View',
            icon: 'fa-eye',
            enable: true,
        },
        {
            key: TableConfigConstants.TABLE_ACTION_KEYS.editItem,
            action: 'edit',
            label: 'Edit',
            icon: 'fa-edit',
            enable: true,
        },
        {
            key: TableConfigConstants.TABLE_ACTION_KEYS.deleteItem,
            action: 'delete',
            label: 'Delete',
            icon: 'fa-trash',
            enable: true,
        },
    ];

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Delete activity formatter modal ref
     */
    public static deleteActivityConfirmDialog: ConfirmDialog = {
        title: 'Delete Activity',
        message: 'Are you sure, you want to delete the activity?',
        actionMode: '',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
    };

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table headers for admin users list
     */
    public static activityTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'domain',
            header: 'Domain'
        },
        {
            type: 'data',
            field: 'sensor',
            header: 'Sensor'
        },
        {
            type: 'data',
            field: 'activity_name',
            header: 'Activity Name'
        },
        {
            type: 'action',
            field: 'action',
            header: 'Actions'
        },
    ];

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table headers for admin users list
     */
    public static treatmentActivityTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'activity_name',
            header: 'Activity Name'
        },
        {
            type: 'data',
            field: 'domainName',
            header: 'Domain'
        },
        {
            type: 'data',
            field: 'sensorName',
            header: 'Sensor'
        },
        {
            type: 'data',
            field: 'recurrence',
            header: 'Recurence'
        },
        {
            type: 'date',
            field: 'start_date',
            header: 'Start Date'
        },
        {
            type: 'date',
            field: 'end_date',
            header: 'End Date'
        },
        {
            type: 'action',
            field: 'action',
            header: 'Actions'
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table settings of activity lists
     */
    public static activityTableSettings: PrimeNgTableSettings = {
        tableId: 'activity',
        showServerSearch: false,
        rowIdentifierKey: 'id',
        expandableRows: false,
        noDataText: 'No Activities found',
        scrollHeight: '700px',
        searchPlaceholder: 'Search activities',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 20,
            totalRecords: 0,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [15, 20, 30]
        }
    };

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Button text
     */
    public static buttonTexts = {
        confirm: 'Confirm',
        create: 'Create',
        submit: 'Submit',
        cancel: 'Cancel',
        close: 'Close',
        update: 'Update',
        send: 'Send',
        waive: 'Waive',
    };

    public static recurrenceTypes: DropdownOption[] = [
        {
            value: 'daily',
            label: 'Daily'
        },
        {
            value: 'weekly',
            label: 'Weekly'
        },
    ];

    public static weekDaysList: DropdownOption[] = [
        {
            label: 'MON',
            value: 0
        },
        {
            label: 'TUES',
            value: 1
        },
        {
            label: 'WED',
            value: 2
        },
        {
            label: 'THU',
            value: 3
        },
        {
            label: 'FRI',
            value: 4
        },
        {
            label: 'SAT',
            value: 5
        },
        {
            label: 'SUN',
            value: 6
        },
    ];

    public static activityStatus = {
        completed: 'completed',
        pending: 'pending',
    };

    // --------------------- TP CHANGE LOG --------------------------

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Table headers for change log list
     */
    public static tpChangeLogTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'tpName',
            header: 'Treatment Plan'
        },
        {
            type: 'data',
            field: 'tpId',
            header: 'Treatment plan ID'
        },
        {
            type: 'dateTime',
            field: 'done_on',
            header: 'Done At'
        },
        {
            type: 'data',
            field: 'done_by_user',
            header: 'Done By'
        },
        {
            type: 'data',
            field: 'change_descr',
            header: 'Change Description',
            width: '400px',
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table settings of logs lists
     */
    public static TPChangelogTableSettings: PrimeNgTableSettings = {
        tableId: 'tp-log',
        showServerSearch: false,
        rowIdentifierKey: 'id',
        expandableRows: false,
        noDataText: 'No Logs found',
        scrollHeight: '700px',
        searchPlaceholder: 'Search logs',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 20,
            totalRecords: 0,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [15, 20, 30]
        }
    };

    /**
 * Author:IBIL GLOBAL
 * Description: Child Activity improvement codes
 */
  public static currentStateOptions: DropdownOption[] = [
      {
          value: 3,
          label: 'Improved'
      },
      {
          value: 2,
          label: 'Stay the Same'
      },
      {
          value: 1,
          label: 'Regressed'
      },
  ];

    // ---------------- TP SUBMISSION ------------------------

    public static paymentOptions: DropdownOption[] = [
        {
            value: 'one_time',
            label: 'One Time'
        },
        {
            value: 'monthly',
            label: 'Monthly'
        },
        {
            value: 'annually',
            label: 'Annual'
        },
    ];

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Confirm dialog of send to cleint
     */
    public static sendToClientConfirmDialog: ConfirmDialog = {
        actionMode: '',
        title: 'Send developmental activity plan to client',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
        message: 'Are you sure, you want to send the treatment plan to client?',
    };

    // ----------------------- PROFILE PAGE - CARE PROVIDER INFO ----------------------------

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table headers for CARE PROVIDERS LIST
     */
    public static careProvidersListTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'fullName',
            header: 'Name',
        },
        {
            type: 'data',
            field: 'designation',
            header: 'Domain',
        },
        {
            type: 'date',
            field: 'assigned_date',
            header: 'Assigned Date',
        },
        {
        type: 'action',
        field: 'action',
        header: 'Actions',
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Question Table options
     */
    public static cpListListTableActions: PrimeNgTableActions[] = [
        {
            key: 'remove',
            enable: true,
            action: 'delete',
            label: 'Remove',
            modifiyAction: true,
            icon: 'fa-times-circle-o',
        },
    ];

    /**
     * Author: T0512 Krupa Tresa Joseph
     * Description: Table options of careproviders lists
     */
    public static careprovidersListTableSetings: PrimeNgTableSettings = {
        noDataText: 'No care providers assigned',
        scrollHeight: '700px',
        searchPlaceholder: 'Search assigned careproviders',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 15,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [10, 20, 30]
        }
    };

    /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Deactivate question modal ref
     */
    public static removeCareProviderConfirmDialog: ConfirmDialog = {
        title: 'Remove Care Provider',
        message: 'Are you sure, you want to reomve the selected care provider?',
        actionMode: '',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
    };

    // --------- CARE PROVIDERS Maintenance Related --Start -------------


    public static careproviderListTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'data',
            field: 'fullname',
            header: 'Name',
        },
        {
            type: 'data',
            field: 'email',
            header: 'Email',
        },
        {
            type: 'data',
            field: 'contact',
            header: 'Phone',
        },
        {
            type: 'data',
            field: 'speciality',
            header: 'Speciality',
        },
        {
            type: 'data',
            field: 'designation',
            header: 'Designation',
        },
        {
            type: 'data',
            field: 'role',
            header: 'User Role',
        },
        {
            type: 'date',
            field: 'createdAt',
            header: 'Created Date',
        },
        {
            type: 'date',
            field: 'updatedAt',
            header: 'Modified Date',
        },
        {
            type: 'data',
            field: 'status',
            header: 'Status',
        },
        {
            type: 'action',
            field: 'action',
            header: 'Actions',
        },
    ];


    public static careproviderListTableActions: PrimeNgTableActions[] = [
        {
            key: 'view',
            enable: true,
            action: 'view',
            label: 'View',
            icon: 'fa-eye',
        },
        {
            key: 'edit',
            enable: true,
            action: 'edit',
            label: 'Edit',
            icon: 'fa-edit',
        },
        {
            key: 'delete',
            enable: true,
            action: 'delete',
            label: 'Delete',
            icon: 'fa-trash',
        },
    ];

    /** START
     * Author: IBIL Solutions
     * Description: Table/Maintenance  options of Care provider.
     */
    public static careproviderListTableSetings: PrimeNgTableSettings = {
        noDataText: 'No CareProviders found',
        scrollHeight: '700px',
        searchPlaceholder: 'Search',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 15,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [10, 20, 30]
        }
    };

    public static prefixList: DropdownOption[] = [
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mrs.', value: 'Mrs.' }
    ];

    public static suffixList: DropdownOption[] = [
        { label: 'Jr.', value: 'Jr.' },
        { label: 'Sr.', value: 'Sr.' },
        { label: 'M.D.', value: 'M.D.' },
        { label: 'O.D.', value: 'O.D.' },
        { label: 'Ph.D.', value: 'Ph.D.' },
        { label: 'R.N.', value: 'R.N.' },
        { label: 'R.N.C.', value: 'R.N.C.' }
    ];

    public static userrolesList: DropdownOption[] = [
        { label: 'Care Provider', value: 'care-provider' },
        { label: 'Expert', value: 'expert' },
        { label: 'Staff-Admin', value: 'staff-admin' },

    ];

    public static cpStatuses: DropdownOption[] = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },

    ];

    public static deleteCPConfirmDialog: ConfirmDialog = {
        title: 'Delete Care provider?',
        message: 'Are you sure, you want to delete the Care provider?',
        actionMode: '',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Confirm',
    };

    // -------------- CREATE APPOINTMENT ---------------

    public static appointmentTypes: DropdownOption[] = [
        {
            value: 'video',
            label: 'Video',
        },
        {
            value: 'text',
            label: 'Chat',
        },
    ];

    // -------------- SETTINGS ------------------

    public static timeZoneList = {
        orgTimeZone: 'ORG_TIMEZONE',
        deviceTimeZone: 'DEVICE_TIMEZONE'
    };

    // --------- CARE PROVIDERS Maintenance Related --End-------------


    // --------- VIDEO SESSIONS Maintenance Related --Start -------------

    public static archivesTableHeaders: PrimeNgTableColumns[] = [
        {
            type: 'slNo',
            field: 'slNo',
            header: 'SL:NO',
        },
        {
            type: 'date',
            field: 'scheduled_on',
            header: 'Schedule Date',
        },
        {
            type: 'data',
            field: 'appointment_purpose',
            header: 'Purpose',
        },
        {
            type: 'data',
            field: 'participants',
            header: 'Participants',
        },
        {
            type: 'data',
            field: 'organizers',
            header: 'Organizers',
        },
        {
            type: 'action',
            field: 'action',
            header: 'Actions',
        },
    ];


    public static archivesTableActions: PrimeNgTableActions[] = [
        {
            key: 'view',
            enable: true,
            action: 'view',
            label: 'View',
            icon: 'fa-video-camera',
        },
    ];


    public static archivesTableSetings: PrimeNgTableSettings = {
        noDataText: 'No Video Records found',
        scrollHeight: '700px',
        searchPlaceholder: 'Search',
        scrollable: true,
        pagination: {
            enable: true,
            itemsPerPage: 10,
            dropdownAppendTo: 'body',
            rowsPerPageOptions: [10, 20, 30]
        }
    };

    public static activityLogsTableHeaders: PrimeNgTableColumns[] = [
      {
          type: 'slNo',
          field: 'slNo',
          header: 'SL:NO',
      },
      {
          type: 'data',
          field: 'action',
          header: 'Action',
      },
      {
          type: 'dateTime',
          field: 'createdAt',
          header: 'Done At',
      },
      {
          type: 'data',
          field: 'updatedBy',
          header: 'Done By',
      },
      {
          type: 'data',
          field: 'message',
          header: 'Message',
          width: '600px',
      },
    ];

    public static activityLogsTableActions: PrimeNgTableActions[] = [
      {
          key: 'view',
          enable: true,
          action: 'view',
          label: 'View',
          icon: 'fa-video-camera',
      },
    ];

    public static activityLogsTableSetings: PrimeNgTableSettings = {
      noDataText: 'No Activity Logs found',
      scrollHeight: '700px',
      searchPlaceholder: 'Search Log',
      scrollable: true,
      pagination: {
          enable: true,
          itemsPerPage: 10,
          dropdownAppendTo: 'body',
          rowsPerPageOptions: [10, 20, 30]
      }
    };

    public static documentsTableHeaders: PrimeNgTableColumns[] = [
      {
          type: 'slNo',
          field: 'slNo',
          header: 'SL:NO',
      },
      {
          type: 'data',
          field: 'title',
          header: 'Document',
      },
      {
          type: 'dateTime',
          field: 'uploaded_on',
          header: 'Uploaded At',
      },
      {
          type: 'data',
          field: 'uploadedBy',
          header: 'Uploaded By',
      },
      {
          type: 'action',
          field: 'action',
          header: 'Actions',
      },
    ];

    public static documentsTableActions: PrimeNgTableActions[] = [
      {
          key: 'view',
          enable: true,
          action: 'view',
          label: 'View',
          icon: 'fa-eye',
      },
    ];

    public static documentsTableSetings: PrimeNgTableSettings = {
      noDataText: 'No Documents found',
      scrollHeight: '700px',
      searchPlaceholder: 'Search',
      scrollable: true,
      pagination: {
          enable: true,
          itemsPerPage: 5,
          dropdownAppendTo: 'body',
          rowsPerPageOptions: [10, 20, 30]
      }
    };

    public static encryptdecryptSecretkey = {
        secret_key: 'eGu@rdianPrivateK@y',
    };

    public static suspendApplnStatusDisplaytexts: DropdownOption[] = [
        {
            value: 'discontinued',
            label: 'Discontinued'
        },
        {
            value: 'released',
            label: 'Released'
        }
    ];


    //-----------INVOICES--------------------

  public static invoicesTableHeaders: PrimeNgTableColumns[] = [
      {
          type: 'slNo',
          field: 'slNo',
          header: 'SL:NO',
      },
      {
          type: 'data',
          field: 'invoice_id',
          header: 'Invoice Number',
      },
      {
          type: 'data',
          field: 'patientId',
          header: 'Client ID',
      },
      {
          type: 'data',
          field: 'fullname',
          header: 'Name',
      },
      {
          type: 'data',
          field: 'email',
          header: 'Email',
          width: '200px',
      },
      {
          type: 'data',
          field: 'contact',
          header: 'Phone',
      },
      {
          type: 'date',
          field: 'invoice_generate_date',
          header: 'Invoice Date',
      },
      {
          type: 'data',
          field: 'amount',
          header: 'Amount',
      },
      {
          type: 'data',
          field: 'reason',
          header: 'Invoice For',
      },
      {
          type: 'data',
          field: 'status',
          header: 'Invoice Status',
      },
      {
          type: 'data',
          field: 'dhs',
          header: 'DHS',
      },
      {
          type: 'action',
          field: 'action',
          header: 'Actions',
      },
  ];

  public static invoicesTableActions: PrimeNgTableActions[] = [
      {
          key: 'view',
          enable: true,
          action: 'view',
          label: 'View',
          icon: 'fa-eye',
      },
      {
          key: 'edit',
          modifiyAction: true,
          enable: true,
          action: 'edit',
          label: 'Edit',
          icon: 'fa-edit',
      },
      // {
      //     key: 'delete',
      //     enable: true,
      //     action: 'delete',
      //     label: 'Delete',
      //     modifiyAction: true,
      //     icon: 'fa-trash',
      // },
  ];

  public static invoicesTableSetings: PrimeNgTableSettings = {
      noDataText: 'No invoices found',
      scrollHeight: '700px',
      searchPlaceholder: 'Search invoices',
      scrollable: true,
      noSearch: true,
      pagination: {
          enable: true,
          itemsPerPage: 10,
          dropdownAppendTo: 'body',
          rowsPerPageOptions: [10, 20, 30]
      }
  };


  public static invoiceStatusDisplaytexts: DropdownOption[] = [
    {
        value: 'pending',
        label: 'Pending'
    },
    {
        value: 'completed',
        label: 'Completed'
    },
    {
        value: 'waived',
        label: 'Waived'
    },
  ];

  public static invoiceStatusFilter: TableFilter = {
      type: 'dropdown',
      filterId: 'status',
      showAllOption: true,
      optionLabel: 'label',
      optionValue: 'value',
      selectedOption: '',
      defaultValue: '',
      filterTitle: 'Invoice Status',
      options: AppConstants.invoiceStatusDisplaytexts,
  };

  public static Localisation = {
    currency: '$',
  };

  public static dataTableSetings: PrimeNgTableSettings = {
      noDataText: 'No data found',
      scrollHeight: '700px',
      searchPlaceholder: 'Search data',
      scrollable: true,
      pagination: {
          enable: true,
          itemsPerPage: 10,
          dropdownAppendTo: 'body',
          rowsPerPageOptions: [10, 20, 30]
      }
  };

  public static reportTableHeaders: PrimeNgTableColumns[] = [
    {
        type: 'slNo',
        field: 'slNo',
        header: 'SL:NO',
    },
    {
        type: 'data',
        field: 'patientID',
        header: 'ID',
    },
    {
        type: 'data',
        field: 'full_name',
        header: 'Name',
    },
    {
        type: 'data',
        field: 'email',
        header: 'E Mail',
    },
    {
        type: 'data',
        field: 'contact',
        header: 'Phone',
    },
    {
        type: 'data',
        field: 'age',
        header: 'Age',
    },
    {
        type: 'data',
        field: 'joiningDate',
        header: 'Joining Date',
    },
    {
        type: 'data',
        field: 'applnStatus',
        header: 'Application Status',
    },
    {
        type: 'data',
        field: 'treatment_status',
        header: 'Treatment Status',
    },
  ];

  public static treatmentStatusOptions: DropdownOption[] = [
      {
          value: 3,
          label: 'Improved'
      },
      {
          value: 2,
          label: 'Stay the Same'
      },
      {
          value: 1,
          label: 'Regressed'
      },
      {
          value: 0,
          label: 'Status Not Updated'
      },
  ];

  public static callierTableHeaders: PrimeNgTableColumns[] = [
    {
      type: 'data',
      field: 'area',
      header: 'Area',
    },
    {
      type: 'data',
      field: 'subScale',
      header: 'Sub-Scale',
    },
    {
      type: 'data',
      field: 'months',
      header: 'Months',
    },
    {
      type: 'data',
      field: 'status',
      header: 'Status',
    },
    {
      type: 'data',
      field: 'itemCollection',
      header: 'Item Collection',
    },
    {
      type: 'data',
      field: 'addItems',
      header: 'Add Items',
    },
  ];

  public static callierViewTableHeaders: PrimeNgTableColumns[] = [
    {
      type: 'data',
      field: 'area',
      header: 'Area',
    },
    {
      type: 'data',
      field: 'subScale',
      header: 'Sub-Scale',
    },
    {
      type: 'data',
      field: 'months',
      header: 'Months',
    },
    {
      type: 'data',
      field: 'status',
      header: 'Status',
    },
    {
      type: 'data',
      field: 'itemCollection',
      header: 'Item Collection',
    },
    {
      type: 'data',
      field: 'remarks',
      header: 'Remarks',
    },
  ];

  public static callierTestsTableHeaders: PrimeNgTableColumns[] = [
    {
        type: 'slNo',
        field: 'slNo',
        header: 'SL:NO',
    },
    {
        type: 'dateTime',
        field: 'test_date',
        header: 'Test Date',
    },
    {
        type: 'data',
        field: 'meeting_type',
        header: 'Meeting Type',
    },
    {
        type: 'data',
        field: 'comments',
        header: 'Comments',
    },
    {
        type: 'action',
        field: 'action',
        header: 'Actions',
    },
  ];

  public static callierTestsTableActions: PrimeNgTableActions[] = [
    {
        key: 'view',
        enable: true,
        action: 'view',
        label: 'View',
        icon: 'fa-eye',
    },
  ];

  public static callierTestsTableSettings: PrimeNgTableSettings = {
    noDataText: 'No Test Result found',
    scrollHeight: '700px',
    searchPlaceholder: 'Search',
    scrollable: true,
    pagination: {
        enable: true,
        itemsPerPage: 5,
        dropdownAppendTo: 'body',
        rowsPerPageOptions: [10, 20, 30]
    }
  };

  public static followupDomains: Domains[] = [
    {
      domainName: 'Second Attempt',
      id: 2,
    },
    {
      domainName: 'Third Attempt',
      id: 3,
    },
  ];

  /**
  * Author:  Jeswin Chacko P A
  * Description: Followup options
  */
  public static followupOptions: DropdownOption[] = [
      {
          value: 'Phone',
          label: 'Phone'
      },
      {
          value: 'Email',
          label: 'Email'
      },
      {
          value: 'SMS',
          label: 'SMS'
      },
  ];

}


