import { Pipe, PipeTransform } from '@angular/core';

import { DropdownOption } from '@models/generic-types';

import { AppConstants } from '@utilities/app-constants';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  genderList: DropdownOption[];

  constructor() {
    this.genderList = AppConstants.genderList;
  }

  transform(value: any): string {
    let returnVal: string = ''
    let selectedObj: DropdownOption = this.genderList.find((item: DropdownOption) => item.value == value);
    if (selectedObj) {
      returnVal = selectedObj.label; 
    }
    return returnVal;
  }

}
