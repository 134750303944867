import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from '@layout/layout.module';

import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxTinymceModule } from 'ngx-tinymce';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TreatmentActivitiesModule } from './modules/treatment-activities/treatment-activities.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule.forRoot(),
    CoreModule,
    LayoutModule,
    NgxTinymceModule.forRoot({baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/'}),
    TreatmentActivitiesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
