<div class="file-input-wrap input-file-container">
    <input 
        #fileUpload
        type="file"
        enctype="multipart/form-data" 
        [readonly]="disableField"
        [ngClass]="{'disabled': disableField}"
        (change)="onFileUploadClick()"
        class="input-file">
        <label 
            for="my-file" 
            tabindex="0" 
            *ngIf="!disableField"
            class="input-file-trigger"
            (click)="fileUpload.click()">Upload
        </label>
</div>
<div class="name-info">
    <span class="file-name"
    *ngIf="fileName && !multipleMode">
    File: <b>{{fileName}}</b> 
    <i class="fa fa-download colored ml-2 mr-2"
        title="Download File"
        (click)="onDownloadSelectedFile()"></i>
    <i class="fa fa-trash delete-mode"
        title="Remove File"
        (click)="onRemoveSelectedFile()"></i>
    </span>
</div>
<!-- Multiple File Upload -->
<div class="con-wrap"
    *ngIf="multipleMode && fileList?.length > 0">
    <div class="row">
        <div class="col-8"><span class="header mb-2"><b>File</b></span></div>
        <div class="col-4"><span class="header mb-2"><b>Action</b></span></div>
    </div>
    <div class="row" 
        *ngFor="let item of fileList">
        <div class="col-8"><span class="header">{{item?.fileName}}</span></div>
        <div class="col-4">
            <i class="fa fa-download colored ml-2 mr-2"
                title="Download File"
                (click)="onDownloadFileFromList(item)"></i>
            <i class="fa fa-trash delete-mode"
                title="Remove File"
                (click)="onRemoveFileFromList(item)"></i>
        </div>
    </div>
</div>
