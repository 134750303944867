<!-- TABLE VIEW -->
<p-table 
    #dataTable
    *ngIf="tableItems"
    responsive="true" 
    immutable="false"
    autoLayout="true"
    resetPageOnSort=true
    rowExpandMode="single"
    resizableColumns="true" 
    columnResizeMode="expand" 
    [lazy]="true" 
    [value]="tableItems"
    [columns]="tableColumns" 
    [scrollable]="tableSettings?.scrollable" 
    [dataKey]="tableSettings?.rowIdentifierKey"
    [scrollHeight]="tableSettings?.scrollHeight"
    [paginator]="tableSettings?.pagination?.enable"
    [rows]="tableSettings?.pagination.itemsPerPage"
    [totalRecords]="tableSettings?.pagination?.totalRecords"
    (onLazyLoad)="onPageChange($event)">
    <!-- Table Header -->
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th 
                *ngFor="let col of tableColumns"
                [class.name]="col?.sub_type == dataTypes?.name"
                [ngClass]="col?.type"
                [ngStyle]="{'width':col?.width}"
                [class.id]="col?.sub_type == dataTypes?.id">
                {{col?.header}}
            </th>
        </tr>
    </ng-template>
    <!-- Main Table Body -->
    <ng-template pTemplate="body" 
        let-item 
        let-rowData
        let-i="rowIndex"
        let-columns="columns"
        let-expanded="expanded">
        <tr>
            <td 
                class="table-data" 
                [ngClass]="col.type"
                *ngFor="let col of tableColumns"
                (click)="onViewDetailPage(item, col.detailOnClick)"
                [class.link]="col?.detailOnClick == true"
                [ngStyle]="{'width':col?.width}"
                [class.id]="col?.sub_type == dataTypes?.id"
                [class.name]="col?.sub_type == dataTypes?.name">
                <!-- TABLE DATA COLS -->
                    <!-- SL.NO Field -->
                    <span class="table-val"
                        *ngIf="col.type === dataTypes?.slNo">
                        {{i + 1}}
                    </span>
                    <!-- Normal datas are displayed without any formatting -->
                    <span class="table-val"
                        [title]="item[col?.field]"
                        *ngIf="col.type === dataTypes.data">
                        {{item[col?.field]}}
                    </span>
                    <!-- Age -->
                    <span class="table-val"
                    [title]="item[col?.field]"
                    *ngIf="col.type === dataTypes?.age">
                    {{item[col?.field]}}
                    </span>
                    <!-- Image Name Field -->
                    <span class="table-val"
                        *ngIf="col.type === dataTypes?.imageName">
                        <img class="img-profile rounded-circle small" 
                        [src]="item?.profile_pic ? item?.profile_pic : dummyImgUrl"
                        (error)="handleBrokenImage(item)">
                        {{item?.full_name}}
                    </span>
                    <!-- Marker & Name Field -->
                    <span class="table-val"
                        *ngIf="col.type === dataTypes?.markedTitle">
                    <div 
                        class="action-btn"
                        [ngClass]="{'hide-icon': !item[col?.markerKey]}">
                        <i 
                            *ngIf="item[col?.markerKey]"
                            class="fa" 
                            [ngClass]="col?.markerIcon" aria-hidden="true">
                        </i>
                    </div>
                        {{item[col?.field]}}
                    </span>
                    <!-- Array Items Field -->
                    <span class="table-val"
                        *ngIf="col.type === dataTypes.arrayItems">
                            <div *ngFor="let loopedData of item[col?.childDataKey]">
                                <span class="child-data" 
                                [ngClass]="{
                                    defaultActive: col?.active,
                                    active: loopedData?.is_active, 
                                    inactive: !loopedData?.is_active}">
                                    {{loopedData[col?.childDataValRef]}}
                                </span>
                            </div>
                    </span>
                    <!-- Date columns -->
                    <span class="table-val"
                        [title]="item[col?.field]"
                        *ngIf="col.type === dataTypes.date">
                        {{item[col?.field] | date: genericFormats?.dateFormat}}
                    </span>
                    <!-- Date Time Columns -->
                    <span class="table-val"
                        [title]="item[col?.field] | date: genericFormats?.dateTimeFormat"
                        *ngIf="col.type === dataTypes.dateTime">
                        {{item[col?.field] | date: genericFormats?.dateTimeFormat}}
                    </span>
                    <!-- TABLE ACTION COL -->
                    <div class="action-con"
                        *ngIf="col.type === dataTypes?.action">
                        <div class="action-wrap" 
                            [title]="actionInfo?.label"
                            (click)="onActionClick(actionInfo, item)"
                            *ngFor="let actionInfo of tableActions"
                            [ngClass]="{'empty-action': !item?.action[actionInfo?.action]}">
                            <!-- ICONS LOOPING -->
                            <div 
                                class="action-btn" 
                                *ngIf="item?.action[actionInfo?.action] == true && actionInfo?.enable">
                                    <i 
                                    class="fa action-icon" 
                                    [ngClass]="actionInfo?.icon" aria-hidden="true"></i>
                            </div>
                            <!-- INPUTS LOOPING -->
                            <div 
                            class="action-btn" 
                            *ngIf="item?.action[actionInfo?.action] == true && actionInfo?.enable && actionInfo?.type == actionTypes?.input">
                                <p-checkbox
                                    binary="true"
                                    [(ngModel)]="item[actionInfo?.dataRef]">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                    <!-- TABLE INPUT COL -->
                    <div class="action-con"
                        *ngIf="col.type === dataTypes?.input">
                    <div class="action-wrap" 
                        [title]="actionInfo?.label"
                        (click)="onActionClick(actionInfo, item)"
                        *ngFor="let actionInfo of tableInputActions"
                        [ngClass]="{'empty-action': !item?.action[actionInfo?.action]}">
                        <!-- INPUTS LOOPING -->
                        <div 
                        class="action-btn" 
                        *ngIf="
                        item?.inputAction[actionInfo?.action] == true && actionInfo?.enable && actionInfo?.action == 'checkbox'">
                            <p-checkbox
                                binary="true"
                                [(ngModel)]="item[actionInfo?.dataRef]">
                            </p-checkbox>
                        </div>
                        <!-- SWITCH FIELD -->
                        <div 
                        class="action-btn" 
                        *ngIf="
                        item?.inputAction[actionInfo?.action] == true && actionInfo?.action == 'switch'">
                        <p-inputSwitch 
                            [disabled]="!actionInfo?.enable"
                            [(ngModel)]="item[actionInfo?.dataRef]">
                        </p-inputSwitch>
                        </div>
                    </div>
                    </div>
            </td>
        </tr>
    </ng-template>
    <!-- No Data Container -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="no-data-text">{{tableSettings?.noDataText}}</td>
        </tr>
    </ng-template>
</p-table>

