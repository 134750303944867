<!--  PAGE TITLE AND BUTTONS CON -->
<div class="page-info">
    <h3 class="title page-title">{{pageTite}}</h3>
    <button 
    *ngIf="enableAddProcess"
    class="btn sec-btn"
    [title]="crudOperationsList?.create?.title"
    (click)="onCreateActivity()">{{crudOperationsList?.create?.title}}</button>
</div>

<!-- ACTIVITIES LISTING TABLE -->
<app-normal-data-table
#reusableTable
[tableTitle]="tableTitle"
[tableItems]="activitiesList"
[tableActions]="tableActions"
[tableColumns]="tableHeaders"
[tableSettings]="tableSettings"
(editItem)="onEditSelectedActivity($event)"
(viewItem)="onViewSelectedActivity($event)"
(deleteItem)="onDeleteSelectedActivity($event)">
</app-normal-data-table>