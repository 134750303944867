import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddPatientResponse } from '@models/add-patient';
import { AddClientRequest } from '@models/appointments';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddClientService {

  constructor(private http: HttpClient) { }

  postAddClient(requestData: AddClientRequest): Observable<AddPatientResponse> {
    const apiUrl = ApiUrls.ADD_CLIENTS.addClient;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: AddPatientResponse) => {
          return response;
        }
      ),
    );
  }
}
