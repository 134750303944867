import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { Participant, SessionData, RecordResponse } from '@models/appointments';
import { LoggedUserInfo } from '@models/logged-user';
import { AuthService } from '@services/auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import * as OT from '@opentok/client';
import * as moment from 'moment-timezone';
import * as $ from 'jquery';
import { TimezoneService } from '@services/timezone/timezone.service';
import { CreateScheduleService } from '@modules/appointments/create-schedule/create-schedule.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { AlertMsg } from '@models/alert-msgs';
import { AppointmentsService } from '@modules/appointments/appointments.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-schedule-widget',
  templateUrl: './schedule-widget.component.html',
  styleUrls: ['./schedule-widget.component.scss']
})
export class ScheduleWidgetComponent implements OnInit {

  @Output() callback: EventEmitter<any> = new EventEmitter();
  @Input() sessionData: SessionData;

  // Tokbox Data
  apiKey: string
  sessionId: string;
  token: string;
  sessionType: string;

  //flags
  showAddParticipantScreen: boolean;
  showMessagingScreen: boolean;
  minimizeVideoCallLayout: boolean;

  userInfoSubscription$ = new Subscription();
  userData: LoggedUserInfo;
  isOrganiser: boolean;
  isRecording: boolean;

  browserPrivileges: { audio: boolean; video: boolean; };

  stream: any;
  session: any;
  publisher: any;
  publisherConnection: any;

  publisherVideo: boolean;
  publisherAudio: boolean;

  t: any;
  duration: string;
  hours: number;
  minutes: number;
  seconds: number;

  participantList: any[];
  participants: number;
  subscribers: any;

  unreadMessages: number;
  messages: any[];
  textMessage: string;

  participantsList: Participant[];

  archiveId: string;

  addedParticipants: string[];
  addParticipantForm: FormGroup;

  dataToSend: {
    participantsDetails: [{
      participant: string
    }]
  }
  handler: any;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private timezoneService: TimezoneService,
    private alertMsgService: AlertMsgService,
    private alertMessageService: AlertMsgService,
    private createScheduleService: CreateScheduleService,
    private appointmentsService: AppointmentsService
  ) {
    this.participantsList = [];

    this.showAddParticipantScreen = false;
    this.showMessagingScreen = false;
    this.minimizeVideoCallLayout = false;

    this.publisherAudio = true;
    this.publisherVideo = true;

    this.browserPrivileges = { audio: false, video: false };
    this.isRecording = false;
    this.archiveId = "";
    this.addedParticipants = [];


  }

  ngOnInit(): void {

    // Tokbox Data
    this.apiKey = this.sessionData.apiKey; // session API Key
    this.sessionId = this.sessionData.sessionID;
    this.token = this.sessionData.clientToken;
    this.sessionType = this.sessionData.sessionType;
    this.isOrganiser = this.sessionData.sessionData.have_start_privileage;


    this.userInfoSubscription$ = this.authService.currentUser.subscribe(
      (userData: LoggedUserInfo) => {
        this.userData = userData;
      }
    );

    this.addParticipantForm = this.fb.group(
      {participants: [this.participantsList, Validators.required]}
    )

    this.dataToSend = {
      participantsDetails: [{
        participant: ""
      }]
    };

    // Check system requirements
    if (!OT.checkSystemRequirements) {
      alert('Your browser currently does not support this feature. Please upgrade.');
      this.callback.emit('no_browser_support');
    } else {

      if (this.sessionType !== 'text') {
        this.browserPrivileges = { audio: true, video: true };
        if (navigator.mediaDevices === undefined || navigator.mediaDevices.getUserMedia === undefined) {
          this.callback.emit('no_browser_support');
          return;
        } else {
          // Access camera and microphone
          navigator.mediaDevices.getUserMedia(this.browserPrivileges)
            .then(stream => {
              this.stream = stream;
              this.initializeSession(this.apiKey, this.sessionId);
            })
            .catch((err) => {
              alert('You should allow to use your camera and microphone.');
              this.callback.emit('no_user_permission');
              return;
            });
        }
      }
      else {
        this.initializeSession(this.apiKey, this.sessionId);
      }
    }

  }

  /**
   * Initialise session
   * @param apiKey
   * @param sessionId
   */

  initializeSession = (apiKey, sessionId) => {
    const that = this;
    that.session = '';
    that.session = OT.initSession(apiKey, sessionId);
    // Subscribe to a newly created stream
    // console.log('SESSION INTIALSED WITH THIS API KEY & SESSION ID!', apiKey, sessionId )

    if (that.sessionType !== 'text') {

      // Create a publisher
      this.publisher = OT.initPublisher('publisher', {
        insertMode: 'append',
        name: that.userData.fname + ' ' + that.userData.mname + ' ' + that.userData.lname,
        // avatar: that.userData.avatar,
        // userId: that.userData._id,
        showControls: false
      }, this.handleError);

      // console.log('PUBLISHER VALUE IS SET', this.publisher )

      that.makeFullScreen($('.OT_publisher'), 'publisher');

      $('.OT_publisher').css('width', '100%');
      $('.OT_publisher').css('height', '100%');

      $('.OT_publisher').bind('dblclick', function (e) {
        this.handler = $(this);
        that.makeFullScreen(this.handler, 'publisher');
      });
    } else {
      setTimeout(function () {
        $('.OT_publisher').hide();
        $('.OT_subscriber').hide();
      }, 100);
    }

    // console.log('CONECT TO SESSION INVOKED', this.session );
    // Connect to the session
    that.session.connect(this.token, function (error) {
      that.subscribers = [];
      that.participantList = [];
      that.messages = [];
      that.participants = 0;
      $('#subscribers').html('');
      // If the connection is successful, publish to the session
      if (error) {
        that.handleError(error);
      } else {
        that.initTimer();
        that.session.publish(that.publisher, that.handleError);
      }
    });

    that.session.on('sessionConnected', function (event) {
      that.publisherConnection = event.target.connection;
    });

    that.session.on('streamDestroyed', function(event){
      that.participants = that.participants ? that.participants-- : 0;
      const userData = event.stream ? JSON.parse(event.stream.connection.data) : {};
      if (userData._id) {
        that.participantList =  that.participantList.filter(p => {
          return p.userId && p.userId !== userData._id;
        });
      }
    });

    if (that.sessionType !== 'text') {
      that.session.on('streamCreated', function (event) {
        if (that.session) {
          that.session.subscribe(event.stream, 'subscribers', {
            insertMode: 'append',
            showControls: false,
            style: { nameDisplayMode: 'false' }
          }, that.handleError);
          const subscribers = that.session.getSubscribersForStream(event.stream);
          subscribers.forEach(sub => {
            if (sub.stream) {
              that.subscribers.push(sub);
            }
          });
          that.participants = that.subscribers.length;
          for (let i = 0; i < that.subscribers.length; i++) {
           if (that.subscribers[i].stream) {
            const stream = that.subscribers[i].stream;
            stream.audio = true;
            stream.video = true;

            const connectionData = JSON.parse(that.subscribers[i].stream.connection.data);
            that.participantList.push({
              avatar: connectionData.avatar,
              name: `${connectionData.prefix} ${connectionData.fname}
              ${connectionData.mname ? connectionData.mname : ''} ${connectionData.lname}
              ${connectionData.suffix ? connectionData.suffix : '' }`,
              userId: connectionData._id
            });

            const audioIcon = stream.audio ? 'assets/images/speaker.svg' : 'assets/images/speaker_off.svg';
            const videoIcon = stream.video ? 'assets/images/video_active.svg' : 'assets/images/video.svg';

            const template = `
            <div class='VSC-BS-PS-Parcicipants-name'>
              ${connectionData.fname} ${connectionData.mname} ${connectionData.lname}
              <div data-id="${i}" class='publisher-video VSC-BS-PS-Parcicipants-Options VSC-BS-PS-PO-video' >
                <img src="${videoIcon}">
              </div>
              <div data-id="${i}" class="publisher-audio VSC-BS-PS-Parcicipants-Options VSC-BS-PS-PO-audio" >
                <img src="${audioIcon}" >
              </div>
            </div>
            <div class="VSC-BS-PS-Parcicipants-color" style="background:${connectionData.color}"></div>
            `;

            if (that.sessionType !== 'text') {
              const id = that.subscribers[i].id;
              const control = $('#' + id);
              control.append( template );

              control.bind('dblclick', function(e){
                if (!e['handled']) {
                  this.handler = $(this);
                  that.makeFullScreen(this.handler, 'subscriber');
                }
              });
            }
          }
          }

          that.participantList = that.participantList.filter((thing, index, self) =>
            index === self.findIndex((t) => (
              t.userId === thing.userId
            ))
          );

          $('.OT_subscriber').css('width', '100%');
          $('#subscribers').find('.OT_subscriber').css('height', '25vh');
          $('.OT_subscriber').css('margin-bottom', '10px');

          $(document).on('click', '.publisher-audio', function (e) {
            if (!e['handled']) {
              that.toggleSubscriberAudio($(this));
              e['handled'] = true;
            }
          });

          $(document).on('click', '.publisher-video', function (e) {
            if (!e['handled']) {
              // console.log("This is ", $(this))
              that.toggleSubscriberVideo($(this));
              e['handled'] = true;
            }
          });
        }
      });
    }


    // Listener for receiving messages.
    that.session.on('signal', function(event) {
      that.unreadMessages++;
      const data = JSON.parse(event.from.data);
      const messageObj = {
        message: event.data,
        name: `${data.prefix} ${data.fname} ${data.mname ? data.mname : ''} ${data.lname} ${data.suffix ? data.suffix : '' }`,
        color: data.color,
        id: event.from.connectionId,
        time:  moment.tz(that.timezoneService.getUserTimeZone()).format('hh:mm A')
      };
      that.messages.push(messageObj);
      // const toDiv = that.sessionType === 'text' ? 'main-message-div' : 'message-input-div';
      // that.triggerScrollTo(toDiv);
    });

  }


  togglePublisherAudio() {
    this.publisherAudio = !this.publisherAudio;
    if (!this.publisherAudio) {
      if (this.stream) {
        this.browserPrivileges.audio = false;
        this.stream.getTracks().forEach(track => {
          if (track.kind == "audio") {
            track.stop()
          }
        });
      }
    } else {
      this.browserPrivileges.audio = true;
      navigator.mediaDevices.getUserMedia(this.browserPrivileges)
        .then(stream => {
          this.stream = stream;
        })
        .catch((err) => {
          alert('You should allow to use your camera and microphone.');
          this.callback.emit('no_user_permission');
          return;
        });
    }
    this.publisher.publishAudio(this.publisherAudio);
  }

  togglePublisherVideo() {
    this.publisherVideo = !this.publisherVideo;

    if (!this.publisherVideo) {
      if (this.stream) {
        this.browserPrivileges.video = false;
        this.stream.getTracks().forEach(track => {
          if (track.kind == "video") {
            track.stop()
          }
        });
      }
    } else {
      this.browserPrivileges.video = true;
      navigator.mediaDevices.getUserMedia(this.browserPrivileges)
        .then(stream => {
          this.stream = stream;
        })
        .catch((err) => {
          alert('You should allow to use your camera and microphone.');
          this.callback.emit('no_user_permission');
          return;
        });
    }

    this.publisher.publishVideo(this.publisherVideo);
  }

  makeFullScreen = (control, type) => {
    // console.log("hiio",control)
    const handler = $($($('.VSC-BS-SS-Streaming-Container').find('.video-full-screen')[0]).find('.OT_root')[0]);
    const parentHTML = $(handler[0])[0];
    if (handler.hasClass('OT_publisher')) {
      $('.Publisherthumb').html('');
      $('.Publisherthumb').append(parentHTML);
    } else {
      $('#subscribers').append( parentHTML );
    }
    if (type === 'publisher') {
      $('.Publisherthumb').hide();
    } else {
      $('.Publisherthumb').show();
    }
    $('.VSC-BS-SS-Streaming-Container').find('.video-full-screen').html(control[0]);
    // $('.VSC-BS-SS-Streaming-Container').find('.video-full-screen').find('.OT_root').css('height', '90vh');
    //   $('.Publisherthumb').find('.OT_root').css('height', '23vh');
    //   $('#subscribers').find('.OT_root').css('height', '25vh');
    setTimeout(function () {
      $('.VSC-BS-SS-Streaming-Container').find('.video-full-screen').find('.OT_root').css('height', '90vh');
      $('.Publisherthumb').find('.OT_root').css('height', '23vh');
      $('#subscribers').find('.OT_root').css('height', '25vh');
    }, 0);

  }

  // makeFullScreen(control, type) {

  //   const handler = $($($('.VSC-BS-SS-Streaming-Container').find('.video-full-screen')[0]).find('.OT_root')[0]);
  //   const parentHTML = $(handler[0])[0];
  //   if (handler.hasClass('OT_publisher')) {
  //     $('.Publisherthumb').html('');
  //     $('.Publisherthumb').append(parentHTML);
  //   } else {
  //     $('#subscribers').append(parentHTML);
  //   }
  //   if (type === 'publisher') {
  //     $('.Publisherthumb').hide();
  //   } else {
  //     $('.Publisherthumb').show();
  //   }
  //   $('.VSC-BS-SS-Streaming-Container').find('.video-full-screen').html(control[0]);
  //   setTimeout(() => {
  //     $('.VSC-BS-SS-Streaming-Container').find('.video-full-screen').find('.OT_root').css('height', '90vh');
  //     $('.Publisherthumb').find('.OT_root').css('height', '23vh');
  //     $('#subscribers').find('.OT_root').css('height', '25vh');
  //   }, 0);

  // }

  toggleSubscriberAudio(control) {
    const i = control.data('id');
    this.subscribers[i].stream.audio = !this.subscribers[i].stream.audio;
    const audioIcon = this.subscribers[i].stream.audio
      ? './assets/img/videostream/speaker.svg'
      : './assets/img/videostream/speaker_off.svg';
    this.subscribers[i].subscribeToAudio(this.subscribers[i].stream.audio);
    control.find('img').attr('src', audioIcon);
  }

  toggleSubscriberVideo(control) {
    const i = control.data('id');
    this.subscribers[i].stream.video = !this.subscribers[i].stream.video;
    const videoIcon = this.subscribers[i].stream.video ? './assets/img/videostream/video_active.svg' : './assets/img/videostream/video.svg';
    this.subscribers[i].subscribeToVideo(this.subscribers[i].stream.video);
    control.find('img').attr('src', videoIcon);
  }

  initTimer() {
    this.initializeTimer();
    this.timer();
  }

  initializeTimer() {
    const d = this.sessionData.sessionData.duration;
    this.seconds = 0;
    this.minutes = 0;
    this.hours = 0;
    if (d) {
      this.seconds = Math.floor(d / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.seconds = this.seconds % 60;
      this.hours = Math.floor(this.minutes / 60);
      this.minutes = this.minutes % 60;
      this.hours = this.hours % 24;
    }
  }

  timer() {
    this.t = setInterval(() => {
      this.addTimer();
    }, 1000);
  }

  addTimer() {
    this.seconds++;
    if (this.seconds >= 60) {
      this.seconds = 0;
      this.minutes++;
      if (this.minutes >= 60) {
        this.minutes = 0;
        this.hours++;
      }
    }
    this.duration = (this.hours ? (
      this.hours > 9 ? this.hours : '0' + this.hours
    ) : '00') + ':' +
      (this.minutes ? (this.minutes > 9 ? this.minutes : '0' + this.minutes) :
        '00') + ':' + (this.seconds > 9 ? this.seconds : '0' + this.seconds);
  }

  handleError(err) {
    if (err) {
      alert(err);
    }
  }

  minimizeVideoCallScreen() {
    this.minimizeVideoCallLayout = true;
    if (this.sessionType === 'text') {
      setTimeout(() => {
        $('.OT_publisher').hide();
        $('.OT_subscriber').hide();
      }, 100);
    }
  }

  maximizeVideoCallScreen() {
    this.minimizeVideoCallLayout = false;
  }

  showMessaging() {
    this.unreadMessages = 0;
    this.showMessagingScreen = !this.showMessagingScreen;
  }

  hideMessaging() {
    this.unreadMessages = 0;
    this.showMessagingScreen = false;
  }

  addParticipant() {
    this.showAddParticipantScreen = !this.showAddParticipantScreen;
  }

  hideParticipant() {
    this.showAddParticipantScreen = false;
  }


  searchParticipants(event) {

    this.participantsList = []

    this.createScheduleService.getCareProviders(event.query).subscribe(
      (response) => {
        response.map(
          (item) => {
            var name = item.prefix + ' ' + item.fullname + '(' + item.designation + ')';;
            var id = item._id;

            this.participantsList.push({
              text: name,
              id: id
            });
          }
        )
      }
    );

  }

  onAddParticipant(){
    const formValue = this.addParticipantForm.value;


    if (formValue.participants.length !== 0) {
      formValue.participants.map(
        (item) => {
          this.addedParticipants.push(item.id);
        }
      );
      this.dataToSend.participantsDetails.pop();
      this.addedParticipants.map(
        (item) => {
          this.dataToSend.participantsDetails.push({
            participant: item
          })
        }
      );
    }


    this.appointmentsService.addParticipant(this.dataToSend, this.sessionData.sessionData._id).subscribe(
      (response) => {
          const alert: AlertMsg = { msg: "Participant Added", title:  'Success' };
          this.alertMessageService.showSuccessToster(alert);
          this.addParticipantForm.reset();
          this.hideParticipant();

      }
    )
  }


  keyPressed(e) {
    if (e.keyCode === 13) {
      this.sendMessage();
    }
  }

  sendMessage() {
    if (!this.textMessage) {
      return false;
    }
    this.session.signal(
      {
        data: this.textMessage
      },
      (error) => {
        if (error) {
          alert("Error")
        } else {
          this.textMessage = '';
        }
      }
    );
  }

  close() {
    this.callback.emit();
  }

  closeVideoCallScreen() {
    // if (this.isRecording && (this.sessionData.recordEnabled && this.isOrganiser && (this.sessionData.sessionData.status == "inactive"))) {
    //   this.recordingControl();
    // }
    this.session.unpublish(this.publisher);
    this.session.disconnect();
    if (this.sessionType !== 'text' || this.publisher) {
      this.publisher.destroy();
    }
    this.session = null;
    this.callback.emit('endcall');
    /* release camera and mic */
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop()
      });
    }
  }

  recordingControl() {
    if (!this.isRecording) {

      this.appointmentsService.startRecording(this.sessionId, true, true, this.sessionData.sessionData.appointmentId)
        .subscribe(
          (response: RecordResponse) => {
            this.archiveId = response.data.id;
          },
          (error) => {
            if (error) {
              this.isRecording = false;
            }
          }
        );

    } else {
      this.appointmentsService.stopRecording(this.archiveId)
        .subscribe(
          (response: RecordResponse) => {
          },
          (error) => {
            if (error) {
              this.isRecording = true;
            }
          }
        );
    }
  }

  screenSharing(){
    OT.checkScreenSharingCapability((response) => {
      if(!response.supported || response.extensionRegistered === false) {
        // console.log("This browser does not support screen sharing.")
      } else if (response.extensionInstalled === false) {
        // Prompt to install the extension.
      } else {
        // console.log("Screen sharing is available. Publish the screen.")
        var publisher = OT.initPublisher('screen-preview',
          {videoSource: 'screen'},
          (error) => {
            if (error) {
              this.handleError(error);
            } else {
              this.session.publish(publisher, this.handleError);
            }
          }
        );
      }
    });
  }

}
