<div class="right-slide-holder">
  <div class="right-slide">
    <div class="container">

      <div class="row">
        <div class="col slider-head">
          <h1>Add Client</h1>
        </div>
      </div>


      <form [formGroup]="addClientForm" (ngSubmit)="onCreateAccount()">
        <div class="row content-scroll content-scroll-appointment">
          <div class="col-12 d-flex flex-column align-items-stretch row ">

            <!-- First Name -->
            <div class="mb-3">
              <label class="label slider-label">First Name</label>
              <input type="text" class="form-control field-input" placeholder="Enter first name"
                formControlName="fname">
              <!-- Error Messages -->
              <div class="form-error-con" *ngIf=" isFormSubmitted && addClientForm?.controls?.fname?.errors?.required &&
                (isFormSubmitted || addClientForm.dirty || addClientForm.touched )">
                <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
              </div>
            </div>
            <!-- Middle Name -->
            <div class="mb-3">
              <label class="label slider-label">Middle Name</label>
              <input type="text" class="form-control field-input" placeholder="Enter middle name"
                formControlName="mname">
              <!-- Error Messages -->
              <div class="form-error-con" *ngIf=" isFormSubmitted && addClientForm?.controls?.mname?.errors?.required &&
                (isFormSubmitted || addClientForm.dirty || addClientForm.touched )">
                <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
              </div>
            </div>
            <!-- Last Name -->
            <div class="mb-3">
              <label class="label slider-label">Last Name</label>
              <input type="text" class="form-control field-input" placeholder="Enter last name" formControlName="lname">
              <!-- Error Messages -->
              <div class="form-error-con" *ngIf=" isFormSubmitted && addClientForm?.controls?.lname?.errors?.required &&
                (isFormSubmitted || addClientForm?.dirty || addClientForm?.touched )">
                <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
              </div>
            </div>
            <!-- Email ID -->
            <div class="mb-3">
              <label class="label slider-label">Email ID</label>
              <input type="text" class="form-control field-input" placeholder="Enter email" formControlName="emailID">
              <!-- Error Messages - Mandatory field -->
              <div class="form-error-con" *ngIf=" isFormSubmitted && addClientForm?.controls?.emailID?.errors?.required &&
                    (isFormSubmitted || addClientForm.dirty || addClientForm.touched )">
                <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
              </div>
              <!-- Error Messages - Email format -->
              <div class="form-error-con" *ngIf="isFormSubmitted && addClientForm?.controls?.email?.errors?.requiredPattern &&
                        addClientForm?.value.email && (addClientForm?.dirty || addClientForm?.touched )">
                <span class="err-msg">{{errMsgs?.invalidEmail}}</span>
              </div>
            </div>
            <!-- Contact Number -->
            <div class="mb-3">
              <label class="label slider-label">Contact Number</label>
              <!-- <input type="text" required [mask]='generalValidations?.contactNumberMask'
                class="form-control field-input" placeholder="Enter contact number"
                [maxlength]="generalValidations?.contactNumberMax" formControlName="phone"> -->
              <input type="text" required [mask]='generalValidations?.contactNumberMask' class="form-control field-input" placeholder="Enter contact number"
                formControlName="phone" [maxlength]="generalValidations?.contactNumberMax">
              <!-- Error Messages - Mandatory field -->
              <div class="form-error-con" *ngIf=" isFormSubmitted && addClientForm?.controls?.phone?.errors?.required &&
                    (isFormSubmitted || addClientForm.dirty || addClientForm.touched )">
                <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
              </div>
            </div>


            <div class="mb-3">
              <div class="form-group d-flex flex-column justify-contents-start align-items-stretch">
                <label class="label slider-label duration-label">Type</label>
                <div>
                  <select class="form-control" formControlName="dhs">
                    <option value=true>DHS</option>
                    <option value=false>Paid</option>
                  </select>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center">
          <button class="btn cancel-button" (click)="onCancel()">Cancel</button>
          <button class="btn btn-primary" type="submit" [disabled]="addClientForm.invalid">Submit</button>
        </div>
      </form>

    </div>
  </div>
</div>
