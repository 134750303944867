<div class="container-fluid">
    <div class="wrapper">
        <div class="logo-banner">
            <img class="logo logo-large" src="assets/images/main-logo.png">
            <!-- <span class="banner-title company-name">GUARDIAN RPM</span> -->
        </div>
        <div class="sec-con">
            <div class="row">
                <div class="col-12">
                    <div class="logo-banner">
                        <img class="logo logo-large" src="assets/images/main-logo.png">
                    </div>
                </div>
                <div class="col-12">
                        <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
