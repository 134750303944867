import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiUrls } from '@utilities/api-urls';

import { TreatmentPlanDetailRequestData, TreatmentPlanDetailResponseData } from '@models/treatment-plan';

@Injectable({
  providedIn: 'root'
})
export class TreatmentPlanService {

  constructor(private httpClient: HttpClient) { }

  /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: API call for posting a new/updated question to server
  */
 postTreatmentPlan(activityData: TreatmentPlanDetailRequestData): Observable<any> {
  const apiUrl = `${ApiUrls.TREATMENT_PLAN.saveTp}`;
  return this.httpClient.post(apiUrl, activityData).pipe(
    map (
      (response: TreatmentPlanDetailResponseData) => { return response; },
      (error) => { return error; }
    ),
  );
 }

 getTreatmentPlanDetails(tpId: string): Observable<any> {
  const apiUrl = `${ApiUrls.TREATMENT_PLAN.gettpdetails}`;
  const paramsObj = {
    params: {
      tpid: tpId
    }
  };
  return this.httpClient.get(apiUrl, paramsObj).pipe(
    map (
      (response: TreatmentPlanDetailResponseData) => { return response; },
      (error) => { return error; }
    ),
  );
 }

}
