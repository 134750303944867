export class InputFieldLimits {

  /**
 * Author: TO512 Krupa Tresa Joseph
 * Description: Stores the maximum and minimum limits of fields
 */
  public static generalValidations = {
    otpMax: 5,
    contactNumberMax: 14,
    passwordMax: 20,
    city: 100,
    name: 50,
    state: 50,
    country: 50,
    placeMax: 100,
    addressMax: 200,
    ageMax: 70,
    ageMin: 1,
    daysMax: 30,
    timeMax: 59,
    timeMin: 0,
    zipcode: 5,
    paymentMax: 2000000000,
    descMax: 1000,
    searchInput: 30,
    simpleInput: 100,
    numberField: 99999,
    contactNumberMask: '(000)-000-0000',
    emailRegx: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  };

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Stores the maximum and minimum limits of fields
   */
  public static loginForm = {
    emailMax: 50,
    passwordMax: 30,
  };

  /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: CSA - Personal info related form validations
     */
  public static csaPersonalInfo = {
      name: 50,
      id: 10,
      concernsMax: 500
  };

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: CSA - Family info related form validations
   */
  public static csaFamilyInfo = {
      grade: 20,
      job: 100,
      age: 2,
  };

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: CSA - Medical info related form validations
   */
  public static csaMedicalInfo = {
      medicine: 20,
      amount: 10,
      disabilityType: 100,
      problemsDesc: 100,
  };

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: CSA - Birth history info related form validations
   */
  public static csaBirthHistoryInfo = {
      pregnancyNumberMax: 20,
      pregnancyNumberMin: 1,
      earlyDeliveryInfo: 100,
      labourDuration: 100,
      poundsMax: 20,
      anasthesiaDesc: 100,
      birthWeight: 100,
      babyCry: 100,
      medicationListDesc: 1000,
  };

  /**
     * Author: TO512 Krupa Tresa Joseph
     * Description: Stores the validations for files which are uploaded
     */
  public static normaFileFile = {
    images: ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf', 'video/mp4', 'video/x-matroska' ],
    iconMaxLimit: 30000000, // 30 MB,
    unit: 'MB'
  };

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: CSA - Medical history info related form validations
   */
  public static csaMedicalHistoryInfo = {
  };


  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Treatment plan - activity detail info related form validations
   */
  public static activityDetail = {
    activityNameMax: 100,
    durationMinutesMax: 60,
    durationHoursMax: 24,
    recurrenceDayMax: 7,
    recurrenceWeekMax: 4,
    materialsMax: 10000,
    procedureMax: 10000,
    expectationMax: 1000,
  };

  /**
   * Author: Jeswin Chacko P A
   * Description: Stores the maximum and minimum limits of fields
   */
  public static resetPasswordForm = {
      passwordMax: 30,
      passwordRegex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&()*+,-./:;=?@[\]^_`{|}~]).{8,30}$/,
  };

  /**
     * Author: Jeswin Chacko P A
     * Description: Stores the validations for files which are uploaded in activity
     */
  public static documentFile = {
      images: ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf' ],
      iconMaxLimit: 30000000, // 30 MB,
      unit: 'MB'
  };


}
