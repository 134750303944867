import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TableController } from '@models/table';
import { TableFilter } from '@models/table-filter';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-table-controller',
  templateUrl: './table-controller.component.html',
  styleUrls: ['./table-controller.component.scss']
})
export class TableControllerComponent implements OnInit {

  // Subscriptions
  searchTextSubscription$: Subscription;

  // Incoming variables
  @Input() tableController: TableController;

  // Event emitters
  @Output() searchItem = new EventEmitter();
  @Output() filterChange = new EventEmitter();

  // Form Controllers
  searchText = new FormControl('', []);


  constructor() { 
    this.searchTextSubscription$ = new Subscription();
    this.subscribeToSearchInput();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.searchTextSubscription$.unsubscribe();
  }

  // ---------------- METHODS RELATED TO EVENT TRIGGERS ------------------

  onFilterClick(filterInfo: TableFilter) {
    this.filterChange.emit(filterInfo);
  }


  // ----------------- SUBSCRIPTIONS ---------------------

  subscribeToSearchInput() {
    this.searchTextSubscription$ = this.searchText.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((newValue) => {
      this.searchItem.emit(newValue);
    });
  }

}
