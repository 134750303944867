import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumberOnlyDirective {

  @Input() maximumLength;

  @Input() maxValue: string;
  @Input() minValue: string;

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onChange($event) {
    let inputVal = (this.el.nativeElement).value;
    inputVal = (-1 === inputVal) ? '' : inputVal;
    inputVal = inputVal ? inputVal.replace(/[^0-9]/g, '') : '';

    if (this.maxValue) {
      if (parseFloat(inputVal) > parseFloat(this.maxValue)) {
        inputVal = this.maxValue;
      }
    }

    if (this.minValue) {
      if (parseFloat(inputVal) < parseFloat(this.minValue)) {
        inputVal = this.minValue;
      }
    }

    if (this.maximumLength) {
      inputVal = String(inputVal).substring(0, this.maximumLength);
    }

    this.control.control.setValue('');
    this.control.control.setValue(inputVal);
  }

}
