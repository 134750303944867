import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { MenuItem } from '@models/menu-item';
import { AppDetails } from '@models/app-details';
import { LoggedUserInfo } from '@models/logged-user';
import { ConfirmDialog } from '@models/confirm-dialog';

import { ConfirmDialogComponent } from '@shared-components/confirm-dialog/confirm-dialog.component';

import { MenuList } from '@utilities/menu-list';
import { AppConstants } from '@utilities/app-constants';
import { AuthConstants } from '@utilities/auth-constants';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from '@services/auth/auth.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { RoleFeatureInfo, UserPermission } from '@models/role-info';
import { RoleMockData } from 'src/app/shared/mock/role-feature-mock';
import { SettingsComponent } from '@modules/settings/settings.component';
import { ChangePasswordComponent } from '@modules/auth/change-password/change-password.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // Constants
  dummyImgUrl: string;

  // App Info
  appDetails: AppDetails;

  // User Info
  userInfo: LoggedUserInfo;

  // Menu Info
  menuList: MenuItem[];

  // Flags
  showSideBar: boolean;

  // ModalRefs
  logoutModalRef: BsModalRef;
  settingsModalRef: BsModalRef;
  changePasswordModalRef: BsModalRef;

  // Subscriptions
  userInfoSubscription$ = new Subscription();
  userPermissionsSubscription$ = new Subscription();
  displayRole: string;

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
    private alertMsgService: AlertMsgService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
  ) {
    this.menuList = [];
    this.showSideBar = false;
    this.dummyImgUrl = 'assets/images/avatar-default.png';
    this.subscribeToUserInfo();
    this.subscribeToUserPermissions();
  }

  ngOnDestroy() {
    this.userInfoSubscription$.unsubscribe();
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Populates user info in the header section
   */
  subscribeToUserInfo() {
    this.userInfoSubscription$ = this.authService.currentUser.subscribe(
      (user: LoggedUserInfo) => {
        this.userInfo = user;
        this.displayRole= this.formatRoleText();
      }
    );
  }


    /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Populates user info in the header section
   */
  subscribeToUserPermissions() {
    this.userPermissionsSubscription$ = this.authService.userPermissions.subscribe(
      (userPermissions: UserPermission) => {
        if (userPermissions && userPermissions.permissions.length > 0) {
          this.setMenuList(userPermissions.permissions);
        }
      });
  }

  ngOnInit(): void {}

  setMenuList(permissionsList: RoleFeatureInfo[]) {
    this.menuList = MenuList.allMenuItems;
    if (permissionsList.length > 0) {
      const features = permissionsList;
      this.menuList.forEach((menuItem: MenuItem) => {
        menuItem.showOnList = false;
        let matchIndex;
        matchIndex = features.findIndex((feature: RoleFeatureInfo) => feature.feature_code == menuItem.key);
        if (matchIndex != -1) {
          menuItem.showOnList = true;
        }
      });
    }
  }

  handleBrokenImage(image) {
    this.userInfo.avatar = this.dummyImgUrl;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Shows a confirmation dialog asking whether the user is sure to logout
   */
  onLogoutClick() {
    const initialState: ConfirmDialog = AppConstants.logoutConfirmDialog;
    this.logoutModalRef = this.modalService.show(
      ConfirmDialogComponent,
      {
        class: 'modal-dialog-centered modal-sm',
        initialState
      }
    );
    this.logoutModalRef.content.onConfirmProcess.subscribe(
      (result) => {
        if (result) {
          this.logoutUser();
        }
      }
    );
  }

  logoutUser() {
    this.authService.logoutUser().subscribe(
      (response) => {},
      (error: HttpErrorResponse) => {}
    );
  }

  toggleSideBar() {
    this.showSideBar = !this.showSideBar;
  }
  formatRoleText() {
    switch (this.userInfo.role) {
      case 'staff-admin': {
        return 'Staff-Admin'
        break;
      }
      case 'expert': {
        return 'Expert'
        break;
      }
      case 'care-provider': {
        return 'Care Provider'
        break;
      }
      case 'admin': {
        return 'Super-Admin'
        break;
      }
    }
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Shows a dialog box of site settings
   */
  onSettingsClick() {
    const initialState: ConfirmDialog = AppConstants.logoutConfirmDialog;
    this.settingsModalRef = this.modalService.show(
      SettingsComponent,
      {
        class: 'modal-dialog-centered modal-md',
        initialState
      }
    );
  }

  onChangePasswordClick() {
    const initialState: ConfirmDialog = AppConstants.logoutConfirmDialog;
    this.changePasswordModalRef = this.modalService.show(
      ChangePasswordComponent,
      {
        class: 'modal-dialog-centered modal-md',
        initialState
      }
    );
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Shows a dialog box of site settings
   */
  onTrainingClick() {
    const downloadLink = document.createElement('a');
    downloadLink.href = 'http://stage-knowledge.guardianrpm.com/';
    downloadLink.target = '_blank';
    downloadLink.click();
  }


}
