import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';

import { RouterModule } from '@angular/router';
import { ScheduleWidgetModule } from '@modules/schedule-widget/schedule-widget.module';
import { SidebarModule } from 'primeng/sidebar';
import { SettingsModule } from '@modules/settings/settings.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    AuthLayoutComponent,
    UnauthorizedComponent,
    ContentLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule,
    ScheduleWidgetModule,
    SettingsModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ]
})
export class LayoutModule { }
