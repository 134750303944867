
<div id="page-top">
    <!-- PAGE WRAPPER -->
    <div id="wrapper" class="app-wrapper">
        <!-- CONTENT WRAPPER -->
        <div id="content-wrapper" class="d-flex flex-column">
            <!-- Main Content -->
            <div id="content">
                <!-- MAIN HEADER -->
                <app-header></app-header>
                <div class="container-fluid">
                    <!-- ROUTER OUTLET TO AUTHENTICATED PAGES -->
                    <router-outlet></router-outlet>
                </div>
                <!-- Main Footer -->
                <app-footer></app-footer>
            </div>
        </div>

          <app-schedule-widget (callback)="closeVideoScreen()" [sessionData]="sessionData" *ngIf="showVideoScreen"></app-schedule-widget>
    </div>
</div>


