import { ActivityActions, ActivityMatrixInfo, FinalMatrixData } from './activity-matrix';
import { ListMetaData } from './generic-types';
import { TreatmentActivities, TreatmentActivityDetail } from './treatment-activities';

export interface MasterActivitiesParams {
    domainId: string;
    sensorId: string;
}
export interface CreateActivityRequestData {
    data: TreatmentActivities;
    secured: boolean;
}
export interface PackedActivityInfo {
    _id?: string;
    activity_details: TreatmentActivities;
    activity_matrix: {
        primary_impact: {
            domain: {
                _id: string;
                id: string;
                domainName: string;
            }
            sensor: {
                _id: string;
                id: string;
                sensorName: string;
            }
        },
        secondary_impact: {
            domain: {
                _id: string;
                id: string;
                domainName: string;
            }
            sensor: {
                _id: string;
                id: string;
                sensorName: string;
            }
        }[];
    }
}
export interface ActivityListHttpResponse {
    data: {
        records: PackedActivityInfo[];
        _metadata: ListMetaData; 
    }
    message: string;
    code: number;
    status: boolean;
}
export interface CreateActivityHttpResponse {
    data: PackedActivityInfo;
    token?: string;
    message: string;
    code: number;
    status: boolean;
}
export interface UpdateStatusHttpResponse {
    data: any;
    token?: string;
    message: string;
    secured: boolean;
    statusCode: number;
    status: boolean;
}
export interface DeleteActivityHttpResponse {
    data: PackedActivityInfo;
    token?: string;
    message: string;
    secured: boolean;
    statusCode: number;
    status: boolean;
}
export interface ActivityDetailSubject {
    activityData: PackedActivityInfo;
    status: boolean;
    mode: string;
}

// --------------- CLASS --------------------

export class MasterActivityManager {
    
    static createActivityActions(event?: ActivityActions): ActivityActions {
        let formatedAction;
        if (event) {
            formatedAction = {
                edit: event.edit ? event.edit : true,
                view: event.view ? event.view : true,
                delete: event.delete ? event.delete : true,
            };
        } else {
            formatedAction = { edit: true, view: true, delete: true };
        }
        return formatedAction;
    } 
}