import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder} from '@angular/forms';
import { Component, OnInit, Input, HostListener, ElementRef, Output, EventEmitter, ViewChild } from '@angular/core';

import { ApiUrls } from '@utilities/api-urls';
import { ToastrMessages } from '@utilities/toastr-messages';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { requiredFileSize, requiredFileType } from '@utilities/validators';

import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { FileUploadService } from '@services/file-upload/file-upload.service';

import { AlertMsg } from '@models/alert-msgs';
import { BaseReferences, FileListInfo, FileUploadResponse, UploadParams } from '@models/file-upload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit {

  @Input() apiUrl;
  @Input() nameRef;
  @Input() fileName;
  @Input() fileLocation;
  @Input() locationRef;
  @Input() disableField;
  @Input() fileValidations;
  @Input() fileList: FileListInfo[];
  @Input() multipleMode: boolean;
  @Input() uploadParams: UploadParams;

  @Output() removeFile = new EventEmitter();
  // @Output() downloadFile = new EventEmitter();
  @Output() fileUploaded = new EventEmitter();
  @Output() uploadInProgress = new EventEmitter();

  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;

  onChange: Function;
  fileForm: FormGroup;
  public file: File | null = null;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {}

  constructor(
    private formBuilder: FormBuilder,
    private host: ElementRef<HTMLInputElement>,
    private fileUploadService: FileUploadService,
    private alertMessageService: AlertMsgService,
  ) {
    this.multipleMode = false;
    this.apiUrl = ApiUrls.COMMON_SERVICE.fileUpload;
  }

  ngOnInit() {
    this.initialiseFileForm()
  }

  initialiseFileForm() {
    this.fileForm = this.formBuilder.group({
      file: ['', [
        requiredFileSize(this.fileValidations.iconMaxLimit),
        requiredFileType(this.fileValidations.images),
      ]]
    });
  }

  onFileUploadClick() {
    const fileElement = this.fileUpload.nativeElement;
    const selectedFileInfo = fileElement.files[0];
    if (selectedFileInfo) {
      this.fileForm.patchValue({file: selectedFileInfo});
      const isValid = this.checkFileValidity();
      if (isValid) {
        this.uploadFileData(selectedFileInfo);
      }
    }
  }

  onDownloadSelectedFile() {
    const downloadLink = document.createElement('a');
    downloadLink.href = this.fileLocation;
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  onRemoveSelectedFile() {
    this.fileUpload.nativeElement.value = '';
    const baseRefernces: BaseReferences = {
      nameRef: this.nameRef,
      locationRef: this.locationRef,
    };
    this.removeFile.emit(baseRefernces);
  }

  checkFileValidity() {
    let proceedStatus;
    if (this.fileForm.valid ) {
      proceedStatus = true;
    } else {
      // Check for file errors
      proceedStatus = false;
      const fileControlDataErrors = this.fileForm.controls.file.errors;
      if (fileControlDataErrors.requiredFileType) {
        const alert: AlertMsg = {
          msg: ToastrMessages.FILE_VALIDATIONS.type,
          title: ToastrMessages.TOASTR_TITLES.error
        };
        this.alertMessageService.showErrorToster(alert);
      } else if (fileControlDataErrors.requiredFileSize) {
        const mbValue = this.fileValidations.iconMaxLimit / 1000000;
        const alert: AlertMsg = {
          msg: `${ToastrMessages.FILE_VALIDATIONS.size}${mbValue} ${this.fileValidations.unit}`,
          title: ToastrMessages.TOASTR_TITLES.error
        };
        this.alertMessageService.showErrorToster(alert);
      }
    }
    return proceedStatus;
  }

  uploadFileData(selectedFileInfo) {
    const formData: FormData = new FormData();
    formData.append('file', selectedFileInfo);
    this.uploadInProgress.emit(true);
    this.fileUploadService.uploadFileData(formData, this.apiUrl, this.uploadParams).subscribe(
      (response: FileUploadResponse) => {
        this.uploadInProgress.emit(false);
        this.fileUploaded.emit(response.data);
        const alert: AlertMsg = {
          msg: ToastrMessages.FORM_ERROR_MSGS.fileUpload,
          title: ToastrMessages.TOASTR_TITLES.success
        };
        this.alertMessageService.showSuccessToster(alert);
      },
      (error: HttpErrorResponse) => {
        this.uploadInProgress.emit(false);
        const alert: AlertMsg = {
          msg: error.error ? error.error.message : error.message,
          title: ToastrMessages.TOASTR_TITLES.error
        };
        this.alertMessageService.showErrorToster(alert);
      }
    );
  }

  getFormData(requestParams: Object = {}) {
    const fd = new FormData();
    for (const key in requestParams) {
      if (requestParams[key]) {
        if (requestParams[key].constructor === Array) {
          for (const innerKey in requestParams[key]) {
            if (requestParams[key][innerKey]) {
              fd.append(key, requestParams[key][innerKey]);
            }
          }
        } else {
          fd.append(key, requestParams[key]);
        }
      } else {
        fd.append(key, requestParams[key]);
      }
    }
    return fd;
  }

  // ----- Multiple File upload methods -----

  onDownloadFileFromList(fileInfo: FileListInfo) {
    const downloadLink = document.createElement('a');
    downloadLink.href = fileInfo.fileLocation;
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  onRemoveFileFromList(fileInfo: FileListInfo) {
    this.fileUpload.nativeElement.value = '';
    this.removeFile.emit(fileInfo);
  }

  // ---- MANDATORY SUPPORTING METHODS ----

  writeValue( value: null ) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {}


}
