import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '@utilities/app-constants';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: any, months?: Boolean): string {
    let retVal = '';
    if (!value) {
      retVal = '';
    }
    if (months) {
      retVal = `${value} ${AppConstants.valuePipes.agePipeMonths}`;
    } else {
      retVal = `${value} ${AppConstants.valuePipes.agePipe}`;
    }
    return retVal;
  }

}
