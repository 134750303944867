import { Subscription, pipe } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';

import { 
  PrimeNgTableColumns,
  PrimeNgTableActions,
  PrimeNgTableSettings,
  PrimeNgTableColEditInfo, 
  PrimeNgInputClickSubject,
  PrimeNgMenuTriggerInfo,
} from '@models/prime-ng-table';

import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';

import { AppConstants } from '@utilities/app-constants';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ThirdPartyConfig } from '@utilities/third-party-config';
import { TableConfigConstants } from '@utilities/table-config-constants';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {

  // @ViewChild('dataTable', null) dataTable: Table;

  // INCOMING VARIABLES: Table related variables
  @Input() tableItems;
  // @Input() tableMenuItems;
  @Input() tableTitle: string;
  @Input() tableMenuItems: MenuItem[];
  @Input() tableActions: PrimeNgTableActions[];
  @Input() tableInputActions: PrimeNgTableActions[];
  @Input() tableColumns: PrimeNgTableColumns[];
  @Input() tableSettings: PrimeNgTableSettings;

  // EVENT EMITTERS
  @Output() editItem = new EventEmitter();
  @Output() viewItem = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  @Output() tableMenuClick = new EventEmitter();
  @Output() deactivate = new EventEmitter();
  @Output() searchItem = new EventEmitter();
  @Output() editTableCol = new EventEmitter();
  @Output() checkboxTrigger = new EventEmitter();
  @Output() triggerAccount = new EventEmitter();
  @Output() triggerContact = new EventEmitter();
  @Output() triggerRefund = new EventEmitter();
  @Output() triggerPayout = new EventEmitter();
  @Output() viewPayoutLog = new EventEmitter();
  @Output() managePagination = new EventEmitter();
  @Output() linkItem = new EventEmitter();
  @Output() navigateToDetailPage = new EventEmitter();

  // General variables
  dataTypes: any;
  actionTypes: any;
  actionsList: any;
  tableActiontext: string;
  searchText = new FormControl('', []);

  selectedRowItem: any;

  // CONSTANTS
  dummyImgUrl: string;
  genericFormats: any;
  tableMenuConfig: any;
  fieldValidations: any;
  expandableTableIdList: any;

  // Subscriptions
  searchtextSubscription$;

  constructor() {
    this.dummyImgUrl = 'assets/images/admin-img.png';
    this.dataTypes = TableConfigConstants.tableDataType;
    this.actionsList = TableConfigConstants.tableActionsList;
    this.actionTypes = TableConfigConstants.tableActionsTypes;
    this.genericFormats = TableConfigConstants.genericFormats;
    this.tableActiontext = TableConfigConstants.tableGenericContents.actionColText;

    this.fieldValidations = InputFieldLimits.generalValidations;
  
    this.tableMenuConfig = ThirdPartyConfig.tableMenuConfig;
  
    this.searchtextSubscription$ = new Subscription();
    this.subscribeToSearchInput();
   }

  ngOnInit() {}

  ngOnDestroy() {
    this.searchtextSubscription$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName) {
        const change = changes[propName];
        const curVal = change.currentValue;
        const preVal = change.previousValue;
        const strCurVal = JSON.stringify(curVal);
        const strPrevVal = JSON.stringify(preVal);
        if (strCurVal !== strPrevVal) {
        }
      }
      if (propName == 'tableMenuItems') {
        this.configureMenuCallBacks();
      }
    }
  }

  handleBrokenImage(item) {
    item.profile_pic = this.dummyImgUrl;
  }

  configureMenuCallBacks() {
    this.tableMenuItems.forEach((item: MenuItem) => {
      item.items.forEach((menuData) => {
        menuData.command = () => this.onMenuClick(menuData)
      })
    }); 
  }

  onEditTableColumn(itemData: any, colData: PrimeNgTableColumns) {
    const emitInfo: PrimeNgTableColEditInfo = {
      colData: itemData,
      headerData: colData
    };
    this.editTableCol.emit(emitInfo);
  }

  onActionClick(action: PrimeNgTableActions, selectedItem: any) {
    switch (action.action) {
      case this.actionsList.delete:
        this.deleteItem.emit(selectedItem);
        break;
      case this.actionsList.edit:
        this.editItem.emit(selectedItem);
        break;
      case this.actionsList.view:
        this.viewItem.emit(selectedItem);
        break;
      case this.actionsList.view:
        this.viewItem.emit(selectedItem);
        break;
      case this.actionsList.deactivate:
        this.deactivate.emit(selectedItem);
        break;
      case this.actionsList.activate:
        this.activate.emit(selectedItem);
        break;
      case this.actionsList.checkbox:
        const triggerInfo: PrimeNgInputClickSubject = {
          actionInfo: action,
          itemInfo: selectedItem
        };
        this.checkboxTrigger.emit(triggerInfo);
        break;
      case this.actionsList.triggerContact:
        this.triggerContact.emit(selectedItem);
        break;
      case this.actionsList.triggerAccount:
        this.triggerAccount.emit(selectedItem);
        break;
      case this.actionsList.triggerPayout:
        this.triggerPayout.emit(selectedItem);
        break;
      case this.actionsList.viewPayoutLog:
        this.viewPayoutLog.emit(selectedItem);
        break;
      case this.actionsList.link:
        this.linkItem.emit(selectedItem);
        break;
      }
  }

  onViewDetailPage(itemData, clickEnabled) {
    if (clickEnabled) {
      this.navigateToDetailPage.emit(itemData);
    }
  }

  onPageChange(pageInfo) {
      this.managePagination.emit(pageInfo);
  }

  onOpenTableMenu(tableItem: any) {
    this.selectedRowItem = tableItem;
  }

  onMenuClick(menuData: MenuItem) {
    const emitInfo: PrimeNgMenuTriggerInfo = {
      colData: this.selectedRowItem,
      menuData: menuData
    };
    this.tableMenuClick.emit(emitInfo);
  }

  onResetTable() {
    // if (this.dataTable) {
    //   this.dataTable.reset();
    // }
  }

  // ----------------- SUBSCRIPTIONS ---------------------

  subscribeToSearchInput() {
    this.searchtextSubscription$ = this.searchText.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((newValue) => {
      const formattedValue = newValue ? newValue.toLowerCase().trim() : '';
      this.searchText.patchValue(formattedValue);
      this.searchItem.emit(formattedValue);
    });
  }

}
