<div class="modal-header" *ngIf="title">
    <h5 class="modal-title pull-left">
        {{title}}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close"(click)="onCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <span class="main-con">
        {{message}}
    </span>
</div>
<div class="modal-footer">
    <div class="btn-con">
        <button class="btn cancel-btn"
        (click)="onCancel()" [title]="cancelBtnText">
            {{cancelBtnText}}
        </button>
        <button class="btn submit-btn"
        (click)="onConfirm()" [title]="confirmBtnText">
            {{confirmBtnText}}
        </button>
    </div>
</div>
