import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertMsg } from '@models/alert-msgs';
import { ChangePasswordData, ChangePasswordResponse } from '@models/change-password';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { AuthService } from '@services/auth/auth.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { AppConstants } from '@utilities/app-constants';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { requiredPattern } from '@utilities/validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  pageTitle: string;

  // Subjects
  onCancelProcess = new Subject();
  onConfirmProcess = new Subject();

  // Constants
  errMsgs: any;
  redirectUrls: any;
  fieldValidations: any;
  generalValidations: any;
  activationCode: any;

  // Form related variables
  changePasswordForm: FormGroup;

  // Flags
  showPassword: boolean;
  isFormSubmitted: boolean;
  showConfirmPassword: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private alertMessageService: AlertMsgService,
    private changePasswordService: ChangePasswordService,
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.pageTitle = AppConstants.pageTitles.changePassword;
    this.showPassword = false;
    this.showConfirmPassword = false;
    this.isFormSubmitted = false;
    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;
    this.fieldValidations = InputFieldLimits.resetPasswordForm;
    this.generalValidations = InputFieldLimits.generalValidations;
  }

  ngOnInit(): void {
    this.initialiseChangePasswordForm();
  }

  onCancel() {
    this.onCancelProcess.next(true);
    this.bsModalRef.hide();
  }

  /**
    * Author: Jeswin Chacko P A
    * Description: Displays error alert for failed api response
    */
  showErrorToastr() {
    const alert: AlertMsg = {
      msg: ToastrMessages.RESETPASSWORD_ACTIVATION.activateResetPasswordFail,
      title: ToastrMessages.TOASTR_TITLES.error
    };
    this.alertMessageService.showErrorToster(alert);
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Initialises changepassword form with necessary form fields
   */
  initialiseChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, requiredPattern(this.fieldValidations.passwordRegex)]],
      confirm_password: ['', [Validators.required, requiredPattern(this.fieldValidations.passwordRegex)]],
      },
    );
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Validates the form and calls the post API method based on the result
   */

  onChangePassword(){
    this.isFormSubmitted = true;
    if (this.changePasswordForm.valid) {
      const ChangePasswordData: ChangePasswordData = this.changePasswordForm.value;
      this.postChangePasswordInfo(ChangePasswordData);
    }
  }

  /**
   * Author: Jeswin Chacko P A
   * @param { LoginInfo } ChangePasswordData
   * Description: Send the change-password info to the  API
   */
  postChangePasswordInfo(ChnagePasswordData: ChangePasswordData) {
    let requestData: ChangePasswordData = this.constructRequestData(ChnagePasswordData);
    this.changePasswordService.postChangePasswordInfo(requestData).subscribe(
      (response: ChangePasswordResponse) => {
        if (response.status) {
            this.utilityService.showResponseSuccess(ToastrMessages.RESETPASSWORD_FORM_MSGS.resetPasswordSuccess, response.message);
            this.bsModalRef.hide();
            this.authService.purgeAuth();
            localStorage.clear();
            this.sessionStorageService.clearLocalStorageValues();
            this.router.navigateByUrl(AppConstants.redirectUrls.login);
        } else {
          this.utilityService.showResponseError(ToastrMessages.RESETPASSWORD_FORM_MSGS.resetPasswordFail, response.message);
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.utilityService.showResponseError(ToastrMessages.RESETPASSWORD_FORM_MSGS.resetPasswordFail, error.error.message );
        }
    }
    );
  }

  /**
    * Author: Jeswin Chacko P A
    * Description: COnstructs requestdata for change-password API request
    */
  constructRequestData(ChangePasswordData: ChangePasswordData): ChangePasswordData {
    ChangePasswordData.reset_token = this.activationCode;
    ChangePasswordData.old_password = this.utilityService.hashPassword(ChangePasswordData.old_password);
    ChangePasswordData.new_password = this.utilityService.hashPassword(ChangePasswordData.new_password);
    ChangePasswordData.confirm_password = this.utilityService.hashPassword(ChangePasswordData.confirm_password);
    const requestData: ChangePasswordData = ChangePasswordData;
    return requestData;
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Toggles the flag which controls password visibility
   */
  togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
  }

  /**
   * Author: Jeswin Chacko P A
   * Description: Toggles the flag which controls confirm password visibility
   */
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

}
