import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as sha1 from 'sha1';
import * as _ from 'lodash';
import * as moment from 'moment';

import { AlertMsgService } from '@services/alert-msg/alert-msg.service';

import { AlertMsg } from '@models/alert-msgs';

import { AppConstants } from './app-constants';
import { AuthConstants } from './auth-constants';
import { ToastrMessages } from './toastr-messages';
import { TableFilter, TableFilterChangeResult } from '@models/table-filter';
import { TableConfigConstants } from './table-config-constants';
import { DropdownOption } from '@models/generic-types';
import { RoleFeatureInfo } from '@models/role-info';
import { MenuList } from './menu-list';
import { MenuItem } from '@models/menu-item';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private router: Router,
    private alertMsgService: AlertMsgService
  ) { }

  hashPassword(passwordVal: any): string {
    return sha1(passwordVal);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Delete an item from the array
   */
  public deleteItem(currentArray: any[], deletedItem: any, mainKey: string, subKey: string = null): any[] {
    const updatedList = _.remove(currentArray, (item) => {
      if (subKey) {
        return item[mainKey][subKey] !== deletedItem[mainKey][subKey];
      } else {
        return item[mainKey] !== deletedItem[mainKey];
      }
    });
    return updatedList;
  }

  showResponseSuccess(defaultMsg: string, serverMessage?: string) {
    const message: string = serverMessage ? serverMessage : defaultMsg;
    const alert: AlertMsg = {
      msg: message,
      title: ToastrMessages.TOASTR_TITLES.success
    };
    this.alertMsgService.showSuccessToster(alert);
  }

  showResponseError(defaultMsg: string, serverMessage?: string) {
    const message: string = serverMessage ? serverMessage : defaultMsg;
    const alert: AlertMsg = {
      msg: message,
      title: ToastrMessages.TOASTR_TITLES.error
    };
    this.alertMsgService.showErrorToster(alert);
  }

  /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: Check the value of token in storage
  */
  checkTokenInStorage(): boolean {
    let retValue = false;
    retValue = localStorage.getItem(AuthConstants.localStorageVariables.access_token) ? true : false;
    return retValue;
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Redirect user to landing page
    */
  redirectToLandingPage() {
      this.router.navigateByUrl(AppConstants.redirectUrls.staffAmdinLandingPage);
  }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Redirect user to login page
    */
  redirectToLogin() {
    this.router.navigateByUrl(AppConstants.redirectUrls.login);
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * @param { LoginResponseData } responseData
   * Description: Based on the permissions assigned to the user, the landing page is set
   */
  checkLandingPage(features: RoleFeatureInfo[]): string {
    const menuList = MenuList.allMenuItems;
    let redirectUrl: string = '';
    let proceedIteration = true;
    menuList.forEach((mainMenu: MenuItem) => {
      let matchIndex;
      if (proceedIteration) {
        matchIndex = features.findIndex((feature: RoleFeatureInfo) => feature.feature_code == mainMenu.key);
        if (matchIndex != -1) {
          proceedIteration = false;
          redirectUrl = mainMenu.link;
        }
      }
    });
    return redirectUrl;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Converts UTC date to to local format
   */
  convertToLocalTime(utcDate: string): any {
    let localDate = new Date();
    if (utcDate) {
      localDate = new Date(utcDate);
    }
    return localDate;
  }

  calculateAge(dob: any, months?: boolean): number {
    let age;
    if(months){
      age = moment().diff(dob, 'months',false);
    }
    else{
      age = moment().diff(dob, 'years',false);
    }
    return age;
  }

  calculateNextPageNumber(currentPage: any) {
    let nextPageVal = 0;
    if (currentPage == 0) {
      nextPageVal = 1;
    } else {
      nextPageVal += 1;
    }
    return nextPageVal;
  }

  constructDropdownFormat(rawList: any[], valueText: string, labelText: string, retainOriginal = false ): any[] {
    const formattedList = rawList.map(
      (item) => {
        if (retainOriginal) {
          item = item;
        }
        item.label = item[labelText];
        item.value = item[valueText];
        item.key = item[labelText] ? item[labelText].toLowerCase(): '';
        return item;
      }
    );
    return formattedList;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: find index of a particular item in an array
   */
  findItemIndex(itemsList: any[], selectedItemRefKey: any, refKey: string) {
    const index = _.findIndex(itemsList, item => item[refKey] === selectedItemRefKey );
    return index;
  }

  isFeaturePresent(itemsList: RoleFeatureInfo[], selectedItemRefKey: string): boolean {
    let isItemPresent = false
    const index = _.findIndex(itemsList, item => item.feature_code === selectedItemRefKey );
    isItemPresent = index > -1 ? true: false;
    return isItemPresent;
  }

  rearrageToDropdownOptionFormat(listItems: any[], labelKey: string, valKey: string): DropdownOption[] {
    let dropdownList: DropdownOption[] = [];
    listItems.forEach(
      (item: any) => {
        let formattedItem: DropdownOption = {
          label: item[labelKey],
          value: item[valKey],
        };
        dropdownList.push(formattedItem);
      }
    );
    return dropdownList;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Converts Local date to to UTC format
   */
  public convertToUTCFormat(localDate: string, isDateOnly: boolean = false): any {
    let utcDate;
    let resultDate;
    if (localDate) {
      let jsDate = new Date(localDate);
      utcDate = new Date(localDate).toISOString();
      if (isDateOnly) {
        utcDate = utcDate.split('T')[0];
        let dateVal = jsDate.getDate();
        let monthVal = jsDate.getMonth() + 1;
        let yearVal = jsDate.getFullYear();
        resultDate = `${yearVal}-${monthVal}-${dateVal}`
      }
    }
    return resultDate;
  }

  convertToMilliSeconds(localDate: any): number {
    let retVal;
    retVal = localDate.getTime();
    return retVal;
  }

  constructFullName(userData: any): string {
    const firstName = userData.fname ? userData.fname : '';
    const middleName = userData.mname ? userData.mname : '';
    const lastName = userData.lname ? userData.lname : '';
    let fullName = `${firstName} ${middleName} ${lastName}`;
    return fullName;
  }

  assessFliterChange(filterResult: TableFilterChangeResult, queryParams: any) {
    queryParams.page = '1';
    if (filterResult.type == TableConfigConstants.FILTER_TYPES.multiSelect) {
      queryParams[filterResult.filterId] = this.extractMuliSelectKey(filterResult.selectedOption);
    } else if (filterResult.type == TableConfigConstants.FILTER_TYPES.dateOnly) {
      queryParams[filterResult.filterId] = this.convertToMilliSeconds(filterResult.selectedOption);
    } else {
      queryParams[filterResult.filterId] = filterResult.selectedOption;
    }
    return queryParams;
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Consolidates the id value of multiselect filters
   */
  extractMuliSelectKey(optionsValue: any[], keyRef?: string): any {
    let retVal = '';
    let key = 'key';
    if (keyRef) {
      key = keyRef;
    }
    let result = '';
    optionsValue.forEach((item: any, index: number) => {
       if (index == (optionsValue.length - 1)){
        result = `${result}${item[key]}`;
      } else {
        result = `${result}${item[key]},`;
      }
        retVal = result;
    });
    return retVal;
  }

  initialiseTableFilters(filtersList: TableFilter[]): TableFilter[] {
    filtersList.forEach((item: TableFilter) => item.selectedOption = item.defaultValue);
    filtersList = JSON.parse(JSON.stringify(filtersList));
    return filtersList;
  }

  resetFilterParams(queryParams: any, resetKeysList: string[]): any {
    Object.keys(queryParams).forEach(function(key) {
      const resIndex = resetKeysList.findIndex((item: string) => item == key);
      if (resIndex != -1) {
        queryParams[key] = '';
      }
    });
    return queryParams;
  }

}
