import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetCalendarDates, GetSchedulesResponse, GetSessionResponse, GetSessionResponseData, RecordResponse, SessionData } from '@models/appointments';
import { ApiUrls } from '@utilities/api-urls';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  sessionDataSubject = new Subject<SessionData>();
  sessionData = this.sessionDataSubject.asObservable();

  constructor(private http: HttpClient) { }

  triggerSessionData(sessionData: SessionData) {
    this.sessionDataSubject.next(sessionData);
  }

  getListScheduleCalender(startdate, endDate): Observable<Array<number>> {
    const apiUrl = ApiUrls.APPOINTMENTS_SERVICE.getCalendarAppointments;
    return this.http.get(apiUrl + '?start_date=' + startdate + '&end_date=' + endDate, {}).pipe(
      map((response: GetCalendarDates) => {
        return response.data;
      })
    );
  }


  getListAppointments(date, page): Observable<GetSchedulesResponse['data']> {
    const apiUrl = ApiUrls.APPOINTMENTS_SERVICE.getSchedules;
    return this.http.get(apiUrl + '?date=' + date + '&page=' + page).pipe(
      map((response: GetSchedulesResponse)  => {
        return response.data;
      })
    );
  }

  getSession(id): Observable<GetSessionResponse> {
    const apiUrl = ApiUrls.APPOINTMENTS_SERVICE.getSession;
    return this.http.get(apiUrl + id + '/get').pipe(
      map((response: GetSessionResponse) => {
        return response
      })
    )
  }

  cancelAppointment(id): Observable<any> {
    const apiUrl = ApiUrls.APPOINTMENTS_SERVICE.cancelSession;
    const reqData = {
      status: 'cancelled'
    }
    return this.http.post(apiUrl + id + '/cancel', reqData).pipe(
      map(response => {
        return response
      })
    )
  }

  sessionStart(id, dataToSend): Observable<any> {
    const apiUrl = ApiUrls.SESSION_SERVICE.start;

    return (
      this.http.put(apiUrl + id + '/start', dataToSend).pipe(
        map(
          (response) => {
            return response;
          }
        )
      )
    );
  }

  startRecording(sessionId, audio, video, appointmentId): Observable<RecordResponse> {
    const apiUrl = ApiUrls.SESSION_SERVICE.startRecord;

    return(
      this.http.get(apiUrl + sessionId + '/' + audio + '/' + video + '/' + appointmentId + '/start').pipe(
        map(
          (response: RecordResponse) => {
            return response;
          }
        )
      )
    )
  }


  stopRecording(archiveId): Observable<RecordResponse> {
    const apiUrl = ApiUrls.SESSION_SERVICE.startRecord;

    return(
      this.http.get(apiUrl + archiveId + '/stop').pipe(
        map(
          (response: RecordResponse) => {
            return response;
          }
        )
      )
    )
  }

  addParticipant(dataToSend, telemedicine_id) {
    const apiUrl = ApiUrls.SESSION_SERVICE.addParticipant;

    return(
      this.http.post(apiUrl + telemedicine_id + '/participant', dataToSend).pipe(
        map(
          (response) => {
            return response;
          }
        )
      )
    )
  }

}
