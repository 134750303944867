import { domain } from 'process';

export interface ActivityMatrixKeys {
    domain_list: ActivityDomain[];
    sensor_list: ActivitySensor[];
}
export interface ActivitySensor {
    id: any; 
    _id: string;
    is_deleted: boolean; 
    sensorName: string;
    value?: string;
    label?: string;
    key?: string;
}
export interface ActivityDomain {
    id: any; 
    _id: string;
    key?: string;
    value?: string;
    label?: string;
    is_deleted?: boolean; 
    domainName: string;

}
export interface ActivityImpact {
    sensor: string;
    domain: string;
}
export interface SensorStatus {
    id: string;
    status: boolean;
    sensorName: string;
    isPrimary: boolean;
}
export interface FetchActivityMatrixResponse {
    code: number;
    status: boolean;
    message: string;
    data: ActivityMatrixKeys;
}
export interface ActivityActions  {
    edit: boolean;
    view: boolean;
    delete: boolean;
}
export interface SensorCoordinateObj {
    sensorId: string;
    sensorName: string;
}
export interface ActivityMatrixInfo {
    sensorCoordinateY: SensorCoordinateObj;
    domainCoordinateX: any;
}
export interface MatrixDomainObj {
    domainId: string;
    domainName: string;
    is_primary: boolean;
    is_secondary: boolean;
    description: string;  
}
export interface FinalMatrixData {
    primary_impact: DomainSensorCombinedInfo;
    secondary_impact: DomainSensorCombinedInfo[];
}
export interface DomainSensorCombinedInfo {
    domain: any;
    sensor: any;
}

//------------------- API REQUESTS -------------------
export interface MatrixCoordinatesHttpResponse {
    data: {
        domains: ActivityDomain[];
        sensors: ActivitySensor[]; 
    }
    code: number;
    status: boolean;
    message: string;
}

// ------------------ CLASSES ---------------
export class ActivityMatrixManager {

    static constructActivityMatrixInfo(
        domainList: ActivityDomain[], 
        sensorList: ActivitySensor[],
        incomingMatrix: FinalMatrixData = null): ActivityMatrixInfo[] {
        let activityMatrix: ActivityMatrixInfo[] = [];
        activityMatrix = sensorList.map(
            (sensor: ActivitySensor) => {
                let matrixObj: ActivityMatrixInfo = { 
                    sensorCoordinateY: { sensorId: '', sensorName: '' },
                    domainCoordinateX: {}
                };
                matrixObj.sensorCoordinateY = {
                    sensorId: sensor.id,
                    sensorName: sensor.sensorName
                };
                matrixObj.domainCoordinateX = domainList.reduce(
                    (domainGroups: any, domain: ActivityDomain) => { 
                        // Initializes object if it doesn't exist
                        domainGroups[domain.domainName.toLowerCase()] = 
                        (domainGroups[domain.domainName.toLowerCase()] || 
                            this.constructMatrixDomainObj(domain, sensor, incomingMatrix)
                        );
                        return domainGroups;
                    }, {}
                );
                return matrixObj;
            });
        return activityMatrix;
    }
    static constructMatrixDomainObj(domain: ActivityDomain, sensor: ActivitySensor, incomingMatrix: FinalMatrixData = null): MatrixDomainObj {
        let matrixDomainObj: MatrixDomainObj;
        let primaryStatus = false;
        let secondaryStatus = false;
        if (incomingMatrix) {
            primaryStatus = ActivityMatrixManager.checkIfPrimary(domain, sensor, incomingMatrix.primary_impact);
            secondaryStatus = ActivityMatrixManager.checkIfSecondary(domain, sensor, incomingMatrix.secondary_impact);
        }
        matrixDomainObj = {
            domainId: domain.id,
            domainName: domain.domainName,
            is_primary: primaryStatus,
            is_secondary: secondaryStatus,
            description: `${sensor.sensorName} - ${domain.domainName}  Experience`,  
        }
        return matrixDomainObj;
    }
    static constructSecondaryImpactInfo(matrixList: ActivityMatrixInfo[]): DomainSensorCombinedInfo[] {
        let secondaryImpactVal: DomainSensorCombinedInfo[] = [];
        secondaryImpactVal = matrixList.reduce(
        (accInfo: any, matrixObj: ActivityMatrixInfo) => {
            for (let domainObj in matrixObj.domainCoordinateX) {
                if (matrixObj.domainCoordinateX[domainObj].is_secondary) {
                    accInfo.push(ActivityMatrixManager.constructDomainSensorCompo(
                        matrixObj.sensorCoordinateY, 
                        matrixObj.domainCoordinateX[domainObj]));
                }
            }
            return accInfo; 
        }, []);
        return secondaryImpactVal; 
    }
    static constructDomainSensorCompo(sensor: any, domain: any): DomainSensorCombinedInfo {
        const newEntry: DomainSensorCombinedInfo = { 
            domain: { _id: domain.domainId, id: domain.domainId, domainName: domain.domainName}, 
            sensor: { _id: sensor.sensorId, id: sensor.sensorId, sensorName: sensor.sensorName},
        };
        return newEntry;
    }
    static checkIfPrimary(domain: ActivityDomain, sensor: ActivitySensor, primary_impact: DomainSensorCombinedInfo): boolean {
        let isPrimary = false;
        if (primary_impact.domain._id ==  domain._id && primary_impact.sensor._id ==  sensor._id) {
            isPrimary = true;
        }
        return isPrimary; 
    }
    static checkIfSecondary(domain: ActivityDomain, sensor: ActivitySensor, sec_impact: DomainSensorCombinedInfo[]): boolean {
        let isSecondary = false;
        const matchObj = sec_impact.findIndex((item) => {
            return ( item.domain._id == domain._id && item.sensor._id == sensor._id);
        });
        if (matchObj != -1) {
            isSecondary = true;
        }
        return isSecondary; 
    }

}
export class ActivityManager {
    static createActivityAction(event?: ActivityActions): ActivityActions {
        let formatedAction;
        if (event) {
            formatedAction = {
                edit: event.edit ? event.edit : true,
                view: event.view ? event.view : true,
                delete: event.delete ? event.delete : true,
            };
        } else {
            formatedAction = {
                edit: true,
                view: true,
                delete: true,
            };
        }
        return formatedAction;
    }
}

