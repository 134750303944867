export class MenuKeys {

    /**
   * Author: T0512 Krupa Tresa Joseph
   */
    public static MENU_KEYS = {
        // Clients Management
        clients_list: {
            key: 'CLIENT_LISTING_VIEW',
            url: 'clients',
            isListedOnMenu: true,
        },
        add_client: {
            url: '',
            key: 'CLIENT_USER_ADD',
            isListedOnMenu: false,
        },
        // Care providers Management
        careproviders_list: {
            key: 'CARE_PROVIDER_LIST',
            url: 'care-providers',
            isListedOnMenu: true,
        },
        add_careprovider: {
            key: 'CARE_PROVIDER_ADD',
            url: 'care-providers',
            isListedOnMenu: true,
        },
        care_providers_view: {
            key: 'CARE_PROVIDER_VIEW',
            url: 'care-providers',
            isListedOnMenu: true,
        },

        // Invoices Management
        invoices_list: {
            key: 'INVOICES_LIST',
            url: 'invoices',
            isListedOnMenu: true,
        },
        invoices_add: {
            key: 'INVOICES_ADD',
            url: 'invoices',
            isListedOnMenu: true,
        },
        invoices_edit: {
            key: 'INVOICES_EDIT',
            url: 'invoices',
            isListedOnMenu: true,
        },

        // Reports Management
        reports_view: {
            key: 'REPORTS_VIEW',
            url: 'invoices',
            isListedOnMenu: true,
        },
        reports_add: {
            key: 'REPORTS_ADD',
            url: 'invoices',
            isListedOnMenu: true,
        },
        reports_edit: {
            key: 'REPORTS_EDIT',
            url: 'invoices',
            isListedOnMenu: true,
        },


        // ----- CLIENT PROFILE FEATURES -----
        // CSA FORM
        view_csa: {
            url: '',
            key: 'CSA_FORM_VIEW',
            isListedOnMenu: false,
        },
        edit_csa: {
            url: '',
            key: 'CSA_FORM_EDIT',
            isListedOnMenu: false,
        },
        view_csa_invoice: {
            url: '',
            key: 'CSA_SUBMISSION_INVOICE_VIEW',
            isListedOnMenu: false,
        },
        submit_csa_invoice: {
            url: '',
            key: 'CSA_SUBMISSION_INVOICE_EDIT',
            isListedOnMenu: false,
        },
        // ASSIGN CARE PROVIDER
        assign_care_provider: {
            url: '',
            key: 'ASSIGN_CARE_PROVIDER',
            isListedOnMenu: false,
        },

        // VIEW ASSIGNED CARE PROVIDER
        care_provider_assignment_view: {
            url: '',
            key: 'CARE_PROVIDER_ASSIGNMENT_VIEW',
            isListedOnMenu: false,
        },
        // INITIAL ASSESSMENT
        view_initial_assessment: {
            url: '',
            key: 'INITIAL_ASSESSMENT_VIEW',
            isListedOnMenu: false,
        },
        edit_initial_assessment: {
            url: '',
            key: 'INITIAL_ASSESSMENT_EDIT',
            isListedOnMenu: false,
        },
        create_initial_assessment: {
            url: '',
            key: 'INITIAL_ASSESSMENT_CREATE',
            isListedOnMenu: false,
        },
        // TREATMENT PLAN
        edit_treatment_plan: {
            url: '',
            key: 'TREATMENT_PLAN_EDIT',
            isListedOnMenu: false,
        },
        create_treatment_plan: {
            url: '',
            key: 'TREATMENT_PLAN_CREATION',
            isListedOnMenu: false,
        },
        submit_treatment_plan: {
            url: '',
            key: 'TREATMENT_PLAN_SUB_CLIENT',
            isListedOnMenu: false,
        },
        // DIAGNOSIS
        edit_diagnosis: {
            url: '',
            key: 'DIAGNOSIS_EDIT',
            isListedOnMenu: false,
        },
        create_diagnosis: {
            url: '',
            key: 'DIAGNOSIS_CREATE',
            isListedOnMenu: false,
        },
        view_diagnosis: {
            url: '',
            key: 'DIAGNOSIS_VIEW',
            isListedOnMenu: false,
        },
        // ACTIVITIES
        activities_list: {
            url: 'treatment-activities',
            key: 'ACTIVITIES_VIEW',
            isListedOnMenu: true,
        },
        activities_add: {
            url: '',
            key: 'ACTIVITIES_CREATION ',
            isListedOnMenu: true,
        },
        activities_edit: {
            url: '',
            key: 'ACTIVITIES_CREATION ',
            isListedOnMenu: true,
        },
        // APPOINTMENTS
        appointments_participation: {
            url: 'appointments',
            key: 'APPOINTMENT_PARTICIPATION',
            isListedOnMenu: true,
        },
        appointments_scheduling: {
            url: 'appointments',
            key: 'APPOINTMENT_SCHEDULING',
            isListedOnMenu: false,
        },

         // VITALS

       vital_log: {
            url: '',
            key: 'LOG_VITALS',
            isListedOnMenu: false,
        },

        //Patient Profile Edit
        client_edit: {
            url: '',
            key: 'CLIENT_PROFILE_EDIT',
            isListedOnMenu: false,
        },
    };

}
