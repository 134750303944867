import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {

  // Incoming variables
  public title: string;
  public message: string;
  public confirmBtnText: string;

  // Subjects
  onCancelProcess = new Subject();
  onConfirmProcess = new Subject();


  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() { }

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Triggers the confirm process subject
  */
  onConfirm() {
    this.onConfirmProcess.next(true);
    this.bsModalRef.hide();
  }

  /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: Logout user from the application
*/
  onCancel() {
    this.onCancelProcess.next(true);
    this.bsModalRef.hide();
  }

}
