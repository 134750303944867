import { Subject, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { LoggedUserInfo } from '@models/logged-user';
import { SettingsResponse } from '@models/settings';

import { AuthService } from '@services/auth/auth.service';
import { TimezoneService } from '@services/timezone/timezone.service';

import { AppConstants } from '@utilities/app-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  pageTitle: string;
  timeZoneData: any;
  selectedTimeZone: string;

  userData: LoggedUserInfo;

  settingsForm: FormGroup;

  userInfoSubscription$ = new Subscription();

  // Subjects
  onCancelProcess = new Subject();
  onConfirmProcess = new Subject();

  isTimeZoneChanged: boolean;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private utilityService: UtilityService,
    private timezoneService: TimezoneService,
    private settingsService: SettingsService,
  ) {
    this.timeZoneData = {
      orgTimeZone: false
    };
    this.pageTitle = AppConstants.pageTitles.settings;
    this.isTimeZoneChanged = false;
  }

  ngOnInit(): void {
    this.initialiseSettingsForm();
    this.selectedTimeZone = this.timezoneService.getUserTimeZone();
    this.subscribeToUserInfo();
  }

  subscribeToUserInfo() {
    this.userInfoSubscription$ = this.authService.currentUser.subscribe(
      (userData: LoggedUserInfo) => {
        this.userData = userData;
        this.setTimeZoneData();
        this.patchTimeZoneValue();
      }
    );
  }

  initialiseSettingsForm() {
    this.settingsForm = this.fb.group({
      orgTimeZone: [false, []],
    });
  }

  setTimeZoneData() {
    if (this.userData) {
      this.timeZoneData.orgTimeZone = this.userData.timezone.toUpperCase() === AppConstants.timeZoneList.deviceTimeZone ? false : true;
    }
  }

  patchTimeZoneValue() {
    this.settingsForm.patchValue({
      orgTimeZone: this.timeZoneData.orgTimeZone
    });
  }

  updateTimeZone(){
    const SettingsData: any = this.settingsForm.value;
    let requestData = {
      timezone: SettingsData.orgTimeZone ? AppConstants.timeZoneList.orgTimeZone : AppConstants.timeZoneList.deviceTimeZone
    };
    this.settingsService.updateTimeZone(requestData).subscribe(
      (response: SettingsResponse) => {
        if (response.status) {
            this.isTimeZoneChanged = true;
            this.utilityService.showResponseSuccess(ToastrMessages.SETTINGS.timeZoneSetSuccess, response.message);
        } else {
          this.utilityService.showResponseError(ToastrMessages.SETTINGS.timeZoneSetSFail, response.message);
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.utilityService.showResponseError(ToastrMessages.SETTINGS.timeZoneSetSFail, error.error.message );
        }
    }
    );
  }

  onTimeZoneChange() {
    if (!this.settingsForm.value.orgTimeZone) {
      this.selectedTimeZone = this.timezoneService.getDeviceTimeZone();
    } else {
      this.selectedTimeZone = this.timezoneService.getOrganisationTimeZone();
    }
  }

  onSubmitForm() {
    this.updateTimeZone();
    this.bsModalRef.hide();
    if(this.isTimeZoneChanged){
      window.location.reload();
    }
  }

  onCancel() {
    this.onCancelProcess.next(true);
    this.bsModalRef.hide();
  }

}
