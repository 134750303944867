<div [ngClass]="{ 'Video-Consulting-Minimize': minimizeVideoCallLayout }"
  class="Video-Consulting-Container Video-Section">
  <!--=============Video Consulting Header Start=============-->
  <div class="VSC-Header">
    <div class="row">
      <div class="VSC-Header-70">
        <div class="VSC-Header-call-duration">
          <span>Duration: </span>
          {{duration}}
        </div>
      </div>

      <div class="VSC-Header-30">
        <div class="VSC-Header-call-action">
          <div class="VSC-HCA-Icons VSC-HCA-Icons-min-mob">
            <a (click)="maximizeVideoCallScreen()">
              <img src="assets/images/video-green.svg">
            </a>
          </div>
          <div class="VSC-HCA-Icons VSC-HCA-Icons-Maximize">
            <img src="assets/images/maximize.svg" (click)="maximizeVideoCallScreen()">
          </div>
          <div class="VSC-HCA-Icons VSC-HCA-Icons-Minimize">
            <img src="assets/images/minimize.svg" (click)="minimizeVideoCallScreen()">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="VSC-Header-seperation"></div>
  <!--=============Video Consulting Header End=============-->
  <div [ngClass]="{ 'ParticipantsActive': showAddParticipantScreen, 'MessagingActive': showMessagingScreen }"
    class="VSC-Body-Section MessagingActive p-0 row">

    <!--=============Participants Start=============-->
    <div class="VSC-BS-Participants-Section col-sm-3">
      <div class="VSC-BS-PS-Header">Participants
      </div>
      <div class="VSC-BS-PS-Parcicipants-Container">
        <span *ngIf="!participants" class="text-white">No participants</span>
        <div id="subscribers" class="VSC-BS-PS-Parcicipants"></div>
      </div>
    </div>
    <!--=============Participants End=============-->

    <!--=============Video Stream Start=============-->
    <div class="VSC-BS-Video-Stream-Section col-sm-9 SidebarActive">

      <!--=============Video Stream Publisher Thumb Start=============-->
      <div id="publisher" class="Publisherthumb"></div>
      <!--=============Video Stream Publisher Thumb End=============-->

      <div class="VSC-BS-SS-Streaming-Container">
        <div class="recording-indicator" *ngIf="isRecording">
          <img src="assets/images/record-1.svg">
          <p>Recording</p>
        </div>

        <div class="video-full-screen"
          style="background: url('src/assets/images/no-video-bg.svg') no-repeat center;background-color: #1e2536;">
        </div>
        <div class="VSC-BS-SS-Streaming-Options">
          <!--Video Caller Name-->
          <div class="VSC-BS-SS-Streaming-Caller-Name">{{ userData.name }}</div>
          <!--Video Caller Options-->
          <div class="VSC-BS-SS-Streaming-Video_Options">
            <div class="VSC-BS-SS-STVO-Icons" (click)="togglePublisherAudio()">
              <img *ngIf="publisherAudio" title="Mute" src="assets/images/mic_active.svg">
              <img *ngIf="!publisherAudio" title="Unmute" src="assets/images/mic.svg">
            </div>
            <div class="VSC-BS-SS-STVO-Icons" (click)="togglePublisherVideo()">
              <img *ngIf="publisherVideo" title="Video" src="assets/images/video_active.svg">
              <img *ngIf="!publisherVideo" title="Video" src="assets/images/video.svg">
            </div>
            <div class="VSC-BS-SS-STVO-Icons" *ngIf="isOrganiser">
              <a (click)="recordingControl()" href="JavaScript:void(0);">
                <img title="Record" [src]="isRecording? 'assets/images/stop.svg' : 'assets/images/record-1.svg'">
              </a>
            </div>
            <div class="VSC-BS-SS-STVO-Icons">
              <a (click)="closeVideoCallScreen()">
                <img title="End call" src="assets/images/call.svg">
              </a>
            </div>
            <div class="VSC-BS-SS-STVO-Icons">
              <a (click)="showMessaging()" href="JavaScript:void(0);">
                <img title="Messaging" src="assets/images/chat.svg">
                <div class="VSC-BS-SS-STVO-Icons-Badge" *ngIf="unreadMessages && !showMessagingScreen">
                  {{ unreadMessages }}</div>
              </a>
            </div>
            <div class="VSC-BS-SS-STVO-Icons">
              <a (click)="addParticipant()" href="JavaScript:void(0);">
                <img title="Add participants" src="assets/images/add_participants.svg">
              </a>
            </div>
            <div class="VSC-BS-SS-STVO-Icons">
              <a (click)="screenSharing()" href="JavaScript:void(0);">
                <img title="Screen Sharing" src="assets/images/screen-share.svg">
              </a>
            </div>
            <!-- <div id="publisher"></div>
            <div id="screen-preview"></div> -->
          </div>
        </div>
      </div>
    </div>
    <!--=============Video Stream End=============-->


    <!--=============Add Participant Start=============-->
    <div class="VSC-BS-Add-Participants-Section col-sm-4">
      <div class="VSC-BS-Add-PS-Header">
        <img src="assets/images/add_participants.svg" class="pr-2"> Add Care Provider Participants
        <div class="VSC-BS-Add-PS-close">
          <a (click)="hideParticipant()">
            <img src="assets/images/close.svg">
          </a>
        </div>
      </div>
      <form [formGroup]="addParticipantForm" (ngSubmit)="onAddParticipant()">
      <div class="VSC-BS-Add-PS-Body">
        <div class="row mb-3 VSC-BS-Add-PS-B-Multiselect">
          <div class="col-md-12">
            <div class="form-group">
              <label _ngcontent-c14="" class="block">Select Participants</label>
              <p-autoComplete [suggestions]="participantsList" field="text"
                (completeMethod)="searchParticipants($event)" formControlName="participants"
                [emptyMessage]="participantsList.length == 0 ? 'No participants found' : ''" placeholder="Type Here..."
                [forceSelection]="true" minLength="3" [multiple]="true">
              </p-autoComplete>
              <!-- <select2 (valueChanged)="changed($event)" [data]="careProviders" [options]="select2Option"></select2> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right VSC-BS-Add-PS-B-Button">
            <button class="btn btn-primary" type="submit">Add</button>
          </div>
        </div>
      </div>
    </form>
    </div>
    <!--=============Add Participant End=============-->


    <!--=============Messaging Start=============-->
    <div class="VSC-BS-Messaging-Section col-sm-4">
      <div class="VSC-BS-Add-MS-Header">
        <img src="assets/images/chat-green.svg" class="pr-2"> Messaging
        <div class="VSC-BS-Add-MS-close">
          <a (click)="hideMessaging();">
            <img src="assets/images/close.svg">
          </a>
        </div>
      </div>
      <div class="VSC-BS-Add-MS-Body">
        <div class="row m-0 px-2">
          <div class="col-md-12 VSC-BS-Add-MS-B-Chat-Container">
            <div class="row m-0 ">
              <p style="width:100%" *ngFor="let item of participantList">
                <span>{{ item.name }}</span>
                has joined the call
              </p>
              <!--=============Chatting Messages Repeated Container=============-->
              <div class="col-md-12 p-0" *ngFor="let item of messages">
                <div class="VSC-BS-Add-MS-B-CC-Section"
                  [ngClass]="{ 'Current-User': publisherConnection.id === item.id  }"
                  [ngStyle]="{'border-color': item.color}" style="border-left: 5px solid">
                  <div class="VSC-BS-Add-MS-B-CC-S-Header">
                    <div class="VSC-BS-Add-MS-B-CC-S-H-Left">{{ item.name }}</div>
                    <div class="VSC-BS-Add-MS-B-CC-S-H-Right">{{ item.time }}</div>
                  </div>
                  <div class="VSC-BS-Add-MS-B-CC-S-Message">{{ item.message }}</div>
                </div>
              </div>
              <span id="message-input-div">&nbsp;</span>
            </div>
          </div>
        </div>
        <div class="VSC-BS-Add-MS-B-CC-S-Footer">
          <input (keydown)="keyPressed($event)" [(ngModel)]="textMessage" type="text" class="chat-form-control"
            placeholder="Type your message here">
          <img (click)="sendMessage()" src="assets/images/send.svg" class="VSC-BS-Add-MS-B-CC-S-f-Send">
        </div>
      </div>
    </div>
    <!--=============Messaging End=============-->

  </div>
</div>
