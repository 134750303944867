<div class="wrapper">
    <div class="sub-wrap">
        <!-- ICON IDENTIFIERS -->
        <div 
            *ngIf="tableController?.iconIdentifiers?.enable"
            class="icon-identifiers-wrap">
            <div 
                class="icon-identifier"
                *ngFor="let icon of tableController?.iconIdentifiers?.icons">
                <i 
                    class="control-icon colored fa"
                    [ngClass]="icon?.iconClass">
                </i>
                <span class="name text-uppercase">{{icon?.iconLabel}}</span>
            </div>
        </div>
    </div>
    <div class="sub-wrap">
        <!-- SEARCH BAR -->
        <div
            *ngIf="tableController?.searchBar?.enable" 
            style="text-align: right"
            class="search-bar">        
            <input
                pInputText 
                size="55" 
                type="text"  
                style="width:auto"
                class="form-control search-input"
                [formControl]="searchText"
                [maxLength]="tableController?.searchBar?.maxLength"
                [placeholder]="tableController?.searchBar?.placeholder"
                style="width:auto">
                <i class="fa fa-search"></i>
        </div>
        <!-- FILTER -->
        <!-- <div
            style="text-align: right"   
            *ngIf="tableController?.filter?.enable">
                <button 
                    class="btn submit-btn iconized"
                    (click)="onFilterClick()">Filters<i class="fa fa-filter light-icon"></i>
                </button>
        </div> -->
    </div>
</div>

<!-- FILTER WRAPPER -->
<!-- <div 
    class="wrapper"
    *ngIf="tableController?.filter?.enable">
    <app-table-filters
        [filters]="tableController?.filter?.filters" 
        [showFilterClearBtn]="tableController?.filter?.showFilterClearBtn"
        (filterChange)="filterChange($event)">
    </app-table-filters>
</div> -->
