import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientsListQueryParams, ClientsListResponse } from '@models/clients';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { ApiUrls } from '@utilities/api-urls';
import { AppConstants } from '@utilities/app-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientUpdateProfileInfo, PatientProfileResponse } from '@models/client-profile';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  activePatientId: any;

  constructor(
    public http: HttpClient,
    public utilityService: UtilityService,
    public sessionStorageService: SessionStorageService
  ) { }

  // ---------------- HTTP REQUESTS METHODS ---------------
  fetchClientsList(queryParams: any): Observable<ClientsListResponse> {
    const apiUrl = `${ApiUrls.CLIENTS.fetchList}`;
    const paramsObj = {
      params: queryParams
    };
    return this.http.get(apiUrl, paramsObj).pipe(
      map (
        (response: any) => {
          if (!response.status) {
            this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.listFail, response.message);
          }
          return response;
        },
        (error: HttpErrorResponse) => {
            this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.listFail, error.error.message );
        },
      ),
    );
  }

  fetchClientProfile(patientId): Observable<any> {
    this.activePatientId = patientId;
    const apiUrl = `${ApiUrls.CLIENTS.patientProfile}/${patientId}`;
    return this.http.get(apiUrl).pipe(
      map (
        (response: any) => {
          if (!response.status) {
            this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.listFail, response.message);
          }
          return response;
        },
        (error: HttpErrorResponse) => {
            this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.listFail, error.error.message );
        },
      ),
    );
  }

    /**
   * Author: IBIL GLOBAL
   * Description: Invoke API to update profile details
   */
  updateProfile(requestData: PatientUpdateProfileInfo): Observable<PatientProfileResponse> {
    const apiUrl = ApiUrls.CLIENTS.patientProfileUpdate;
    return this.http.post(apiUrl, requestData).pipe(
      map (
        (response: PatientProfileResponse) => {
        //  this.csaService.updateUserOverallInfo();
          return response;
        },
        (error: HttpErrorResponse) => {
          this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.profileUpdateFail, error.error.message );
      },
      ),
    );
  }

  downloadFile(clientId: any, file: string): Observable<any> {
    let apiUrl: any;
    if(file === 'CSA'){
      apiUrl = `${ApiUrls.CSA_FORM.downloadCSAFinal}/${clientId}/get`;
    }
    else if(file === 'IE'){
      apiUrl = `${ApiUrls.INITIAL_EVALUATION.downloadInitialEvaluation}/${clientId}`;
    }

    return this.http.get(apiUrl, {responseType: 'arraybuffer'}).pipe(
      map (
        (response: any) => {
          return response;
        },
        (error: HttpErrorResponse) => {},
      ),
    );
  }

  fetchActivityLogs(id: any): Observable<any> {
    const apiUrl = `${ApiUrls.ACTIVITY_LOGS.activityLog}?id=${id}`;
    return this.http.get(apiUrl).pipe(
      map (
        (response: any) => {
          if (!response.status) {
            this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.listFail, response.message);
          }
          return response;
        },
        (error: HttpErrorResponse) => {
            this.utilityService.showResponseError(ToastrMessages.CLIENTS_LIST.listFail, error.error.message );
        },
      ),
    );
  }

}
