import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertMsg } from '@models/alert-msgs';
import { AddClientRequest } from '@models/appointments';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ToastrMessages } from '@utilities/toastr-messages';
import { AddClientService } from './add-client.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  @Input() showAddClient: boolean;
  @Output() hideAddClient = new EventEmitter();

  dataToSend: AddClientRequest;
  generalValidations: any;

  addClientForm: FormGroup;
  isFormSubmitted: boolean;
  errMsgs: any;


  constructor(
    private fb: FormBuilder,
    private addClientService: AddClientService,
    private alertMessageService: AlertMsgService
  ) { }

  ngOnInit(): void {
    this.generalValidations = InputFieldLimits.generalValidations;
    this.addClientForm = this.fb.group({
      fname: ['', [Validators.required]],
      mname: ['', []],
      lname: ['', [Validators.required]],
      emailID: ['', [Validators.required, Validators.pattern(this.generalValidations.emailRegx)]],
      phone: ['', [Validators.required]],
      dhs: [true, Validators.required]
    });
    this.isFormSubmitted = false;
    this.errMsgs = ToastrMessages.FORM_ERROR_MSGS;

    this.dataToSend = {
      emailID: '',
      fname: '',
      mname: '',
      lname: '',
      phone: 0,
      dhs: true,
    }
  }

  /**
 * Author: Gokul
 * Description: API call if form is valid
 */
  onCreateAccount() {
    this.isFormSubmitted = true;
    if (this.addClientForm.valid) {
      const formValue = this.addClientForm.value;
      this.dataToSend.emailID = formValue['emailID'];
      this.dataToSend.fname = formValue['fname'];
      this.dataToSend.mname = formValue['mname'];
      this.dataToSend.lname = formValue['lname'];
      this.dataToSend.phone = formValue['phone'];
      this.dataToSend.dhs = (formValue['dhs'] === "false" || formValue['dhs'] === false)?false:true;
      this.addClientService.postAddClient(this.dataToSend).subscribe(
        result => {
          const alert: AlertMsg = { msg: result.message, title: 'Success' };
          this.alertMessageService.showSuccessToster(alert);
          this.hideAddClient.emit(false);
        }
      )
    }
  }

  onCancel() {
    this.hideAddClient.emit(false);
  }
}
