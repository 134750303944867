import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUploadResponse } from '@models/file-upload';
import { ApiUrls } from '@utilities/api-urls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private httpClient: HttpClient
  ) { }
  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: The HTTP post method for sending data as 'FormData', mainly used for file upload purpose
   * @param FormData
   */
  public uploadFileData(fileBody, featureApiUrl: string, uploadParams): Observable<any> {
    let apiUrl = ApiUrls.COMMON_SERVICE.fileUpload;
    if (featureApiUrl) {
      apiUrl = featureApiUrl;
    }
    const header: any = {
      reference: uploadParams.reference,
      referenceid: uploadParams.referenceid,
      u_code: String(uploadParams.u_code),
    };
    return this.httpClient.post(apiUrl, fileBody, { headers: header}).pipe(
      map (
        (response: FileUploadResponse) => {
          return response;
        },
        (error) => {
          return error;
        }
      ),
    );
  }

  /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Creates a form data using the form values
   */
  public constructFormData<T>(formValue: T) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }
}
