<!-- MODAL HEADER -->
<div  class="modal-header">
    <h5 class="modal-title pull-left">{{title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close"(click)="onCancel()"><span aria-hidden="true">&times;</span></button>
</div>

<!-- MODAL BODY -->
<div class="modal-body main-con">

    <!-- BASIC INFO -->
    <form
        class="question-form"
        [formGroup]="activityDetailForm">
        <div class="section-wrap">

            <!-- Domain Sensor Activity -->
            <div class="row form-wrap"
                *ngIf="!isMasterMode && !disableFormFields">
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <label class="label modal-label">Domain</label>
                    <select class="form-control"
                        formControlName="domain"
                        (change)="manageMatrixValues()"
                        [attr.disabled]="(disableFormFields) ? '': null">
                        <option [value]="defaultValues?.dropdownDefault?.value">{{defaultValues?.dropdownDefault?.label}}</option>
                        <option *ngFor="let item of domainsList" [value]="item?.value">{{item?.label}}</option>
                    </select>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <label class="label modal-label">Sensor</label>
                    <select
                        class="form-control"
                        (change)="manageMatrixValues()"
                        formControlName="sensor"
                        [attr.disabled]="(disableFormFields) ? '': null">
                        <option [value]="defaultValues?.dropdownDefault?.value">{{defaultValues?.dropdownDefault?.label}}</option>
                        <option *ngFor="let item of sensorsList" [value]="item?.value">{{item?.label}}</option>
                    </select>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <label class="label modal-label">Master Activities</label>
                    <select class="form-control"
                        formControlName="activity_ref"
                        (change)="onMasterActivitySelection()"
                        [attr.disabled]="(disableFormFields) ? '': null">
                        <option [value]="defaultValues?.dropdownNullDefault?.value">{{defaultValues?.dropdownDefault?.label}}</option>
                        <option *ngFor="let item of masterActivityList"
                        [value]="item?.activity_details?._id">
                            {{item?.activity_details?.activity_name}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- Name -->
            <div class="row form-wrap">
                <!-- Activity Name -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label class="label modal-label">Activity Name</label>
                    <input
                        [readonly]="disableFormFields"
                        class="form-control"
                        formControlName="activity_name"/>
                    <!-- Error Messages -->
                    <div class="form-error-con"
                        *ngIf=" isFormSubmitted && activityDetailForm?.controls?.activity_name?.errors?.required &&
                        (isFormSubmitted || activityDetailForm?.dirty || activityDetailForm?.touched )">
                        <span class="err-msg"> {{errMsgs.mandarotyFields}} </span>
                    </div>
                </div>
                <!-- Duration -->
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label class="label modal-label">Duration</label>
                    <div class="multi-input-con">
                        <!-- Hours -->
                        <div class="input-grp-wrap">
                            <input
                                appNumberOnly
                                class="form-control"
                                [readonly]="disableFormFields"
                                formControlName="duration_hours"
                                [maxValue]="fieldValidations?.durationHoursMax"/>
                            <label class="label modal-label">Hours</label>
                            <!-- Error Messages -->
                            <div
                                class="form-error-con flex-error"
                                *ngIf="zeroDuration">
                                <span class="err-msg">{{errMsgs?.zeroDuration}}</span>
                            </div>
                        </div>
                        <!-- Minutes -->
                        <div class="input-grp-wrap">
                            <input
                                appNumberOnly
                                class="form-control"
                                [readonly]="disableFormFields"
                                formControlName="duration_min"
                                [maxValue]="fieldValidations?.durationMinutesMax"/>
                            <label class="label modal-label">Minutes</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Start Date & End Date -->
            <div class="row form-wrap">
                <!-- Start Date -->
                <div class="col-sm-12 col-md-3 col-lg-3" *ngIf="!isMasterMode">
                    <label class="label modal-label">Start Date</label>
                    <p-calendar
                        #myStartCalendar
                        formControlName="start_date"
                        [minDate]="currentDate"
                        [showTime]=calenderConfig?.showTime
                        [showIcon]="calenderConfig?.showIcon"
                        [yearRange]="calenderConfig?.yearRange"
                        [dateFormat]=calenderConfig?.dateFormat
                        [yearNavigator]="calenderConfig?.yearNavigator"
                        [showButtonBar]="calenderConfig?.showButtonBar"
                        [monthNavigator]="calenderConfig?.monthNavigator"
                        [readonlyInput]="disableFormFields">
                    </p-calendar>
                    <!-- Error Messages -->
                    <div
                        class="form-error-con"
                        *ngIf=" isFormSubmitted && activityDetailForm?.controls?.start_date?.errors?.required &&
                        (isFormSubmitted || activityDetailForm.dirty || activityDetailForm.touched )">
                        <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                    </div>
                </div>
                <!-- End Date -->
                <div class="col-sm-12 col-md-3 col-lg-3" *ngIf="!isMasterMode">
                    <label class="label modal-label">End Date</label>
                    <p-calendar
                        #myStartCalendar
                        formControlName="end_date"
                        [minDate]="currentDate"
                        [showTime]=calenderConfig?.showTime
                        [showIcon]="calenderConfig?.showIcon"
                        [yearRange]="calenderConfig?.yearRange"
                        [dateFormat]=calenderConfig?.dateFormat
                        [yearNavigator]="calenderConfig?.yearNavigator"
                        [showButtonBar]="calenderConfig?.showButtonBar"
                        [monthNavigator]="calenderConfig?.monthNavigator"
                        [readonlyInput]="disableFormFields">
                    </p-calendar>
                     <!-- Error Messages -->
                    <div
                        class="form-error-con"
                        *ngIf=" isFormSubmitted && activityDetailForm?.controls?.end_date?.errors?.required &&
                        (isFormSubmitted || activityDetailForm.dirty || activityDetailForm.touched )">
                        <span class="err-msg">{{errMsgs?.mandarotyFields}}</span>
                    </div>
                </div>
            </div>
            <!-- Recurrence and Interval -->
            <div class="row form-wrap" *ngIf="!isMasterMode">
                <!-- Recurrence Details -->
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <label class="label modal-label">Recurrence</label>
                    <div class="multi-input-con">
                        <!-- Interval -->
                        <div class="input-grp-wrap inline">
                            <select class="form-control"
                                formControlName="recurrence"
                                (change)="onRecurrenceTypeChange()"
                                [attr.disabled]="(disableFormFields) ? '': null">
                            <option [value]="defaultValues?.dropdownDefault?.value">{{defaultValues?.dropdownDefault?.label}}</option>
                            <option *ngFor="let item of recurrenceTypes" [value]="item?.value">{{item?.label}}</option>
                            </select>
                            <!-- Error Messages -->
                            <div class="form-error-con"
                                *ngIf=" isFormSubmitted && activityDetailForm?.controls?.recurrence?.errors?.required &&
                                (isFormSubmitted || activityDetailForm?.dirty || activityDetailForm?.touched )">
                                <span class="err-msg"> {{errMsgs.mandarotyFields}} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  Day Selection -->
                <div class="col-sm-12 col-md-8 col-lg-8"
                    *ngIf="activityDetailForm?.value?.recurrence == 'weekly'">
                    <div class="multi-box-wrap">
                        <div class="multi-checkbox-con"
                            *ngFor="let item of weekdaysList">
                            <p-checkbox
                                class="grp-checkbox"
                                name="weekday"
                                [value]="item?.value"
                                [label]="item.label"
                                [(ngModel)]="selectedWeekdays"
                                [ngModelOptions]="{standalone: true}">
                            </p-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Materials -->
            <div class="row form-wrap">
                <!-- Materials to be used -->
                <div class="col-sm-12 col-md-12 col-lg-12" formGroupName="materials">
                    <label class="label modal-label">Materials to be used</label>
                    <textarea
                        class="form-control"
                        formControlName="contents"
                        [readonly]="disableFormFields"
                        [maxLength]="fieldValidations?.materialsMax">
                    </textarea>
                    <!-- Error Messages -->
                    <div class="form-error-con"
                        *ngIf=" isFormSubmitted && activityDetailForm?.controls?.materials?.controls['contents']?.errors?.required &&
                        (isFormSubmitted || activityDetailForm?.dirty || activityDetailForm?.touched )">
                        <span class="err-msg"> {{errMsgs.mandarotyFields}} </span>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- MATRIX -->
    <div class="row form-wrap">
        <div class="col-12">
            <h5 class="modal-label label">ACTIVITY MATRIX</h5>
        </div>
        <div class="col-12">
            <div class="matrix-wrap">
                <!-- Header -->
                <div class="matrix-x-axis">
                    <div class="sec-wrap">
                        <div class="matrix-val">
                            <span class="value"></span>
                        </div>
                    </div>
                    <div class="sec-wrap"
                        *ngFor="let domain of domainsList">
                        <div class="matrix-val">
                            <span class="matrix-label value domain">{{domain?.domainName}}</span>
                        </div>
                    </div>
                </div>
                <!-- Body -->
                <div class="matrix-y-axis">
                    <div class="body-wrap"
                        *ngFor="let matrix of activityMatrix">
                        <!-- Sensor Info -->
                        <div class="block-wrap">
                            <!-- Sensor Name -->
                            <div class="sec-wrap">
                                <span class="matrix-label value sensor">{{matrix?.sensorCoordinateY?.sensorName}}</span>
                            </div>
                            <!-- Domain Data -->
                            <div class="sec-wrap tap-block"
                                [ngClass]="{
                                    'is-secondary': matrix?.domainCoordinateX[domain?.key]?.is_secondary,
                                    'is-primary': matrix?.domainCoordinateX[domain?.key]?.is_primary,
                                    'disabled': disableFormFields}"
                                *ngFor="let domain of domainsList">
                                <!-- Primary Checkbox -->
                                <p-checkbox
                                    *ngIf="!disableFormFields || matrix?.domainCoordinateX[domain?.key]?.is_primary && !isModalView"
                                    binary="true"
                                    [label]="'Primary'"
                                    class="custom-checkbox"
                                    [disabled]="disableFormFields"
                                    (onChange)="onPrimaryMatrixSelectionChange(matrix, domain)"
                                    [ngClass]="{'is-primary': matrix?.domainCoordinateX[domain?.key]?.is_primary}"
                                    [(ngModel)]="matrix.domainCoordinateX[domain.key].is_primary">
                                </p-checkbox>
                                <!-- Tab Box -->
                                <div class="tap-wrap"
                                    [ngClass]="{'disabled-tp': disableFormFields}"
                                    title="Click to manage secondary matrix"
                                    (click)="onMatrixClick(matrix?.domainCoordinateX[domain.key])">
                                    <span class="click-ele value">{{matrix?.domainCoordinateX[domain?.key]?.description}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- EXPECTATION & PROCEDURE -->
    <form
        class="question-form"
        [formGroup]="activityDetailForm">
        <div class="row form-wrap">
            <!-- Procedure -->
            <div class="col-sm-12 col-md-12 col-lg-12"
                formGroupName="procedure">
                <label class="label modal-label text-uppercase">Activity Procedure</label>
                <tinymce
                    *ngIf="viewMode?.key !== 'view'"
                    #reportEditor
                    formControlName="contents"
                    [config]="textEditorConfig"
                ></tinymce>
                <!--  View Mode -->
                <div class="template-view"
                    *ngIf="viewMode?.key == 'view'"
                    [innerHtml]="activityDetailForm?.value?.procedure?.contents">
                </div>
            </div>
            <!-- Expectation -->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <label class="label modal-label text-uppercase">What is expected from the activity</label>
                <tinymce
                    *ngIf="viewMode?.key !== 'view'"
                    #reportEditor
                    formControlName="expectation"
                    [config]="textEditorConfig"
                ></tinymce>
                <!--  View Mode -->
                <div class="template-view"
                    *ngIf="viewMode?.key == 'view'"
                    [innerHtml]="activityDetailForm?.value?.expectation">
                </div>
            </div>
        </div>
    </form>

</div>

<!-- MODAL FOOTER -->
<div class="modal-footer">
    <div class="btn-con">
        <button
            class="btn cancel-btn"
            (click)="onCancel()"
            [title]="cancelBtnText">{{cancelBtnText}}
        </button>
        <button
            *ngIf="showConfirmBtn"
            class="btn submit-btn"
            [title]="confirmBtnText"
            (click)="onActivitySubmit()">{{confirmBtnText}}
        </button>
    </div>
</div>

