import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthConstants } from '@utilities/auth-constants';
import { MenuKeys } from '@utilities/menu-keys';
import { MasterActivitiesComponent } from './master-activities/master-activities.component';


const routes: Routes = [
  {
    path: '',
    component: MasterActivitiesComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TreatmentActivitiesRoutingModule { }
