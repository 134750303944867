import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  // Incoming variables
  public title: string;
  public message: string;
  public cancelBtnText: string;
  public confirmBtnText: string;

  // Subjects
  onCancelProcess = new Subject();
  onConfirmProcess = new Subject();


  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {}

  /**
    * Author: T0512 Krupa Tresa Joseph
    * Description: Triggers the confirm process subject 
  */
 onConfirm() {
   this.onConfirmProcess.next(true);
   this.bsModalRef.hide();
 }

  /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: Logout user from the application 
*/
 onCancel() {
  this.onCancelProcess.next(true);
  this.bsModalRef.hide();
}

}
