
export class ServiceList {

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Contains an array of auth related api urls
   */
  public static authServiceApiLists = [
    'logout/',
    'login/',
    'getLoggedinUser/',
    'token',
    'v1/signup/confirm',
    'reset/password',
    'forgot/password',
    'change/password',
    'update/settings',
    'upload',
    'country',
    'states',
    'invoice/status_change',
    'download/invoice',
    'patient/document',
    'cancel/document',
    'patient/documents',

    'signup/', //not used
    'user/reset-user-password/', //not used

  ];

  /**
   * Author: TO512 Krupa Tresa Joseph
   * Description: Contains an array of client feature based urls
   */
  public static clientServiceApiLists = [
    'savecsatmp',
    'getcsatmp',
    'getcsafinal',
    'savecsafinal',
    'downloadcsafinal',
    'gettpcalenderdates', // found both in client both cp
    'completeactivity', // found both in client both cp
    'getfeedbackqns', // found both in client both cp
    'submitfeedback', // found both in client both cp
    'gettpactivities' // found both in client both cp
  ];

  /**
* Author: Gokul Krishna
* Description: Contains an array of careprovider feature based urls
*/
  public static careporviderServiceApiLists = [
    'getpatients',
    'getcareproviders',
    'createappointment',
    'getcalenderdates',
    'getSchedules',
    'getappointment',
    'telemedicine',
    'addPatient',
    'startsession/',
    'archive',
    'session/',
    'listpatients',
    'patientprofile',
    'updatePatient',
    'saveevaluation',
    'getevaluation',
    'downloadevaluation',
    'saveactivity',
    'getactivitylist',
    'getdomainssensors',
    'savetp',
    'submittp',
    'gettpdetails',
    'gettpchangelog',
    'getreport',
    'getdomains',
    'getdiagnoses',
    'savediagnosis',
    'listcareproviders',
    'assigncareprovider',
    'getallinvoices',
    'edit/invoice',
    'download/csv',
    'getmgmntsummary',
    'getreport/totalpatients',
    'getreport/totalinprogram',
    'getreport/totalreleased',
    'getreport/totaldiscontinued',
    'getreport/totalmale',
    'getreport/totalfemale',
    'getreport/totalcantsay',
    'getreport/totalimproved',
    'getreport/totalstaysame',
    'getreport/totalregressed',
    'getreport/totaldiagnosed',
    'getreport/totalnotdiagnosed',
    'getreport/statusnotupdated',
    'getreport/agelist',
    'getreport/staylist',
    'activitylog',
    'add/note',
    'get/notes',
    'get/master/callier',
    'add/callier',
    'get/callier',
    '/get/latest/callier',
    'savefollowup',
    'getfollowups'
  ];

  public static adminServiceApiLists = [
    'add/careprovider',
    'edit/careprovider'
  ];
}

