import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '@services/timezone/timezone.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  timeZone: any;
  constructor(private timezoneService: TimezoneService) {}

  transform(value: any, dob?: Boolean): any {
    let retVal = '';
    if (!value) {
      retVal = '';
    }
    if (dob) {
      retVal = moment(value).format('MMM DD, YYYY');
    } else {
      this.timeZone = this.timezoneService.getUserTimeZone();
      if (this.timeZone) {
        retVal = moment(value).tz(this.timeZone).format('MMM DD, YYYY');
      } else {
        retVal = moment(value).format('MMM DD, YYYY');
      }
    }
    return retVal;
  }

}
