import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMsg } from '@models/alert-msgs';
import { PatientProfileInfo } from '@models/client-profile';
import { ClientInfo } from '@models/clients';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { AppConstants } from '@utilities/app-constants';
import { InputFieldLimits } from '@utilities/input-field-limts';
import { ToastrMessages } from '@utilities/toastr-messages';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent implements OnInit {

  // Incoming variables
  @Input() clientInfo: PatientProfileInfo;

  @Output() clientProfile = new EventEmitter();

  // Constants
  applnStatus: any;
  generalValidations: any;

  constructor(
    private alertMessageService: AlertMsgService
  ) {
    this.applnStatus = AppConstants.APPLN_STATUS;
    this.generalValidations = InputFieldLimits.generalValidations;
   }

  ngOnInit(): void {}

  // ---------- METHODS RELATED TO EVENTS --------------

  onClientClick() {
    if (this.clientInfo.minMode) {
      const alertMsg: AlertMsg = {
        msg: ToastrMessages.CLIENTS_LIST.noInfoAvailable,
        title: ToastrMessages.TOASTR_TITLES.warning
      };
      this.alertMessageService.showWarningToster(alertMsg);
    }
    this.clientProfile.emit(this.clientInfo);
  }

}
