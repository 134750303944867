import { TrimPipe } from './trim.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeFormatPipe } from './time-format.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { ApplnStatusPipe } from './appln-status.pipe';
import { MobileFormatPipe } from './mobile-format.pipe';
import { TimezoneFormatPipe } from './timezone-format.pipe';
import { DateTimeFormatPipe } from './datetime-format.pipe';
import { AgePipe } from './age.pipe';
import { GenderPipe } from './gender.pipe';



@NgModule({
  declarations: [
    AgePipe,
    GenderPipe,
    DateFormatPipe,
    TimeFormatPipe,
    ApplnStatusPipe,
    MobileFormatPipe,
    TimezoneFormatPipe,
    DateTimeFormatPipe,
    TrimPipe,
  ],
  exports: [
    AgePipe,
    GenderPipe,
    DateFormatPipe,
    TimeFormatPipe,
    DateTimeFormatPipe,
    TimezoneFormatPipe,
    MobileFormatPipe,
    ApplnStatusPipe,
    TrimPipe,
  ],
  imports: [
    CommonModule
  ]
})

export class PipesModule { }
