import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@guards/auth-guard/auth.guard';
import { RoleGuard } from '@guards/role-guard/role.guard';
import { NoAuthGuard } from '@guards/no-auth/no-auth.guard';

import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from '@layout/content-layout/content-layout.component';

import { MenuKeys } from '@utilities/menu-keys';
import { AuthConstants } from '@utilities/auth-constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [RoleGuard],
    component: ContentLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'csa-form',
        data: {
          menuKey: MenuKeys.MENU_KEYS.view_csa,
        },
        loadChildren: () => import('./modules/csa-form/csa-form.module').then(m => m.CsaFormModule)
      },
      {
        path: 'clients',
        canActivateChild: [RoleGuard],
        data: {
          menuKey: MenuKeys.MENU_KEYS.clients_list,
        },
        loadChildren: () =>
          import('@modules/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'invoices',
        data: {
          menuKey: MenuKeys.MENU_KEYS.invoices_list,
        },
        loadChildren: () =>
          import('@modules/invoices/invoices.module').then(m => m.InvoicesModule)
      },
      {
        path: 'care-providers',
        data: {
          menuKey: MenuKeys.MENU_KEYS.careproviders_list,
        },
        loadChildren: () =>
          import('@modules/care-providers/care-providers.module').then(m => m.CareProvidersModule)
      },
      {
        path: 'appointments',
        data: {
          menuKey: MenuKeys.MENU_KEYS.appointments_participation,
        },
        loadChildren: () =>
          import('@modules/appointments/appointments.module').then(m => m.AppointmentsModule)
      },
      {
        path: 'appointment-details/:id',
        data: {
          menuKey: MenuKeys.MENU_KEYS.appointments_participation,
        },
        loadChildren: () =>
          import('@modules/appointment-details/appointment-details.module').then(m => m.AppointmentDetailsModule)
      },
      {
        path: 'treatment-activities',
        data: {
          menuKey: MenuKeys.MENU_KEYS.activities_list,
        },
        loadChildren: () =>
          import('@modules/treatment-activities/treatment-activities.module').then(m => m.TreatmentActivitiesModule)
      },
      {
        path: 'reports',
        data: {
          menuKey: MenuKeys.MENU_KEYS.invoices_list,
        },
        loadChildren: () =>
          import('@modules/reports/reports.module').then(m => m.ReportsModule)
      },
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [
      NoAuthGuard
    ],
    loadChildren: () =>
      import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  // Fallback when no prior routes is matched
  {
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
