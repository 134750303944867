import { LiabilityInfo } from './liability-form';

export interface ClientInfo {
    _id: string;
    dhsId?: any;
    patientId: string;
    isDHSEnrolled?: boolean;
}
export interface CsaFormInfo {
    stepIndex: number;
    showBtnBar: boolean; 
    formTitle: string;
    isForm: boolean;
    formIndex?: number; 
    url: string;
    formPageNo?: number,
}

// ----------- API Request Data Interfaces -----------------
export interface GetCsaFormInfoRequest {
    patientId: any;
}
export interface FormInfo {
    formInfo: any;
    formPageNo: number;
}
export interface ConsolidatedFormInfo {
    id: string;
    patientId: string;
    is_deleted: boolean;
    finalForms: FormInfo[];
    liabilityFormInfo: LiabilityFormInfo;
}

export interface LiabilityFormInfo {
    date: string;
    filledBy: string;
    name: string;
    liabilityAccepted: boolean;
}
export interface CsaFormStatusResponse {
    id: string;
    data: ConsolidatedFormInfo;
    code: number;
    status: boolean;
    message: string;
    patientId: string;
    is_deleted: boolean;
}
export interface TempFormInfoRequestData {
    patientId: string, // unique id of the application
    formPageNo: number,
    formInfo: any, 
};
export interface FinalFormInfoRequestData {
    patientId: string, // unique id of the application
    liabilityInfo: LiabilityInfo;
};
export interface FormTempSaveResponse {
    data: any;
    token: string;
    message: string;
    code: number;
    status: boolean;
    secured: boolean;
}
export interface FormFinalSaveResponse {
    code: number;
    message: string;
    status: boolean;
    data: any[]; 
}
export interface FormFinalGetResponse {
    code: number;
    message: string;
    status: boolean;
    data: FinalSaveInfo; 
}
export interface FinalSaveInfo {
    id: string;
    _id: string;
    patientId: string;
    createdAt: string;
    updatedAt: string;
    is_deleted: boolean;
    finalForms: FormInfo[];
}


export class LiabilityFormMaker {
    static create() {
        const formattedData = {
            name: '',
            filledBy: '',
            liabilityAccepted: false,
            date: ''
        };
        return formattedData;
    }
}