import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  @Input() pageTitle: string;
  @Input() showEditIcon: boolean;

  @Output() editContent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  onEditContent() {
    this.editContent.emit();
  }

}
