import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MenuInfo } from '@models/menu-item';
import { AlertMsg } from '@models/alert-msgs';
import { UserPermission } from '@models/role-info';
import { ConfirmDialog } from '@models/confirm-dialog';
import { PackedActivityInfo } from '@models/master-activities';
import { TreatmentActivities } from '@models/treatment-activities';
import { PrimeNgTableActions, PrimeNgTableColumns, PrimeNgTableSettings } from '@models/prime-ng-table';
import { ActivityDomain, ActivitySensor, MatrixCoordinatesHttpResponse } from '@models/activity-matrix';
import { 
  ActivityDetailSubject,
  ActivityListHttpResponse, 
  DeleteActivityHttpResponse, 
  MasterActivitiesParams, 
  MasterActivityManager} from '@models/master-activities';
import { TreatmentActivityDetailComponent } from '@modules/clients/treatment-activity-detail/treatment-activity-detail.component';


import { AuthService } from '@services/auth/auth.service';
import { AlertMsgService } from '@services/alert-msg/alert-msg.service';
import { TreatmentActivitiesService } from '../treatment-activities.service';

import { ConfirmDialogComponent } from '@shared-components/confirm-dialog/confirm-dialog.component';

import { MenuKeys } from '@utilities/menu-keys';
import { AppConstants } from '@utilities/app-constants';
import { ToastrMessages } from '@utilities/toastr-messages';
import { UtilityService } from '@utilities/utility.service';
import { TableConfigConstants } from '@utilities/table-config-constants';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-master-activities',
  templateUrl: './master-activities.component.html',
  styleUrls: ['./master-activities.component.scss']
})
export class MasterActivitiesComponent implements OnInit {

pageTite: string;

// Activities List related variables
activitiesList: TreatmentActivities[];

// Table Settings
tableTitle: string;
tableSettings: PrimeNgTableSettings;
tableActions: PrimeNgTableActions[];
tableHeaders: PrimeNgTableColumns[];

// Flags
fetchInProgress: boolean;
enableEditProcess: boolean;
enableAddProcess: boolean;
grantAllPermissions: boolean;

// API call related variables
requestParams: MasterActivitiesParams;

// ModalRefs
deleteConfirmModalRef: BsModalRef;
activityDetailModalRef: BsModalRef;

// Request Params
activityParams: MasterActivitiesParams;

// Constants
crudOperationsList;

// Auth variables
routeInfo: MenuInfo;
grantedPermissions: any;

// Filter related variables
domainsList: ActivityDomain[];
sensorsList: ActivitySensor[]; 

// Subscriptions
userPermissionsSubscription$ =  new Subscription();

constructor(
  private authService: AuthService,
  private modalService: BsModalService,
  private utilityService: UtilityService,
  private alertMessageService: AlertMsgService,
  private treatmentActivitiesService: TreatmentActivitiesService,
) {
  this.fetchInProgress = false;
  this.grantAllPermissions = false;
  this.enableAddProcess = false;
  this.pageTite = AppConstants.pageTitles.masterActivities;
  this.tableActions = AppConstants.activitiesListTableActions;
  this.tableSettings = AppConstants.masterActivitiesTableSetings;
  this.crudOperationsList = TableConfigConstants.crudOperationsMode;
  this.subscribeToUserPermissions();
}

ngOnInit() {
  this.fetchMatrixCoordinates();
  this.createTableHeaders();
  this.intialiseRequestParams();
  this.getActivitiesList();
}

ngOnDestroy() {
  this.userPermissionsSubscription$.unsubscribe();
}

intialiseRequestParams() {
  this.requestParams = {
    sensorId: '',
    domainId: ''
  };
}

    /**
   * Author: T0512 Krupa Tresa Joseph
   * Description: Populates user info in the header section
   */
  subscribeToUserPermissions() {
    this.userPermissionsSubscription$ = this.authService.userPermissions.subscribe(
      (userPermissions: UserPermission) => {
        if (userPermissions && userPermissions.permissions.length > 0) {
          this.enableAddProcess= this.utilityService.isFeaturePresent(userPermissions.permissions, MenuKeys.MENU_KEYS.activities_add.key);
          this.enableEditProcess= this.utilityService.isFeaturePresent(userPermissions.permissions, MenuKeys.MENU_KEYS.activities_edit.key);
          this.setTableActions();
        }
      });
  }

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Sets the actions which is to be set against to each row in the table
 */
setTableActions() {
  this.tableActions = AppConstants.activitiesListTableActions;
  this.tableActions.forEach((item: PrimeNgTableActions) => {
    if (item.modifiyAction) {
      item.enable = this.enableEditProcess;
    }
  });
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Sets the table headers for activities table
 */
createTableHeaders() {
  this.tableHeaders = AppConstants.masterActivitiesListTableHeaders;
}

formatSingleActivity(activity: PackedActivityInfo) {
  let newEntry: TreatmentActivities = { ...activity.activity_details, activity_matrix: activity.activity_matrix};
  newEntry.domainName = activity.activity_matrix.primary_impact.domain.domainName;
  newEntry.sensorName = activity.activity_matrix.primary_impact.sensor.sensorName;
  newEntry.action = MasterActivityManager.createActivityActions();
  return newEntry;
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Sets the actions for each activity item,  by default edit/view/delete will be set to true
 */
formatActivitiesList(activityList: PackedActivityInfo[]) {
  this.activitiesList = [];
  activityList.forEach( 
    (activity: PackedActivityInfo) => {
      const newEntry = this.formatSingleActivity(activity);
      this.activitiesList.push(newEntry);
  });
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Adds the newly created item into the array
 */
pushNewActivityToArray(activityData: TreatmentActivities) {
  activityData.action = MasterActivityManager.createActivityActions();
  this.activitiesList.unshift(activityData);
}

constructOriginalActivityInfo(activityData: TreatmentActivities): PackedActivityInfo {
  let packedInfo = {
    activity_details: null,
    activity_matrix: activityData.activity_matrix
  };
  packedInfo.activity_details = { ...activityData};
  delete packedInfo.activity_details.action;
  delete packedInfo.activity_details.domainName;
  delete packedInfo.activity_details.sensorName;
  delete packedInfo.activity_details.activity_matrix;
  return packedInfo;
}

// ---------- ERROR HANDLING METHODS ------------

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Displays error alert
 */
showErrorToastr(errMsg: string) {
  const alertMsg: AlertMsg = {
    msg: errMsg,
    title: ToastrMessages.TOASTR_TITLES.error
  };
  this.alertMessageService.showErrorToster(alertMsg);
}

// ----------- HTTP REQUESTS -----------

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Fetches questions list from server
 */
getActivitiesList() {
  this.fetchInProgress = true;
  this.treatmentActivitiesService.fetchActivitiesList(this.requestParams).subscribe(
    (response: ActivityListHttpResponse) => {
      if (response.status) {
        this.formatActivitiesList(response.data.records);
      } else {
        const alert: AlertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.fetchListError, title: ToastrMessages.TOASTR_TITLES.error };
      }
      this.fetchInProgress = false;
    },
    (error) => {
      this.fetchInProgress = false;
      const alert: AlertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.fetchListError, title: ToastrMessages.TOASTR_TITLES.error };
      this.alertMessageService.showErrorToster(alert);
    }
  );
}

fetchMatrixCoordinates() {
  const alert: AlertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.fetchMatrixCOordinatesFail, title: ToastrMessages.TOASTR_TITLES.error };
  this.treatmentActivitiesService.fetchMatrixCoordinates().subscribe(
    (response: MatrixCoordinatesHttpResponse) => {
      if (response.status) {
        this.domainsList = response.data.domains;
        this.sensorsList = response.data.sensors;
      } else {
        this.alertMessageService.showErrorToster(alert);
      }
    },
    (error: HttpErrorResponse) => {
      this.alertMessageService.showErrorToster(alert);
    }
  );
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Adds the newly created item into the array
 */
updateSelectedActivity(activityData: PackedActivityInfo) {
  const newEntry = this.formatSingleActivity(activityData);
  const selectedActivityIndex = this.activitiesList.findIndex((activity: TreatmentActivities) => {
    return activity._id == newEntry._id
  });
  this.activitiesList[selectedActivityIndex] = JSON.parse(JSON.stringify(newEntry));
  this.activitiesList = JSON.parse(JSON.stringify(this.activitiesList));
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Delete the selected question item from the array
 */
deleteSelectedActivity(activityData: TreatmentActivities) {
  const mainKey = '_id';
  activityData.activity_id = activityData._id;
  activityData.is_deleted = true;
  delete activityData.action;
  delete activityData.domainName;
  delete activityData.sensorName;
  this.treatmentActivitiesService.deleteActivity(activityData).subscribe(
    (response: DeleteActivityHttpResponse) => {
      let alertMsg: AlertMsg;
      if (response.status) {
        this.activitiesList = this.utilityService.deleteItem(this.activitiesList, activityData, mainKey);
        alertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.deleteActivity, title: ToastrMessages.TOASTR_TITLES.success};
        this.alertMessageService.showSuccessToster(alertMsg);
       } else {
        alertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.deleteActivityFail, title: ToastrMessages.TOASTR_TITLES.error};
         if (response.message) { alertMsg.msg = response.message }
        this.alertMessageService.showErrorToster(alertMsg);
       }
    },
    (error: HttpErrorResponse)  => {
      const alertMsg = { msg: ToastrMessages.MASTER_ACTIVITY.deleteActivityFail, title: ToastrMessages.TOASTR_TITLES.error };
      if (error) {
        this.alertMessageService.showErrorToster(alertMsg);
      }
   }
  );
}

// ----- METHODS RELATED TO EVENTS -----

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Handles the creation of a new question
 */
onCreateActivity() {
  const initialState = {
    isMasterMode: true,
    title: `${TableConfigConstants.crudOperationsMode.create.title} ${AppConstants.pageTitles.masterActivities}`,
    viewMode: TableConfigConstants.crudOperationsMode.create,
  };
  this.activityDetailModalRef = this.modalService.show(
    TreatmentActivityDetailComponent,
    {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered modal-xl',
    }
  );
  this.activityDetailModalRef.content.onConfirmProcess.subscribe(
    (result: ActivityDetailSubject) => {
      this.getActivitiesList();
      // if (result.status) {
      //   this.pushNewActivityToArray(result.questionData);
      // }
    }
  );
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Handles the editing of the table item
 */
onEditSelectedActivity(selectedActivity) {
  const initialState = {
    isMasterMode: true,
    selectedActivityInfo: selectedActivity,
    title: `${TableConfigConstants.crudOperationsMode.edit.title} ${AppConstants.pageTitles.masterActivities}`,
    viewMode: TableConfigConstants.crudOperationsMode.edit,
  };
  this.activityDetailModalRef = this.modalService.show(
    TreatmentActivityDetailComponent,
    {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered modal-xl',
    }
  );
  this.activityDetailModalRef.content.onConfirmProcess.subscribe(
    (result: ActivityDetailSubject) => {
        this.updateSelectedActivity(result.activityData);
    }
  );
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Handles the editing of the table item
 */
onViewSelectedActivity(selectedActivity) {
  const initialState = {
    isMasterMode: true,
    selectedActivityInfo: selectedActivity,
    title: `${TableConfigConstants.crudOperationsMode.view.title} ${AppConstants.pageTitles.masterActivities}`,
    viewMode: TableConfigConstants.crudOperationsMode.view,
  };
  this.activityDetailModalRef = this.modalService.show(
    TreatmentActivityDetailComponent,
    {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered modal-xl',
    });
}

/**
 * Author: T0512 Krupa Tresa Joseph
 * Description: Handles the delete of the table item
 */
onDeleteSelectedActivity(selectedActivity) {
  const initialState: ConfirmDialog = AppConstants.deleteActivityConfirmDialog;
  this.deleteConfirmModalRef = this.modalService.show(
    ConfirmDialogComponent,
    {
      class: 'modal-dialog-centered modal-sm',
      initialState
    }
  );
  this.deleteConfirmModalRef.content.onConfirmProcess.subscribe(
    (result) => {
      if (result) {
        this.deleteSelectedActivity(selectedActivity);
      }
    }
  );
}

}
