import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeleteActivityHttpResponse, CreateActivityRequestData, CreateActivityHttpResponse, MasterActivitiesParams, ActivityListHttpResponse, PackedActivityInfo } from '@models/master-activities';

import { ApiUrls } from '@utilities/api-urls';
import { MatrixCoordinatesHttpResponse } from '@models/activity-matrix';
import { ActivityDetailRequestInfo, TreatmentActivities } from '@models/treatment-activities';

@Injectable({
  providedIn: 'root'
})
export class TreatmentActivitiesService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // ---------- API REQUEST METHODS ------------

 /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: API call for fetching activities list from server
  * @param requestParams
  * @returns formattedList Observable<any>
  */
 fetchActivitiesList(requestParams): Observable<ActivityListHttpResponse> {
    const apiUrl = `${ApiUrls.ACTIVITIES.masterActivities}`;
    const paramsObj = {
      params: requestParams
    };
    return this.httpClient.get(apiUrl, paramsObj).pipe(
      map (
        (response: ActivityListHttpResponse) => {
          return response;
        },
        (error) => {
          return error;
        }
      ),
    );
 }

 /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: API call for posting a new/updated question to server
  */
 postActivity(activityData: ActivityDetailRequestInfo): Observable<any> {
  const apiUrl = `${ApiUrls.ACTIVITIES.saveActivity}`;
  return this.httpClient.post(apiUrl, activityData).pipe(
    map (
      (response: CreateActivityHttpResponse) => {
        return response;
      },
      (error) => {
        return error;
      }
    ),
  );
 }

 /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: API call for posting a new/updated question to server
  */
 updateActivity(activityData: CreateActivityRequestData, activityId: any): Observable<any> {
  const apiUrl = `${ApiUrls.ACTIVITIES.saveActivity}${activityId}/`;
  return this.httpClient.put(apiUrl, activityData).pipe(
    map (
      (response: CreateActivityHttpResponse) => {
        return response;
      },
      (error) => {
        return error;
      }
    ),
  );
 }

 /**
  * Author: T0512 Krupa Tresa Joseph
  * Description: API call for deleting a question
  */
 deleteActivity(activityData: TreatmentActivities): Observable<any> {
  const apiUrl = `${ApiUrls.ACTIVITIES.saveActivity}`;
  return this.httpClient.post(apiUrl, activityData).pipe(
    map (
      (response: DeleteActivityHttpResponse) => {
        return response;
      },
      (error) => {
        return error;
      }
    ),
  );
 }

fetchMatrixCoordinates(): Observable<MatrixCoordinatesHttpResponse> {
  const apiUrl = `${ApiUrls.ACTIVITIES.matrixCoordinates}`;
  return this.httpClient.get(apiUrl).pipe(
    map (
      (response: MatrixCoordinatesHttpResponse) => {
        return response;
      },
      (error) => {
        return error;
      }
    ),
  );
}  

}
