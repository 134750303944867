<p-card [style]="{'margin-bottom': '2em'}" class="profile-card">
    <p-header>
        <label class="info text-uppercase title-con mb-0">
            Client ID: <span class="client-id black">{{clientInfo?.patientId}}</span>
        </label>
        <span class="bookmark-icon"
        *ngIf="clientInfo?.is_bookmark">
            <i class="fa fa-bookmark colored"></i>
        </span>
    </p-header>
    <div class="card-wrap"
        title="Click to view profile"
        (click)="onClientClick()">
        <div class="img-con">
            <i class="fa fa-user-circle black enlarged"></i>
        </div>
        <!-- Min View -->
        <div
            class="detail-wrap"
            *ngIf="clientInfo?.minMode; else elseBlock">
            <div class="detail-con minimised-view">
                <label class="info text-uppercase title-con mb-0">{{clientInfo?.name}}</label>
                <!-- Contact Info -->
                <div class="identity-info contact-info row  mb-2">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <label class="sec-label mb-0"><i class="fa fa-envelope colored sec-label"></i>
                            <span class="sec-value">{{clientInfo?.mails[0]?.email}}</span>
                        </label>
                        <label class="sec-label mb-0"><i class="fa fa-phone-square colored sec-label"></i>
                            <input class="form-input sec-value disabled"
                                readonly="true"
                                [(ngModel)]="clientInfo.phone[0].number"
                                [mask]='generalValidations?.contactNumberMask'/>
                        </label>
                    </div>
                </div>
                <!-- Status Info -->
                <label class="identity-info mb-0">
                    <span
                        class="value appln-status"
                        [ngClass]="clientInfo?.applnStatus">{{clientInfo?.applnStatus  | applnStatus}}
                    </span>
                </label>
            </div>
        </div>
        <!-- Max view -->
        <ng-template #elseBlock>
        <div class="detail-con maximised-view">
            <!-- {{clientInfo | json}} -->
            <label class="info text-uppercase title-con mb-0">{{clientInfo?.appln_addtn_info?.personal?.name}}</label>
            <!-- Age, Gender, DOB info -->
            <span class="identity-info">
                <span class="gender">
                    {{clientInfo?.appln_addtn_info?.personal?.gender | gender }}/
                    {{clientInfo?.appln_addtn_info?.personal?.age | age}}
                </span>
            </span>
            <label class="identity-info mb-0">
                <span class="value">{{clientInfo?.appln_addtn_info?.personal?.dob | dateFormat:true }}</span>
            </label>
            <!-- Contact Info -->
            <div class="identity-info contact-info row  mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <label class="sec-label mb-0"><i class="fa fa-home colored sec-label"></i>
                        <span class="sec-value">
                            {{clientInfo?.appln_addtn_info?.personal?.address}},
                            {{clientInfo?.appln_addtn_info?.personal?.city}},
                            {{clientInfo?.appln_addtn_info?.personal?.state}},
                            {{clientInfo?.appln_addtn_info?.personal?.country}}
                        </span>
                    </label>
                    <label
                    *ngIf="clientInfo.appln_addtn_info"
                    class="sec-label mb-0"><i class="fa fa-phone-square colored sec-label"></i>
                        <input class="form-input sec-value disabled"
                            readonly="true"
                            [(ngModel)]="clientInfo.appln_addtn_info.personal.contact_no"
                            [mask]='generalValidations?.contactNumberMask'/>
                    </label>
                </div>
            </div>
            <!-- Status Info -->
            <label class="identity-info mb-0">
                <span
                    class="value appln-status"
                    [ngClass]="clientInfo?.applnStatus">{{clientInfo?.applnStatus  | applnStatus}}
                </span>
            </label>
            </div>
        </ng-template>
    </div>
    <p-footer>
        <label class="info text-uppercase title-con mb-0">Created On:</label>
        <span class="client-id pl-2">{{clientInfo?.createdAt | dateFormat:false}}</span>
    </p-footer>
</p-card>
